import React from 'react';

const ArchiveIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.5 8.6665V19.4998H4.5V8.6665" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 4.5L3 4.5V8.66667L21 8.66667V4.5Z" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.333 12H13.6663" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArchiveIcon;
