import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

interface StyledMotionDivProps {
  $marginTop?: string;
}

export const StyledMotionDiv = styled(motion.div)<StyledMotionDivProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ $marginTop = '0rem' }: StyledMotionDivProps) => $marginTop});
  margin-top: ${({ $marginTop = '0rem' }: StyledMotionDivProps) => $marginTop};
  z-index: 10;
  background-color: ${(props: ThemeProp) => props.theme.colors.white};
  position: fixed;
  top: 0;
  right: 0;
  border-left: 0.0625rem solid #ebebf0;
`;
