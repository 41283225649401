import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { Checkbox } from '..';
import { StyledLabel } from '../Checkbox/Checkbox.styles';

interface SelectAllProps {
  $selectAll?: boolean;
}

export const Label = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.primary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.bold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const StyledSelectAllCheckbox = styled(Checkbox)`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  display: flex;
  align-items: center;
  justify-content: center;
  ${StyledLabel} {
    color: ${(props: ThemeProp) => props.theme.colors.primary};
    font-weight: ${(props: ThemeProp) => props.theme.fontWeight.bold};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const CheckboxGroupOptionsContainer = styled.div<SelectAllProps>`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  ${($selectAll: SelectAllProps) => $selectAll && `margin-left: 2rem;`}
`;

export const CheckboxGroupContainer = styled.div``;
