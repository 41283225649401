import styled, { css } from 'styled-components';

import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';
import Spinner from '../Spinner';

export enum ButtonRole {
  Primary,
  Secondary,
  Destructive,
  Round,
  IconOnly,
}

export const Label = styled.span``;

export const Icon = styled.span`
  display: flex;
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
`;

export const Container = styled.button<{ $loading?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  position: relative;
  border: medium;
  border-radius: ${3 / 8}rem;
  padding: 0.8125rem 1rem;
  height: 2.5rem;
  min-width: 6.125rem;
  overflow: hidden;
  line-height: 2.5rem;
  background: ${(props: ThemeProp) => props.theme.colors.primary};
  font-size: 1rem;
  cursor: pointer;
  pointer-events: ${({ $loading }) => ($loading ? 'none' : 'auto')};
  transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;

  &:disabled {
    opacity: 0.2;
    color: ${(props: ThemeProp) => props.theme.colors.white};
    pointer-events: none;
  }

  &:hover:active {
    transform: scale(0.97);
  }

  &:focus-visible {
    ${focusedOutline}
  }

  ${Label} {
    transition: color 0.25s ease 0s;
    color: ${({ $loading, theme }) => ($loading ? 'transparent !important' : theme.colors.white)};
    white-space: nowrap;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const ButtonRoleStyles: Record<ButtonRole, string> = {
  [ButtonRole.Primary]: '',
  [ButtonRole.Secondary]: css`
    background: ${(props: ThemeProp) => props.theme.colors.white};
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.primary};
    ${Label} {
      color: ${(props: ThemeProp) => props.theme.colors.primary};
    }
  `,
  [ButtonRole.Destructive]: css`
    background: ${(props: ThemeProp) => props.theme.colors.darkRed};
  `,
  [ButtonRole.Round]: css`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid ${(props: ThemeProp) => props.theme.colors.c200};
    background-color: ${(props: ThemeProp) => props.theme.colors.white};
    stroke: ${(props: ThemeProp) => props.theme.colors.primary};
    min-width: 2rem;
  `,
  [ButtonRole.IconOnly]: css`
    min-width: unset;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
  `,
};
