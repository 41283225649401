import React from 'react';

const CodeBoxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="#2D7FF9" />
    <path
      d="M29.0214 24.004L29.0107 16.004C29.0099 15.6533 28.9168 15.309 28.7409 15.0055C28.565 14.7021 28.3123 14.4503 28.0084 14.2754L21.003 10.2847C20.6988 10.1096 20.3538 10.0176 20.0027 10.0181C19.6516 10.0186 19.3068 10.1115 19.003 10.2874L12.0084 14.2968C11.7049 14.4725 11.4529 14.725 11.2778 15.0289C11.1027 15.3328 11.0106 15.6774 11.0107 16.0281L11.0214 24.0281C11.0222 24.3788 11.1153 24.7232 11.2912 25.0266C11.4671 25.33 11.7197 25.5818 12.0237 25.7567L19.0291 29.7474C19.3333 29.9225 19.6783 30.0145 20.0294 30.014C20.3805 30.0135 20.7253 29.9207 21.0291 29.7447L28.0237 25.7354C28.3272 25.5596 28.5791 25.3071 28.7543 25.0032C28.9294 24.6993 29.0215 24.3547 29.0214 24.004Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5056 12.2321L20.0091 14.8261L24.5056 12.2201"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5265 27.8121L15.5196 22.6221L11.0161 20.0281"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29.0161 20.004L24.5196 22.61L24.5265 27.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.2793 14.9877L20.016 20.0261L28.7393 14.9644"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.0296 30.096L20.0161 20.0161" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CodeBoxIcon;
