import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react';

import {
  HelperTextContainer,
  ReplicatedDiv,
  StyledTextArea,
  TextAreaContainer,
  TextAreaContent,
  TextAreaInputContainer,
  TextAreaLabel,
} from './TextArea.styles';

export interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows'> {
  label?: string;
  helperText?: string;
  minRows?: number;
  maxRows?: number;
  className?: string;
}

const TextArea = ({ label, helperText, minRows, maxRows, className, ...textAreaProps }: TextAreaProps) => {
  const replicatedRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (replicatedRef?.current && textAreaRef?.current) {
      replicatedRef.current.textContent = textAreaRef.current.textContent + ' ';
    }
  }, [textAreaRef?.current?.textContent]);

  return (
    <TextAreaContainer className={className}>
      <TextAreaLabel>{label}</TextAreaLabel>
      <TextAreaInputContainer $minRows={minRows} $maxRows={maxRows}>
        <TextAreaContent>
          <ReplicatedDiv ref={replicatedRef} />
          <StyledTextArea ref={textAreaRef} {...textAreaProps} rows={1} />
        </TextAreaContent>
      </TextAreaInputContainer>
      <HelperTextContainer>{helperText}</HelperTextContainer>
    </TextAreaContainer>
  );
};

export default TextArea;
