import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import Spinner from '../Spinner';

// This is needed because https://github.com/wojtekmaj/react-pdf/issues/852
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version as string
}/pdf.worker.min.js`;

export const StyledDocument = styled(Document)<{ $noMargin?: boolean }>`
  *::selection {
    background-color: #9ecafe80;
  }
  margin: ${({ $noMargin }) => ($noMargin ? '0' : '4rem auto 0')};
`;

export const StyledPage = styled(Page)`
  margin: 1rem 0;
`;

export const StyledSpinner = styled(Spinner)`
  margin-top: 3rem;
`;
