import React, { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProtonLogo } from '../../assets';
import { useEventListener } from '../../hooks/useEventListener';
import { DEFAULT_COLORS } from '../../types/enums';
import {
  BottomLogoContainer,
  DealershipImage,
  DealershipName,
  Document,
  DOCUMENT_PAGE_HEIGHT,
  DOCUMENT_PAGE_WIDTH,
  DocumentColor,
  DocumentContainer,
  DocumentCover,
  DocumentPositioner,
  DocumentSubtitle,
  DocumentTitle,
  FlexCover,
  FooterText,
  LogoContainer,
  MainContainer,
} from './DocumentComponent.styles';

interface DocumentComponentProps {
  dealership?: {
    name?: string;
    imageUrl?: string;
  };
  documentName: string;
  subtitle?: boolean;
  customSubtitle?: string;
  selectedColor?: string;
  footer?: boolean;
  customFooter?: string;
  noMargin?: boolean;
  marginTop?: string;
}

const getDocumentSize = ({ clientWidth = 0, clientHeight = 0 } = {}) =>
  Math.min(clientHeight / DOCUMENT_PAGE_HEIGHT, clientWidth / DOCUMENT_PAGE_WIDTH);

const DocumentComponent = ({
  dealership,
  documentName,
  subtitle,
  customSubtitle,
  selectedColor,
  footer,
  customFooter,
  noMargin = false,
  marginTop,
  children,
}: PropsWithChildren<DocumentComponentProps>) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();
  const [documentScale, setDocumentScale] = useState(getDocumentSize(containerRef.current));

  useEffect(() => setDocumentScale(getDocumentSize(containerRef.current)), [containerRef.current, dealership?.name]);
  useEventListener('resize', () => setDocumentScale(getDocumentSize(containerRef.current)), [containerRef.current]);

  const getDocumentSubtitle = () => {
    return subtitle
      ? customSubtitle
      : new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
  };
  const getDocumentFooter = () => {
    return footer ? customFooter : t('policy.create.customizeDocument.confidential');
  };

  return (
    <DocumentContainer ref={containerRef} $marginTop={marginTop} $noMargin={noMargin} id="document-cover-container">
      <DocumentPositioner style={{ transform: `scale(${documentScale})` }}>
        <Document>
          <MainContainer>
            <DocumentColor $color={selectedColor ?? DEFAULT_COLORS[0]} />
            <DocumentCover>
              <FlexCover>
                <LogoContainer>
                  {dealership?.imageUrl && (
                    <DealershipImage src={dealership?.imageUrl} alt={`dealership logo for ${dealership?.name}`} />
                  )}
                </LogoContainer>
                <DocumentTitle>{documentName}</DocumentTitle>
                <DealershipName>{dealership?.name}</DealershipName>
                <DocumentSubtitle>{getDocumentSubtitle()}</DocumentSubtitle>
                <BottomLogoContainer>
                  <ProtonLogo />
                  <FooterText>{getDocumentFooter()}</FooterText>
                </BottomLogoContainer>
              </FlexCover>
            </DocumentCover>
          </MainContainer>
        </Document>
        {children}
      </DocumentPositioner>
    </DocumentContainer>
  );
};

export default memo(DocumentComponent);
