import React from 'react';

const PersonnelCardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="55" height="35" viewBox="0 0 55 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="Vector"
      d="M6.11111 0C2.74045 0 0 2.24219 0 5V30C0 32.7578 2.74045 35 6.11111 35H48.8889C52.2595 35 55 32.7578 55 30V5C55 2.24219 52.2595 0 48.8889 0H6.11111ZM13.75 20H19.8611C24.0816 20 27.5 22.7969 27.5 26.25C27.5 26.9375 26.8125 27.5 25.9722 27.5H7.63889C6.79861 27.5 6.11111 26.9375 6.11111 26.25C6.11111 22.7969 9.52951 20 13.75 20ZM10.6944 12.5C10.6944 11.1739 11.3383 9.90215 12.4843 8.96447C13.6304 8.02678 15.1848 7.5 16.8056 7.5C18.4263 7.5 19.9807 8.02678 21.1268 8.96447C22.2728 9.90215 22.9167 11.1739 22.9167 12.5C22.9167 13.8261 22.2728 15.0979 21.1268 16.0355C19.9807 16.9732 18.4263 17.5 16.8056 17.5C15.1848 17.5 13.6304 16.9732 12.4843 16.0355C11.3383 15.0979 10.6944 13.8261 10.6944 12.5ZM35.1389 10H47.3611C48.2014 10 48.8889 10.5625 48.8889 11.25C48.8889 11.9375 48.2014 12.5 47.3611 12.5H35.1389C34.2986 12.5 33.6111 11.9375 33.6111 11.25C33.6111 10.5625 34.2986 10 35.1389 10ZM35.1389 15H47.3611C48.2014 15 48.8889 15.5625 48.8889 16.25C48.8889 16.9375 48.2014 17.5 47.3611 17.5H35.1389C34.2986 17.5 33.6111 16.9375 33.6111 16.25C33.6111 15.5625 34.2986 15 35.1389 15ZM35.1389 20H47.3611C48.2014 20 48.8889 20.5625 48.8889 21.25C48.8889 21.9375 48.2014 22.5 47.3611 22.5H35.1389C34.2986 22.5 33.6111 21.9375 33.6111 21.25C33.6111 20.5625 34.2986 20 35.1389 20Z"
      fill="black"
    />
  </svg>
);

export default PersonnelCardIcon;
