import React, { ReactNode } from 'react';

import { Box, Container, StyledCheckIcon, StyledInput, StyledIntermediateIcon, StyledLabel } from './Checkbox.styles';

interface checkboxProps {
  isSelected?: boolean;
  label?: ReactNode;
  onChange?: (checked: boolean) => void;
  className?: string;
  isDisabled?: boolean;
  intermediate?: boolean;
}

export const Checkbox = ({ className, isSelected, label, onChange, isDisabled, intermediate }: checkboxProps) => {
  return (
    <Container className={className}>
      <Box>
        <StyledInput
          type="checkbox"
          disabled={isDisabled}
          checked={isSelected}
          onChange={(e) => {
            onChange?.(e.currentTarget.checked);
          }}
        />
        {!intermediate && <StyledCheckIcon $isIntermediate={intermediate} />}
        {!isSelected && <StyledIntermediateIcon $isIntermediate={intermediate} />}
      </Box>
      <StyledLabel>{label}</StyledLabel>
    </Container>
  );
};

export default Checkbox;
