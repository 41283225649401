interface Colors {
  primary: string;
  primaryLight: string;
  secondary: string;
  black: string;
  blackLowOpacity: string;
  c500: string;
  c400: string;
  c300: string;
  c250: string;
  c200: string;
  c100: string;
  c150: string;
  c050: string;
  neon: string;
  scroll: string;
  background: string;
  caret: string;
  white: string;
  selection: string;
  lightBlackBackground: string;
  secondaryGraph: string;
  disabledPrimaryBackground: string;
  red: string;
  darkRed: string;
  cherryRed: string;
  warningYellow: string;
  green: string;
}

interface Gradients {
  primary: string;
  overflowGradient: string;
  shimmer: string;
}

interface FontSize {
  xxs: string;
  xs: string;
  s: string;
  m: string;
  ml: string;
  mlg: string;
  l: string;
  lxl: string;
  xl: string;
  '2xl': string;
  '3xl': string;
}

interface FontWeight {
  regular: number;
  medium: number;
  semibold: number;
  bold: number;
}

export interface Breakpoints {
  tablet: string;
  desktop: string;
  large: string;
  'extra-large': string;
}

export interface Shadow {
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;
  '2xl': string;
}

export interface Fonts {
  primary: string;
}

export type ThemeColors = keyof Colors;

export interface ThemeType {
  name: string;
  colors: Colors;
  gradients: Gradients;
  fontSize: FontSize;
  lineHeight: FontSize;
  fontWeight: FontWeight;
  breakpoints: Breakpoints;
  fonts: Fonts;
  borderRadius: string;
}

export type ThemeProp = { theme: ThemeType };

export const lightTheme: ThemeType = {
  name: 'Light',
  colors: {
    primary: '#2D7FF9',
    primaryLight: '#796DF6',
    secondary: '#2D7FF9',
    neon: '#11BA5D',
    green: '#00C06C',
    black: '#2D2D2D',
    blackLowOpacity: '#2d2d2d0d',
    c500: '#575757',
    c400: '#818181',
    c300: '#ABABAB',
    c250: '#EBEBF0',
    c200: '#D5D5D5',
    c150: '#F3F5F8',
    c100: '#EAEAEA',
    c050: '#F4F4F4',
    caret: '#444',
    scroll: '#999',
    background: '#F9F9F9',
    white: '#FFFFFF',
    selection: '#F7F2FB',
    lightBlackBackground: '#223445',
    secondaryGraph: '#B668FF',
    disabledPrimaryBackground: '#E1D3EE',
    red: '#ED0A34',
    cherryRed: '#DE2A30',
    darkRed: '#EB4747',
    warningYellow: '#FABF1C',
  },
  gradients: {
    primary: 'linear-gradient(90deg, #5EB4F9 -0.29%, #796DF6 100%)',
    overflowGradient: 'linear-gradient(270deg, #ffffff 0%, #ffffff00 100%)',
    shimmer: 'linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);',
  },
  fonts: {
    primary: 'IBM Plex Sans',
  },
  fontSize: {
    xxs: '0.625rem',
    xs: '0.75rem',
    s: '0.875rem',
    m: '0.9375rem',
    ml: '1rem',
    mlg: '1.125rem',
    l: '1.25rem',
    lxl: '1.375rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '2.5rem',
  },
  lineHeight: {
    xxs: '0.875rem',
    xs: '1.25rem',
    s: '1.375rem',
    m: '1.5rem',
    ml: '1.25rem',
    mlg: '1.5rem',
    l: '1.75rem',
    lxl: '2rem',
    xl: '2rem',
    '2xl': '2.5rem',
    '3xl': '3.75rem',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  breakpoints: {
    tablet: '768px',
    desktop: '1054px',
    large: '1200px',
    'extra-large': '1400px',
  },
  borderRadius: '6px',
};
export interface PropsTheme {
  theme: ThemeType;
}

export const darkTheme: ThemeType = {
  ...lightTheme,
  name: 'Dark',
  gradients: {
    ...lightTheme.gradients,
    shimmer: 'linear-gradient(to right, #4a5064 8%, #3c4456 18%, #4a5064 100%)',
  },
};
