import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type InfiniteScrollProps = React.ComponentProps<typeof InfiniteScroll>;

interface Props extends React.PropsWithChildren<InfiniteScrollProps> {
  loadMore: InfiniteScrollProps['loadMore'];
  hasMore: InfiniteScrollProps['hasMore'];
  loader: InfiniteScrollProps['loader'];
}

const InfinitelyScrolled = ({ loadMore, hasMore, loader, children, ...rest }: Props) => {
  return (
    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} loader={loader} threshold={0} {...rest}>
      {children}
    </InfiniteScroll>
  );
};

export default InfinitelyScrolled;
