import React from 'react';

const ReSendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.332 4.66675V8.66675H13.332" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.6593 12.0001C15.2259 13.2267 14.4056 14.2792 13.322 14.9991C12.2384 15.7189 10.9502 16.0671 9.65155 15.9911C8.35286 15.9151 7.11404 15.4191 6.12177 14.5778C5.12951 13.7365 4.43755 12.5955 4.15018 11.3268C3.86281 10.058 3.99559 8.73017 4.52851 7.54343C5.06143 6.35668 5.96563 5.37529 7.10484 4.74714C8.24405 4.11899 9.55656 3.87812 10.8446 4.06081C12.1326 4.24351 13.3263 4.83988 14.2459 5.76005L17.3326 8.66672"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReSendIcon;
