import React from 'react';

import { DescriptionContainer, EmptyViewContainer, InfoContainer, TitleContainer } from './EmptyView.styles';

interface EmptyViewProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: React.ReactNode;
  className?: string;
}

const EmptyView = ({ title, description, placeholder, className }: EmptyViewProps) => {
  return (
    <EmptyViewContainer className={className}>
      {placeholder}
      <InfoContainer>
        <TitleContainer>{title}</TitleContainer>
        <DescriptionContainer>{description}</DescriptionContainer>
      </InfoContainer>
    </EmptyViewContainer>
  );
};

export default EmptyView;
