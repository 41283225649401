import React from 'react';

const LargeArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.4436 10H4.55469" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.99913 15.4446L4.55469 10.0001L9.99913 4.55566"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LargeArrowIcon;
