import React from 'react';

import Button, { ButtonRole } from '../../Button';
import Modal, { ModalProps } from '../Modal';
import { ModalBody, ModalFooter, ModalHeader } from './StructuredModal.styles';

export type ModalActionButton = {
  buttonText: string;
  onClick?: () => void;
  buttonRole?: ButtonRole;
  disabled?: boolean;
  showButton?: boolean;
  loading?: boolean;
};

export interface StructuredModalProps extends ModalProps {
  title?: string;
  modalActionButtons?: Array<ModalActionButton>;
}

const StructuredModal = ({ children, title, modalActionButtons = [], ...baseModalProps }: StructuredModalProps) => {
  const ActionButton = (
    {
      showButton = true,
      buttonText,
      onClick,
      buttonRole = ButtonRole.Primary,
      disabled = false,
      loading = false,
    }: ModalActionButton,
    index: number,
  ) =>
    showButton && (
      <Button
        key={index}
        disabled={disabled}
        buttonRole={buttonRole}
        loading={loading}
        onClick={onClick ?? baseModalProps.onClose}
      >
        {buttonText}
      </Button>
    );

  return (
    <Modal {...baseModalProps}>
      {title && <ModalHeader>{title}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
      {modalActionButtons.length > 0 && <ModalFooter>{modalActionButtons.map(ActionButton)}</ModalFooter>}
    </Modal>
  );
};

export default StructuredModal;
