import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ButtonRole, ButtonRoleStyles, Container, Icon, Label, StyledSpinner } from './Button.styles';

export { ButtonRole };
export interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
  loading?: boolean;
  loadingSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const BaseButton = ({ icon, loading, loadingSize = 'md', children, ...buttonProps }: BaseButtonProps) => (
  <Container $loading={loading} {...buttonProps}>
    {icon && <Icon>{icon}</Icon>}
    {children && <Label>{children}</Label>}
    {loading && <StyledSpinner size={loadingSize} color={'white'} />}
  </Container>
);

export const Button = styled(BaseButton)<{ buttonRole?: ButtonRole }>`
  ${({ buttonRole = ButtonRole.Primary }) => ButtonRoleStyles[buttonRole]};
`;
