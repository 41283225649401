import React from 'react';

import { BaseButtonProps, ButtonRole } from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { IconBadgeContainer, StyledIconButton } from './IconBadge.styles';

interface Props {
  helpText: string;
  icon: BaseButtonProps['icon'];
  buttonProps?: BaseButtonProps;
  className?: string;
}

const IconBadge = ({ helpText, icon, buttonProps, className }: Props) => {
  return (
    <IconBadgeContainer className={className}>
      <Tooltip disableTabbing text={helpText}>
        <StyledIconButton buttonRole={ButtonRole.IconOnly} icon={icon} {...buttonProps} />
      </Tooltip>
    </IconBadgeContainer>
  );
};

export default IconBadge;
