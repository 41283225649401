import React from 'react';

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 8L16 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 8L8 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CloseIcon;
