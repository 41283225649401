import { Dispatch, SetStateAction, useState } from 'react';
import merge from 'ts-deepmerge';

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type SetUpdateStateAction<S> = DeepPartial<S> | ((prevState: S) => DeepPartial<S>);

export const getUpdateValue = <T>(prev: T, value: SetUpdateStateAction<T>) =>
  value instanceof Function ? value(prev) : value;

/**
 * Like useState but includes returning a function that merges the new state with the previous one.
 *
 * @returns [state, updateState, setState]
 *
 * @see https://reactjs.org/docs/hooks-reference.html#usestate
 * */
export const useUpdateState = <S>(
  initialState: S | (() => S),
): [S, Dispatch<SetUpdateStateAction<S>>, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState(initialState);

  const updateState = (update: SetUpdateStateAction<S>) =>
    // We use `as S` since we know `prev` is a full S object and `update` is a subset of S, so the result should always be of type S.
    setState((prev) => merge.withOptions({ mergeArrays: false }, prev, getUpdateValue(prev, update)) as S);

  return [state, updateState, setState];
};
