import React, { ReactNode, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Trans, useTranslation } from 'react-i18next';

import { ErrorIcon, GenericDocumentIcon } from '../../assets';
import { useAppContext } from '../../hooks/AppContext';
import { DocumentFileExtension } from '../../types/api.graphql';
import { TranslationKeys } from '../../types/react-i18next';
import { cancelStorage, uploadFile } from '../../utils/Amplify';
import Spinner from '../Spinner';
import {
  ActionButton,
  BlueText,
  Container,
  DesktopGreyTitle,
  Dropzone,
  FileInfoContainer,
  StyledCheckIcon,
  TitleContainer,
} from './DragAndDropFileUploader.styles';

interface DragAndDropFileUploaderProps {
  placeholder?: TranslationKeys;
  setFile?: (value: File) => void;
  fileUrl?: string | undefined;
  setFileUrl?: (value: string) => void;
  placeholderFileName?: string;
  fileExtensions: DocumentFileExtension[];
  imagePlaceholder?: ReactNode;
  onFileChange?: (file: any) => void;
  onDelete?: () => void;
  shouldNotUploadToBucket?: boolean;
  onDropError?: string;
}

const DragAndDropFileUploader = ({
  placeholder = 'components.dragAndDrop.dragHere',
  fileUrl,
  setFile,
  setFileUrl,
  placeholderFileName,
  fileExtensions,
  imagePlaceholder,
  onFileChange,
  shouldNotUploadToBucket,
  onDropError,
  onDelete,
}: DragAndDropFileUploaderProps) => {
  const { t } = useTranslation();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>(placeholderFileName);
  const uploadOperationRef = useRef<any | undefined>(undefined);
  const { showError } = useAppContext();
  const [isError, setIsError] = useState(false);

  const onFileAdded = (files: Array<File>) => {
    shouldNotUploadToBucket ? loadFileAttributes(files[0]) : uploadFileLocal(files[0]);
  };

  const onUploadFinish = (url: string) => {
    setFileUrl(url);
  };

  const loadFileAttributes = (file: File) => {
    if (fileUrl || setFileUrl) {
      setFileName(file.name);
      const localUrl = URL.createObjectURL(file as Blob);
      setFileUrl(localUrl);
      onFileChange?.(file);
    } else if (setFile) {
      setIsError(false);
      setFileName(file.name);
      setFile(file);
    }
  };

  const uploadFileLocal = (file: File) => {
    setIsError(false);
    void (async () => {
      if (!file) return;
      setUploadingFile?.(true);
      setFileName(file.name);
      const url = await uploadFile(file, uploadOperationRef);
      if (url) {
        onUploadFinish(url);
        onFileChange?.(file);
      } else {
        setIsError(true);
      }
      setUploadingFile?.(false);
    })();
  };

  const deleteFile = () => {
    if (fileUrl) {
      setFileUrl(undefined);
    } else if (setFile) {
      setFile(null);
    }
    setFileName('');
    onDelete?.();
  };

  const cancelUpload = () => {
    void (async () => {
      await cancelStorage(uploadOperationRef?.current);
      setUploadingFile(false);
    })();
  };

  const onTypeError = (error: any) => {
    showError(onDropError ?? (error as string));
  };

  return (
    <Container>
      <TitleContainer>
        <span>{t('components.dragAndDrop.label')}</span>
        {(uploadingFile || fileUrl || fileName) && (
          <ActionButton
            onClick={() => {
              uploadingFile ? cancelUpload() : deleteFile();
            }}
          >
            {uploadingFile
              ? t('components.dragAndDrop.cancel')
              : isError
              ? t('components.dragAndDrop.uploadAgain')
              : t('components.dragAndDrop.delete')}
          </ActionButton>
        )}
      </TitleContainer>
      {uploadingFile || fileUrl || fileName ? (
        <Dropzone disabled={uploadingFile} uploaded={!!fileUrl || !!fileName} isError={isError}>
          <GenericDocumentIcon />
          <FileInfoContainer>
            <span>{fileName}</span>
            {uploadingFile && <Spinner />}
            {isError && <ErrorIcon />}
            {((!uploadingFile && fileUrl) || fileName) && <StyledCheckIcon />}
          </FileInfoContainer>
        </Dropzone>
      ) : (
        <FileUploader multiple={true} handleChange={onFileAdded} types={fileExtensions} onTypeError={onTypeError}>
          <Dropzone>
            {imagePlaceholder}
            <DesktopGreyTitle>
              <Trans i18nKey={placeholder as any} components={{ blue: <BlueText /> }} />
            </DesktopGreyTitle>
          </Dropzone>
        </FileUploader>
      )}
    </Container>
  );
};

export default DragAndDropFileUploader;
