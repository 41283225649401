import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonRole } from '../Button';
import { TextAreaProps } from '../TextArea/TextArea';
import { ButtonsContainer, StyledTextArea, StyledTextAreaContainer } from './InputWithCTAButton.styles';

interface InputWithCTAButtonProps {
  text?: string;
  autoFocus?: boolean;
  onSave: (value: string) => void;
  loading?: boolean;
  placeholder?: string;
  label?: string;
  ctaText?: string;
  onUpdate?: (value: string) => void;
  onCancel?: () => void;
  showCharacterCounter?: boolean;
  maxCharacters?: number;
  textAreaProps?: Partial<TextAreaProps>;
  className?: string;
}

const InputWithCTAButton = ({
  text,
  autoFocus,
  onSave,
  loading,
  placeholder,
  label,
  ctaText,
  onCancel,
  showCharacterCounter = false,
  maxCharacters,
  textAreaProps,
  className,
}: InputWithCTAButtonProps) => {
  const { t } = useTranslation();
  const [modifiedText, setModifiedText] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    onCancel?.();
    setModifiedText(text);
    setIsEditing(false);
  };

  const handleSave = () => {
    onSave(modifiedText);
    setModifiedText(text);
    setIsEditing(false);
  };

  useEffect(() => {
    setModifiedText(text);
  }, [text]);

  return (
    <StyledTextAreaContainer className={className}>
      <StyledTextArea
        autoFocus={autoFocus}
        onFocus={() => setIsEditing(true)}
        value={(isEditing ? modifiedText : text) ?? ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setModifiedText(e.target.value)}
        minRows={1}
        maxRows={10}
        maxLength={showCharacterCounter && maxCharacters}
        label={label}
        placeholder={placeholder ?? t('policy.details.descriptionPlaceholder')}
        helperText={showCharacterCounter && isEditing ? `${modifiedText?.length || 0}/${maxCharacters}` : null}
        {...textAreaProps}
      />
      {isEditing && (
        <ButtonsContainer $showCharacterCounter={showCharacterCounter}>
          <Button onClick={handleSave} loading={loading}>
            {ctaText ?? t('common.save')}
          </Button>
          <Button buttonRole={ButtonRole.Secondary} onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
        </ButtonsContainer>
      )}
    </StyledTextAreaContainer>
  );
};

export default InputWithCTAButton;
