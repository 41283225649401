import React from 'react';

const QuestionMarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.48218 4.99559C2.36938 5.25943 2.31299 5.61344 2.31299 6.05762H3.47681C3.48364 5.5767 3.59985 5.21101 3.82544 4.96053L4.45605 4.35437C4.97559 3.8267 5.23535 3.29068 5.23535 2.74631C5.23535 2.13514 5.04309 1.65924 4.65857 1.31859C4.27405 0.97794 3.73828 0.807617 3.05127 0.807617C2.38476 0.807617 1.849 0.97961 1.44397 1.3236C1.03894 1.66759 0.833008 2.13514 0.826172 2.72627H2.07202C2.07202 2.44908 2.16174 2.22949 2.34119 2.06752C2.52063 1.90554 2.75732 1.82455 3.05127 1.82455C3.35889 1.82455 3.59216 1.90721 3.7511 2.07253C3.91003 2.23784 3.9895 2.47746 3.9895 2.7914C3.9895 3.02852 3.92114 3.24893 3.78442 3.45266C3.69556 3.58624 3.4845 3.81084 3.15125 4.12644C2.81799 4.44204 2.59497 4.73176 2.48218 4.99559ZM2.41553 6.81213C2.28906 6.93689 2.22583 7.1001 2.22583 7.30176C2.22583 7.48975 2.2865 7.64697 2.40784 7.77344C2.52918 7.8999 2.70264 7.96313 2.92822 7.96313C3.15381 7.96313 3.32812 7.8999 3.45117 7.77344C3.57422 7.64697 3.63574 7.48975 3.63574 7.30176C3.63574 7.10693 3.57251 6.94544 3.44604 6.81726C3.31958 6.68909 3.14697 6.625 2.92822 6.625C2.71289 6.625 2.54199 6.68738 2.41553 6.81213Z"
    />
  </svg>
);

export default QuestionMarkIcon;
