import styled from 'styled-components';

import { CompletedCheckIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

const indicatorSize = '1.25rem';

interface StatusProps {
  active: boolean;
}

export const Container = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  line-height: ${indicatorSize};
`;

export const Indicator = styled.span<StatusProps>`
  background-color: ${(props: ThemeProp & StatusProps) =>
    props.active ? props.theme.colors.secondary : props.theme.colors.c050};
  border-radius: ${indicatorSize};
  color: ${(props: ThemeProp & StatusProps) => (props.active ? props.theme.colors.white : props.theme.colors.c400)};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  height: ${indicatorSize};
  line-height: ${indicatorSize};
  text-align: center;
  width: ${indicatorSize};
`;

export const Check = styled(CompletedCheckIcon)`
  position: absolute:
  top: 0;
  left: 0;
  height: ${indicatorSize};
  width: ${indicatorSize};
`;

export const Title = styled.span<StatusProps>`
  color: ${(props: ThemeProp & StatusProps) => (props.active ? props.theme.colors.secondary : props.theme.colors.c400)};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp & StatusProps) =>
    props.active ? props.theme.fontWeight.medium : props.theme.fontWeight.regular};
  margin-left: 0.5rem;
`;

export const Separator = styled.span`
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  margin: 0 0.5rem;
`;
