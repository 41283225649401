import React from 'react';

import { ThemeColors } from '../../theme/Themes';
import { PrimarySpinner, SecondarySpinner, SpinnerContainer } from './Spinner.styles';

type SpinnerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface props {
  size?: SpinnerSize;
  color?: ThemeColors;
  className?: string;
}

const SpinnerDimensions: Record<SpinnerSize, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 44,
  xl: 64,
};

export const Spinner = ({ size = 'md', color = 'primary', className }: props) => {
  return (
    <SpinnerContainer className={className}>
      <PrimarySpinner $size={SpinnerDimensions[size]} $color={color} />
      <SecondarySpinner $size={SpinnerDimensions[size]} $color={color} />
    </SpinnerContainer>
  );
};

export default Spinner;
