import React from 'react';

import { Check, Container, Indicator, Separator, Title, TitleContainer } from './Wizard.styles';

interface WizardProps {
  selectedStepIndex: number;
  titles: ReadonlyArray<string>;
}

const Wizard = ({ selectedStepIndex, titles, ...rest }: WizardProps) => {
  return (
    <Container {...rest}>
      {titles.map((title, index) => {
        const active = index === selectedStepIndex;
        return (
          <TitleContainer key={index}>
            <Indicator active={active}>{index < selectedStepIndex ? <Check /> : ++index}</Indicator>
            <Title active={active}>{title}</Title>
            {index < titles.length && <Separator>{'>'}</Separator>}
          </TitleContainer>
        );
      })}
    </Container>
  );
};

export default Wizard;
