import axios, { AxiosInstance } from 'axios';

import { ExtendedAxiosRequestConfig } from '../axios';
import { authInterceptor, defaultInterceptorErrorHandler } from './interceptors';
import { safelyJoinPaths } from './utils/paths';

export const createAxiosClient = (config: ExtendedAxiosRequestConfig): AxiosInstance => {
  const client = axios.create(config);
  client.interceptors.request.use(authInterceptor, defaultInterceptorErrorHandler);

  return client;
};

export const axiosClient = createAxiosClient({
  baseURL: safelyJoinPaths(process.env.REACT_APP_GRAPHQL_URI, 'api'),
  headers: {
    'Content-Type': 'application/json',
  },
});
