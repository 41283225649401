import React, { ComponentType, Key, useMemo } from 'react';

import { InputLabel, RadioButton, RadioGroupContainer, RadioInput } from './RadioGroup.styles';

export interface RadioGroupProps<T, U extends Key> {
  inputs: readonly T[];
  selectedKey: U;
  keySelector: (input: T) => U;
  onSelectionChange?: (selection: U) => void;
  textSelector?: (input: T) => string;
  radioSelectionComponent?: ComponentType<{ input: T; selected: boolean }>;
  groupName: string;
  disabled?: boolean;
  className?: string;
}

const RadioGroup = <T, U extends Key>({
  inputs,
  selectedKey,
  keySelector,
  onSelectionChange,
  textSelector,
  radioSelectionComponent: RadioSelection,
  groupName,
  disabled,
  className,
}: RadioGroupProps<T, U>) => {
  const radioInputs = useMemo(
    () => (
      <>
        {inputs.map((input) => {
          const key = keySelector(input);
          const text = textSelector?.(input);
          return (
            <InputLabel key={key}>
              <RadioInput
                checked={selectedKey === key}
                name={groupName}
                value={key}
                onClick={() => onSelectionChange?.(key)}
                disabled={disabled}
              />
              {RadioSelection ? (
                <RadioSelection input={input} selected={selectedKey === key} />
              ) : (
                <>
                  <RadioButton checked={selectedKey === key} />
                  {text}
                </>
              )}
            </InputLabel>
          );
        })}
      </>
    ),
    [inputs, selectedKey, keySelector, textSelector, onSelectionChange, RadioSelection, groupName, disabled],
  );

  return <RadioGroupContainer className={className}>{radioInputs}</RadioGroupContainer>;
};

export default RadioGroup;
