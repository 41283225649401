import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export interface PopoverPortalContainerProps {
  $top: number;
  $left: number;
  $isOpen: boolean;
  $maxHeight: string | (($top: number) => string);
}

export const PopoverPortalContainer = styled.div<PopoverPortalContainerProps>`
  position: fixed;
  top: ${({ $top }: PopoverPortalContainerProps) => $top}px;
  left: ${({ $left }: PopoverPortalContainerProps) => $left}px;
  max-height: ${({ $maxHeight, $top }: PopoverPortalContainerProps) =>
    typeof $maxHeight === 'function' ? $maxHeight($top) : $maxHeight};
  z-index: 500;
  padding: 0.5rem;
  box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.08);
  background: ${({ theme }: ThemeProp) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProp) => theme.colors.blackLowOpacity};
  border-radius: ${({ theme }: ThemeProp) => theme.borderRadius};
  min-width: 11rem;
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};

  animation: ${({ $isOpen }: PopoverPortalContainerProps) => ($isOpen ? 'open' : 'close')} 0.25s ease 0s 1 both;

  @keyframes open {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    1% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
`;
