import React from 'react';

import { ShimmerProps, ShimmerText } from '../Shimmer';
interface TextValueWithLoaderProps {
  value: string | number | React.ReactElement;
  loading: boolean;
  shimmerProps?: ShimmerProps;
}

const TextValueWithLoader = ({ value, loading, shimmerProps }: TextValueWithLoaderProps) => {
  return loading ? <ShimmerText {...shimmerProps} /> : <>{value}</>;
};

export default TextValueWithLoader;
