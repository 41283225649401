import React from 'react';

const ColorPicker = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" stroke="#D5D5D5" />
    <path d="M17.3997 13.2C17.3997 13.2 15.7997 14.936 15.7997 16C15.7997 16.4243 15.9682 16.8313 16.2683 17.1314C16.5683 17.4314 16.9753 17.6 17.3997 17.6C17.824 17.6 18.231 17.4314 18.531 17.1314C18.8311 16.8313 18.9997 16.4243 18.9997 16C18.9997 14.936 17.3997 13.2 17.3997 13.2ZM6.36766 12L10.1997 8.168L14.0317 12M15.4477 11.152L8.29566 4L7.16766 5.128L9.07166 7.032L4.95166 11.152C4.47966 11.6 4.47966 12.376 4.95166 12.848L9.35166 17.248C9.58366 17.48 9.89566 17.6 10.1997 17.6C10.5037 17.6 10.8157 17.48 11.0477 17.248L15.4477 12.848C15.9197 12.376 15.9197 11.6 15.4477 11.152Z" />
  </svg>
);

export default ColorPicker;
