import React from 'react';

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="46" viewBox="0 0 50 46" fill="none">
    <g clipPath="url(#clip0_5958_9172)">
      <path
        d="M24.9998 3.80078C26.3865 3.80078 27.6658 4.45996 28.369 5.54102L49.4627 37.8848C50.1756 38.9746 50.1756 40.3193 49.4822 41.4092C48.7889 42.499 47.49 43.1758 46.0936 43.1758H3.90606C2.50958 43.1758 1.21075 42.499 0.517392 41.4092C-0.175968 40.3193 -0.166202 38.9658 0.536923 37.8848L21.6307 5.54102C22.3338 4.45996 23.6131 3.80078 24.9998 3.80078ZM24.9998 15.0508C23.701 15.0508 22.6561 15.9912 22.6561 17.1602V27.0039C22.6561 28.1729 23.701 29.1133 24.9998 29.1133C26.2986 29.1133 27.3436 28.1729 27.3436 27.0039V17.1602C27.3436 15.9912 26.2986 15.0508 24.9998 15.0508ZM28.1248 34.7383C28.1248 33.9924 27.7956 33.277 27.2095 32.7495C26.6235 32.2221 25.8286 31.9258 24.9998 31.9258C24.171 31.9258 23.3762 32.2221 22.7901 32.7495C22.2041 33.277 21.8748 33.9924 21.8748 34.7383C21.8748 35.4842 22.2041 36.1996 22.7901 36.727C23.3762 37.2545 24.171 37.5508 24.9998 37.5508C25.8286 37.5508 26.6235 37.2545 27.2095 36.727C27.7956 36.1996 28.1248 35.4842 28.1248 34.7383Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5958_9172">
        <rect width="50" height="45" fill="white" transform="translate(0 0.988281)" />
      </clipPath>
    </defs>
  </svg>
);

export default WarningIcon;
