import React from 'react';

import { ColoredLabel } from './ColoredLabel.styles';

interface BadgeProps {
  color: string;
  children: React.ReactNode;
  className?: string;
}

const Badge = ({ color, children, className }: BadgeProps) => {
  return (
    <ColoredLabel $color={color} className={className}>
      {children}
    </ColoredLabel>
  );
};

export default Badge;
