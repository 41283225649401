import styled from 'styled-components';

interface ColorLabelProps {
  $color: string;
}
export const ColoredLabel = styled.div<ColorLabelProps>`
  border-radius: ${13 / 16}rem;
  padding: 0.25rem 0.5rem;
  background: ${(props: ColorLabelProps) => props.$color}14;
  color: ${(props: ColorLabelProps) => props.$color};
  font-size: ${13 / 16}rem;
  line-height: normal;
  width: fit-content;
  height: fit-content;
`;
