import React from 'react';

const BriefCaseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="#2D7FF9" />
    <path
      d="M26.6667 15.8333H13.3334C12.4129 15.8333 11.6667 16.5795 11.6667 17.5V25.8333C11.6667 26.7538 12.4129 27.5 13.3334 27.5H26.6667C27.5872 27.5 28.3334 26.7538 28.3334 25.8333V17.5C28.3334 16.5795 27.5872 15.8333 26.6667 15.8333Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3334 27.5V14.1667C23.3334 13.7246 23.1578 13.3007 22.8453 12.9882C22.5327 12.6756 22.1088 12.5 21.6667 12.5H18.3334C17.8914 12.5 17.4675 12.6756 17.1549 12.9882C16.8423 13.3007 16.6667 13.7246 16.6667 14.1667V27.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BriefCaseIcon;
