export const UNAUTHENTICATED_TERMINALS = {
  ROOT: '',
  Login: 'login',
  VendorQuestionnaire: 'questionnaire',
} as const;

export const UNAUTHENTICATED_ROUTES = {
  Root: `/*`,
  Login: `/${UNAUTHENTICATED_TERMINALS.Login}`,
  VendorQuestionnaire: `/${UNAUTHENTICATED_TERMINALS.VendorQuestionnaire}`,
} as const;

export const UNAUTHENTICATED_PATHS = {
  ROOT: ``,
  get Login() {
    return `${this.ROOT}/${UNAUTHENTICATED_TERMINALS.Login}` as const;
  },
  get VendorQuestionnaire() {
    return `${this.ROOT}/${UNAUTHENTICATED_TERMINALS.VendorQuestionnaire}` as const;
  },
} as const;
