import React from 'react';

const DevicesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="55" height="40" viewBox="0 0 55 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.5007 0C26.8046 0 26.1171 0.124975 25.464 0.366L1.3591 9.40887C0.542716 9.72131 0.00132274 10.5247 0.00132274 11.4263C0.00132274 12.3279 0.542716 13.1314 1.3591 13.4438L6.33476 15.3095C4.92542 17.6126 4.12622 20.3353 4.12622 23.2008V25.7092C4.12622 28.2445 3.19812 30.86 2.20986 32.9221C1.65128 34.0826 1.01536 35.2252 0.276316 36.2786C0.00132274 36.6625 -0.0760192 37.1624 0.0786646 37.6176C0.233348 38.0729 0.594277 38.4121 1.04114 38.5282L6.54101 39.9565C6.90194 40.0547 7.28865 39.9832 7.60661 39.7779C7.92457 39.5726 8.148 39.2334 8.21675 38.8495C8.95579 35.0289 8.58627 31.601 8.03629 29.1461C7.76129 27.8785 7.39177 26.5841 6.87616 25.3968V23.2008C6.87616 20.5049 7.7527 17.9608 9.27376 15.9255C10.3823 14.5418 11.8174 13.4259 13.5018 12.7386L26.9936 7.23073C27.6983 6.94507 28.4975 7.30214 28.7725 8.03414C29.0475 8.76614 28.7038 9.59633 27.9991 9.88199L14.5072 15.3898C13.4416 15.8273 12.5049 16.4968 11.7401 17.318L25.4554 22.4599C26.1085 22.7009 26.796 22.8259 27.4921 22.8259C28.1881 22.8259 28.8756 22.7009 29.5287 22.4599L53.6422 13.4438C54.4586 13.1403 55 12.3279 55 11.4263C55 10.5247 54.4586 9.72131 53.6422 9.40887L29.5373 0.366C28.8842 0.124975 28.1967 0 27.5007 0ZM11.0011 33.5648C11.0011 36.716 18.3915 39.9922 27.5007 39.9922C36.6098 39.9922 44.0003 36.716 44.0003 33.5648L42.6855 20.5853L30.4654 25.1736C29.5116 25.5307 28.5061 25.7092 27.5007 25.7092C26.4952 25.7092 25.4812 25.5307 24.5359 25.1736L12.3159 20.5853L11.0011 33.5648Z"
      fill="black"
    />
  </svg>
);

export default DevicesIcon;
