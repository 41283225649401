import styled from 'styled-components';

import { SearchIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Dropdown from '../Dropdown';
import { DropdownTrigger } from '../Dropdown/Dropdown.styles';
import DropdownMultiselect from '../Dropdown/DropdownMultiselect';
import { Input, InputField } from '../Input';

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  margin-right: 1rem;
`;

export const Title = styled.p`
  color: ${(props: ThemeProp) => props.theme.colors.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xl};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  margin: 0;
  align-self: center;
`;

export const InputContainer = styled.div`
  ${Input} {
    width: 14.5rem;
    ${InputField} {
      height: 2.5rem;
    }
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.black};
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  ${Dropdown}, ${DropdownMultiselect} {
    height: 2.5rem;
    width: 12.5rem;
    ${DropdownTrigger} {
      width: 100%;
      height: 100%;
    }
  }
`;
