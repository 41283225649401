import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { ScrollHint, StyledDivider, Tab, TabsContainer, TabsInnerContainer, Title } from './Tabs.styles';

export interface TabType {
  title: string;
  icon?: ReactNode;
  path: string;
}

interface TabsProps {
  tabs: TabType[];
}
const Tabs = ({ tabs }: TabsProps) => {
  const location = useLocation();

  const isCurrentTab = (path: string) => location.pathname === path;

  return (
    <TabsContainer>
      <TabsInnerContainer>
        {tabs?.map((tab: TabType) => (
          <Tab key={tab.title} $isActive={isCurrentTab(tab.path)} to={tab.path}>
            {tab.icon && tab.icon} <Title>{tab.title}</Title>
          </Tab>
        ))}
      </TabsInnerContainer>
      <StyledDivider />
      <ScrollHint />
    </TabsContainer>
  );
};

export default Tabs;
