import React, { ReactNode, useEffect, useRef } from 'react';

import { PopoverPlacement } from '../PopoverButton/PopoverButton.styles';
import { TooltipContainer, TooltipContent, TooltipTrigger } from './Tooltip.styles';

interface TooltipProps {
  text?: ReactNode;
  placement?: PopoverPlacement;
  isDisabled?: boolean;
  disableTabbing?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Tooltip = ({
  text,
  children,
  isDisabled,
  disableTabbing,
  placement = PopoverPlacement.top,
  className,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const triggerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const handleScroll = () => setIsOpen(false);

      document.addEventListener('scroll', handleScroll, true);
      return () => {
        document.removeEventListener('scroll', handleScroll, true);
      };
    }
    return undefined;
  }, [isOpen]);

  return isDisabled ? (
    <TooltipContainer className={className}>
      <TooltipTrigger>{children}</TooltipTrigger>
    </TooltipContainer>
  ) : (
    <TooltipContainer className={className} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <TooltipTrigger
        tabIndex={disableTabbing ? -1 : 0}
        ref={triggerRef}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent isOpen={isOpen} triggerRef={triggerRef} placement={placement}>
        {text}
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;
