import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const EmptyViewContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 3.75rem;
  margin-bottom: -1.25rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1.25rem;
  text-align: center;
  z-index: 4;
`;

export const TitleContainer = styled.div`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
`;

export const DescriptionContainer = styled.div`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  color: ${(props: ThemeProp) => props.theme.colors.c400};
`;
