import React from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from 'react-phone-input-2';

import { Container, Label, StyledPhoneInput, Title } from './PhoneInput.styles';

interface PhoneInputProps {
  formattedValue: string;
  onChange: (phone: string, isComplete: boolean) => void;
  required?: boolean;
  className?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ formattedValue, onChange, required, className }) => {
  const { t } = useTranslation();

  const allowedCountries = ['us'];

  return (
    <Container className={className}>
      <Label>
        <Title $required={required}>{t('auth.signUp.phoneNumber')}</Title>
        <StyledPhoneInput
          value={formattedValue}
          onChange={(_value, data, _e, formattedValue) => {
            onChange(formattedValue, (data as CountryData)?.format.length === formattedValue.length);
          }}
          onlyCountries={allowedCountries}
          country={'us'}
          countryCodeEditable={false}
          disableDropdown={allowedCountries.length <= 1}
        />
      </Label>
    </Container>
  );
};

export default PhoneInput;
