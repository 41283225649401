import React, { Dispatch, Key, useEffect, useRef } from 'react';

import { SectionStatus } from '../../types/enums';
import { Card, RadioGroupContainer } from './SelectList.styles';

interface ItemProps<K = Key> {
  id: K;
  hidden?: boolean;
  status?: SectionStatus;
}

interface Props<T, K = Key> {
  component: React.ComponentType<T>;
  items: readonly T[];
  selected?: K;
  onElementSelected?: Dispatch<K>;
  className?: string;
}

const SelectList = <T, K extends Key = Key>({
  component: Item,
  items,
  selected,
  onElementSelected,
  className,
}: Props<T & ItemProps<K>, K>) => {
  const itemsRef = useRef<Record<K, HTMLLIElement>>({} as Record<K, HTMLLIElement>);

  const handleChange = (value: K) => {
    if (onElementSelected) onElementSelected(value);
  };

  // Allow tab and arrow navigation through the radio group
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;
    const { length } = items;
    const currentIndex = items.findIndex((item) => item.id === selected);
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = currentIndex + 1;
      if (nextIndex < length) {
        handleChange(items[nextIndex].id);
      }
    } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      const nextIndex = currentIndex - 1;
      if (nextIndex >= 0) {
        handleChange(items[nextIndex].id);
      }
    }
  };

  // Make the radio group together with the selected element visible
  useEffect(() => {
    const element = itemsRef.current[selected];
    element?.focus();
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [selected]);

  return (
    <RadioGroupContainer className={className} onKeyDown={handleKeyDown}>
      {items?.map(({ id, ...item }, index) => (
        <Card
          ref={(el) => (itemsRef.current[id] = el)}
          tabIndex={id === selected || (selected === undefined && index === 0) ? 0 : -1}
          onClick={() => handleChange(id)}
          key={id}
          $hidden={item?.hidden}
          highlighted={!item?.hidden && item?.status === SectionStatus.Completed}
          checked={id === selected}
        >
          <Item {...items[index]} />
        </Card>
      ))}
    </RadioGroupContainer>
  );
};

export default SelectList;
