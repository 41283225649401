import React from 'react';

const TrashIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.25 7.5H6.75H18.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.25 7.5V18C17.25 18.3978 17.092 18.7794 16.8107 19.0607C16.5294 19.342 16.1478 19.5 15.75 19.5H8.25C7.85218 19.5 7.47064 19.342 7.18934 19.0607C6.90804 18.7794 6.75 18.3978 6.75 18V7.5M9 7.5V6C9 5.60218 9.15804 5.22064 9.43934 4.93934C9.72064 4.65804 10.1022 4.5 10.5 4.5H13.5C13.8978 4.5 14.2794 4.65804 14.5607 4.93934C14.842 5.22064 15 5.60218 15 6V7.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5 11.25V15.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5 11.25V15.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TrashIcon;
