import React from 'react';

const LargePlus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
    <path
      d="M6.42857 0.988281C2.88281 0.988281 0 3.87109 0 7.41685V39.5597C0 43.1055 2.88281 45.9883 6.42857 45.9883H38.5714C42.1172 45.9883 45 43.1055 45 39.5597V7.41685C45 3.87109 42.1172 0.988281 38.5714 0.988281H6.42857ZM20.0893 32.3276V25.899H13.6607C12.3248 25.899 11.25 24.8242 11.25 23.4883C11.25 22.1523 12.3248 21.0776 13.6607 21.0776H20.0893V14.649C20.0893 13.3131 21.1641 12.2383 22.5 12.2383C23.8359 12.2383 24.9107 13.3131 24.9107 14.649V21.0776H31.3393C32.6752 21.0776 33.75 22.1523 33.75 23.4883C33.75 24.8242 32.6752 25.899 31.3393 25.899H24.9107V32.3276C24.9107 33.6635 23.8359 34.7383 22.5 34.7383C21.1641 34.7383 20.0893 33.6635 20.0893 32.3276Z"
      fill="black"
    />
  </svg>
);

export default LargePlus;
