import React, { useEffect, useRef, useState } from 'react';

import { Container, DummyHelpTextBox, HelpText, StyledChevronIcon, StyledInformationIcon } from './HelperBox.styles';

interface Props {
  /** This html is used in `dangerouslySetInnerHTML` and is unescaped, so do not include unsanitized user generated content. */
  __html: string;
  className?: string;
}

const CLOSED_MAX_HEIGHT = '1.5rem';

const HelperBox = ({ __html, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [maxHeight, setMaxHeight] = useState('');
  const textBoxRef = useRef<HTMLDivElement>();
  const dummyTextBoxRef = useRef<HTMLDivElement>();

  const calculateHeightUsed = () => {
    textBoxRef.current.style.position = 'absolute';
    dummyTextBoxRef.current.style.display = 'block';
    dummyTextBoxRef.current.style.maxHeight = 'max-content';
    const maxHeight = dummyTextBoxRef.current.getBoundingClientRect().height;
    textBoxRef.current.style.position = '';
    dummyTextBoxRef.current.style.display = '';
    dummyTextBoxRef.current.style.maxHeight = '';
    return maxHeight.toString() + 'px';
  };

  const handleChangeCollapse = () => {
    if (isExpanded) {
      setMaxHeight(CLOSED_MAX_HEIGHT);
      setIsExpanded(false);
    } else {
      setMaxHeight(calculateHeightUsed());
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      textBoxRef.current.style.maxHeight = 'max-content';
      setMaxHeight(calculateHeightUsed());
      setTimeout(() => {
        textBoxRef.current.style.maxHeight = '';
      });
    }
  }, [__html]);

  return (
    <Container className={className} onClick={handleChangeCollapse}>
      <StyledInformationIcon />
      <HelpText ref={textBoxRef} $maxHeight={maxHeight} dangerouslySetInnerHTML={{ __html }} />
      <DummyHelpTextBox ref={dummyTextBoxRef} dangerouslySetInnerHTML={{ __html }} />
      <StyledChevronIcon $isExpanded={isExpanded} />
    </Container>
  );
};

export default HelperBox;
