import React from 'react';

const UploaderDefaultIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" viewBox="7 1 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.5" cx="27" cy="21" r="20" fill="#EAEAEA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4531 10.1108C20.4531 9.00627 21.3486 8.11084 22.4531 8.11084H31.4727C31.9796 8.11084 32.4676 8.30332 32.838 8.64937L36.2085 11.7982C36.6134 12.1764 36.8432 12.7056 36.8432 13.2597V27.7692C36.8432 28.8737 35.9478 29.7692 34.8432 29.7692H22.4531C21.3486 29.7692 20.4531 28.8737 20.4531 27.7692V10.1108Z"
        fill="url(#paint0_linear_2287_12722)"
      />
      <g filter="url(#filter0_d_2287_12722)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1113 11.8667C18.1113 10.7621 19.0068 9.8667 20.1113 9.8667H29.5574C30.0614 9.8667 30.5469 10.057 30.9166 10.3995L34.4459 13.669C34.8545 14.0475 35.0868 14.5792 35.0868 15.1362V30.1104C35.0868 31.2149 34.1913 32.1104 33.0868 32.1104H20.1113C19.0068 32.1104 18.1113 31.2149 18.1113 30.1104V11.8667Z"
          fill="url(#paint1_linear_2287_12722)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9151 15.4277C21.4302 15.4277 21.0371 15.8208 21.0371 16.3058C21.0371 16.7907 21.4302 17.1838 21.9152 17.1838H31.2809C31.7658 17.1838 32.1589 16.7907 32.1589 16.3058C32.1589 15.8208 31.7658 15.4277 31.2809 15.4277H21.9151ZM21.9152 18.6475C21.4302 18.6475 21.0371 19.0406 21.0371 19.5255C21.0371 20.0105 21.4302 20.4036 21.9152 20.4036H31.2809C31.7658 20.4036 32.159 20.0105 32.159 19.5255C32.159 19.0406 31.7658 18.6475 31.2809 18.6475H21.9152ZM21.0371 22.7451C21.0371 22.2602 21.4302 21.867 21.9152 21.867H31.2809C31.7658 21.867 32.159 22.2602 32.159 22.7451C32.159 23.23 31.7658 23.6231 31.2809 23.6231H21.9152C21.4302 23.6231 21.0371 23.23 21.0371 22.7451ZM21.9152 25.0858C21.4302 25.0858 21.0371 25.4789 21.0371 25.9638C21.0371 26.4488 21.4302 26.8419 21.9152 26.8419H28.5005C28.9854 26.8419 29.3785 26.4488 29.3785 25.9638C29.3785 25.4789 28.9854 25.0858 28.5005 25.0858H21.9152Z"
        fill="url(#paint2_linear_2287_12722)"
      />
      <defs>
        <filter
          id="filter0_d_2287_12722"
          x="0.111328"
          y="0.866699"
          width="52.9746"
          height="58.2437"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="9" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.945098 0 0 0 0 0.95498 0 0 0 0 0.980392 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2287_12722" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2287_12722" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_2287_12722"
          x1="26.8113"
          y1="29.7692"
          x2="33.576"
          y2="12.4208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3D3D3" />
          <stop offset="1" stopColor="#FAFAFA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2287_12722"
          x1="22.7633"
          y1="28.7952"
          x2="30.8551"
          y2="12.2082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2287_12722"
          x1="22.4536"
          y1="28.9449"
          x2="16.1418"
          y2="19.9779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAEAEA" />
          <stop offset="1" stopColor="#EAEAEA" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UploaderDefaultIcon;
