import React from 'react';

const GenericDocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="40" height="40" viewBox="7 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity="0.11" cx="27" cy="20" r="20" fill="#2D7FF9" />
    <g opacity="0.4" filter="url(#filter0_d_2753_28148)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11C18 9.89543 18.8954 9 20 9H30.2239C30.7041 9 31.1682 9.17275 31.5315 9.48668L35.3076 12.7495C35.7473 13.1294 36 13.6818 36 14.2628V29C36 30.1046 35.1046 31 34 31H20C18.8954 31 18 30.1046 18 29V11Z"
        fill="url(#paint0_linear_2753_28148)"
      />
    </g>
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.878 14.561C21.3931 14.561 21 14.9541 21 15.4391C21 15.924 21.3931 16.3171 21.878 16.3171H32.122C32.6069 16.3171 33 15.924 33 15.4391C33 14.9541 32.6069 14.561 32.122 14.561H21.878ZM21.878 17.7808C21.3931 17.7808 21 18.1739 21 18.6588C21 19.1437 21.3931 19.5368 21.878 19.5368H32.122C32.6069 19.5368 33 19.1437 33 18.6588C33 18.1739 32.6069 17.7808 32.122 17.7808H21.878ZM21 21.8785C21 21.3936 21.3931 21.0005 21.878 21.0005H32.122C32.6069 21.0005 33 21.3936 33 21.8785C33 22.3635 32.6069 22.7566 32.122 22.7566H21.878C21.3931 22.7566 21 22.3635 21 21.8785ZM21.878 24.2192C21.3931 24.2192 21 24.6124 21 25.0973C21 25.5822 21.3931 25.9753 21.878 25.9753H29.122C29.6069 25.9753 30 25.5822 30 25.0973C30 24.6124 29.6069 24.2192 29.122 24.2192H21.878Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_2753_28148"
        x="0"
        y="0"
        width="54"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.945098 0 0 0 0 0.95498 0 0 0 0 0.980392 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2753_28148" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2753_28148" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_2753_28148" x1="36" y1="31" x2="16.5" y2="5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2D7FF9" />
        <stop offset="1" stopColor="#2D7FF9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default GenericDocumentIcon;
