import React from 'react';

import { ProgressBar, TopProgressContainer } from './TopProgress.styles';

interface TopProgressProps {
  className?: string;
}

const TopProgress = ({ className }: TopProgressProps) => {
  return (
    <TopProgressContainer className={className}>
      <ProgressBar />
    </TopProgressContainer>
  );
};

export default TopProgress;
