import React, { ReactNode } from 'react';

import { WithChildren } from '../../interfaces';
import PopoverButton from '../PopoverButton';
import { PopoverPlacement, PopoverRow } from '../PopoverButton/PopoverButton.styles';
import { ButtonSection, Content, RowBasedListItemContainer, StyledVertDotsIcon } from './RowBasedListItem.styles';

interface RowBasedListItemProps extends WithChildren {
  disabled?: boolean;
}
const RowBasedListItem = ({ children, disabled = false }: RowBasedListItemProps) => {
  return <RowBasedListItemContainer $disabled={disabled}>{children}</RowBasedListItemContainer>;
};

/**
 * Expects a `ReactNode` of `RowBasedListItem.MenuItem`.
 */
const MoreMenu = ({
  open,
  onOpenChange,
  children,
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  return (
    <ButtonSection>
      <PopoverButton
        isOpen={open}
        onOpenChange={onOpenChange}
        triggerDisplay={<StyledVertDotsIcon $active={open} />}
        placement={PopoverPlacement.bottomRight}
      >
        {children}
      </PopoverButton>
    </ButtonSection>
  );
};

RowBasedListItem.MoreMenu = MoreMenu;
RowBasedListItem.MenuItem = PopoverRow;
RowBasedListItem.Content = Content;

export default RowBasedListItem;
