import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import Divider from '../Divider';

export const TabsInnerContainer = styled.div`
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  gap: 1rem;
`;

export const StyledDivider = styled(Divider)`
  margin-top: -1px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0.2rem;
`;
interface TabProps {
  $isActive: boolean;
}
export const Tab = styled(NavLink)<TabProps>`
  padding-bottom: 1rem;
  color: ${({ theme, $isActive }: ThemeProp & TabProps) =>
    $isActive ? theme.colors.primary : theme.colors.primaryLight};
  ${({ theme, $isActive }: ThemeProp & TabProps) => $isActive && `border-bottom: 2px solid ${theme.colors.primary}`};
  white-space: no-wrap;
  &:focus-visible {
    outline: 1px auto ${({ theme }: ThemeProp) => theme.colors.primary};
  }
`;

export const Title = styled.div`
  margin: auto 0;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.primary};
`;

export const ScrollHint = styled.div`
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 1.5rem;
  height: 100%;
  z-index: 4;
  background: linear-gradient(270deg, #f9f9fe 26.37%, rgba(249, 249, 254, 0) 100%);
`;

export const PageContainer = styled.div`
  width: 100%;
  padding: 2rem 5rem;
`;
