import React, { Key } from 'react';

import {
  CheckboxGroupContainer,
  CheckboxGroupOptionsContainer,
  Label,
  StyledCheckbox,
  StyledSelectAllCheckbox,
} from './CheckboxGroup.styles';

interface CheckboxGroupProps<T> {
  label?: string;
  options: Array<{ key: T; label: string; value: boolean }>;
  onOptionChange: (option: T, value: boolean) => void;
  className?: string;
  selectAllOnChange?: () => void;
}

const CheckboxGroup = <T extends Key>({
  label,
  options,
  onOptionChange,
  className,
  selectAllOnChange,
}: CheckboxGroupProps<T>) => {
  const selectAll = !!selectAllOnChange;
  const allTrue = options.every(({ value }) => value);
  const someTrue = !allTrue && options.some(({ value }) => value);
  const checkbox = options.map(({ key, value, label: choiceLabel }) => (
    <StyledCheckbox
      key={key}
      label={choiceLabel}
      isSelected={value}
      onChange={(e) => {
        onOptionChange(key, e);
      }}
    />
  ));

  return (
    <CheckboxGroupContainer className={className}>
      {selectAll ? (
        <StyledSelectAllCheckbox
          intermediate={someTrue}
          isSelected={allTrue}
          label={<Label>{label}</Label>}
          onChange={selectAllOnChange}
        />
      ) : (
        label && <Label>{label}</Label>
      )}
      <CheckboxGroupOptionsContainer $selectAll={selectAll}>{checkbox}</CheckboxGroupOptionsContainer>
    </CheckboxGroupContainer>
  );
};

export default CheckboxGroup;
