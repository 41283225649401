import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const AvatarContainer = styled.div<{ $squared: boolean }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  aspect-ratio: 1/1;
  background-color: ${(props: ThemeProp) => props.theme.colors.secondary}15;
  border-radius: ${({ $squared }) => ($squared ? '20%' : '50%')};
`;

export const AvatarText = styled.div`
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.ml};
  font-weight: ${({ theme }: ThemeProp) => theme.fontWeight.medium};
  color: ${({ theme }: ThemeProp) => theme.colors.primary};
  padding: 0.5rem;
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
