import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const checkedRadioSpanSize = '0.375rem';

export const RadioGroupContainer = styled.div``;

export const InputLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
`;
interface RadioButtonProps {
  checked: boolean;
}

export const RadioButton = styled.span<RadioButtonProps>`
  position: relative;
  height: 1rem;
  width: 1rem;
  background-color: ${({ theme, checked }: ThemeProp & RadioButtonProps) =>
    checked ? theme.colors.primary : theme.colors.white};
  border-radius: 50%;
  ${({ checked }: RadioButtonProps) =>
    checked
      ? `&:after {
        content: '';
        position: absolute;
        top: calc(50% - ${checkedRadioSpanSize} / 2);
        left: calc(50% - ${checkedRadioSpanSize} / 2);
        width: ${checkedRadioSpanSize};
        height: ${checkedRadioSpanSize};
        border-radius: 50%;
        background: white;`
      : `border: 1px solid #ccc;`}
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  width: 0;
  height: 0;
  scale: 0;
  &:focus-visible ~ ${RadioButton} {
    outline: 2px solid ${({ theme }: ThemeProp) => theme.colors.primary};
    outline-offset: 2px;
  }
`;
