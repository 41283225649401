import React from 'react';

const UserAvatarPlaceholder = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#818181"
      strokeWidth="1.5"
      {...props}
    >
      <path
        d="M13.5 14.0625V12.9375C13.5 12.3408 13.2629 11.7685 12.841 11.3465C12.419 10.9246 11.8467 10.6875 11.25 10.6875H6.75C6.15326 10.6875 5.58097 10.9246 5.15901 11.3465C4.73705 11.7685 4.5 12.3408 4.5 12.9375V14.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.4375C10.2426 8.4375 11.25 7.43014 11.25 6.1875C11.25 4.94486 10.2426 3.9375 9 3.9375C7.75736 3.9375 6.75 4.94486 6.75 6.1875C6.75 7.43014 7.75736 8.4375 9 8.4375Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserAvatarPlaceholder;
