import { useEffect, useState } from 'react';

/**
 * Hook to help with handling the loading and error states of a promise
 * @param promise A promise factory function that fetch the promise run on mount.
 * @returns An object containing the loading and error states of the promise as well as the resulting data.
 */
export const usePromise = <T>(promise: () => Promise<T>, dependencies: ReadonlyArray<unknown> = []) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    promise()
      .then((result) => setData(result))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, dependencies);

  return { loading, data, error };
};
