import React from 'react';

const Envelope = () => (
  <svg width="55" height="34" viewBox="0 0 55 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.15625 0C2.30957 0 0 1.88965 0 4.21875C0 5.5459 0.762695 6.79394 2.0625 7.59375L25.4375 21.9375C26.6621 22.6846 28.3379 22.6846 29.5625 21.9375L52.9375 7.59375C54.2373 6.79394 55 5.5459 55 4.21875C55 1.88965 52.6904 0 49.8438 0H5.15625ZM0 9.84375V28.125C0 31.2275 3.08301 33.75 6.875 33.75H48.125C51.917 33.75 55 31.2275 55 28.125V9.84375L31.625 24.1875C29.1758 25.6904 25.8242 25.6904 23.375 24.1875L0 9.84375Z"
      fill="black"
    />
  </svg>
);

export default Envelope;
