import React from 'react';

import { AvatarContainer, AvatarImg, AvatarText } from './Avatar.styles';

interface AvatarProps {
  text?: string;
  src?: string;
  squared?: boolean;
  className?: string;
}

const Avatar = ({ text, src, className, squared }: AvatarProps) => {
  return (
    <AvatarContainer $squared={squared} className={className}>
      {src ? <AvatarImg src={src} /> : <AvatarText>{text}</AvatarText>}
    </AvatarContainer>
  );
};

export default Avatar;
