import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface CardProps {
  checked: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  $hidden?: boolean;
}

export const StyledCard = styled.div`
  display: flex;
  padding: 0.75rem;
  margin-bottom: 1rem;
  gap: 0.875rem;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c100};
  border-radius: 0.5rem;
`;

export const Card = styled.li<CardProps>`
  display: flex;
  padding: 0.6875rem;
  margin: 0;
  gap: 0.875rem;
  border-style: ${({ disabled }) => (disabled ? 'dashed' : 'solid')};
  border-width: 1px;
  border-color: ${({ checked, highlighted }) =>
    checked || highlighted
      ? (props: ThemeProp) => props.theme.colors.primary
      : (props: ThemeProp) => props.theme.colors.c100};
  border-radius: 0.5rem;

  background-color: ${(props: CardProps & ThemeProp) => {
    if (props.$hidden) {
      return props.theme.colors.c050;
    }
    if (props.highlighted) {
      return `${props.theme.colors.primary}0A`;
    }
    return props.theme.colors.white;
  }};

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: transparent solid 2px;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px ${({ theme }: ThemeProp) => theme.colors.white},
      0 0 0 4px ${({ theme }: ThemeProp) => theme.colors.primary};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;
