import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppContainerContext } from '../../../contexts/AppContainerContext';
import Modal from '../Modal';
import { ButtonTitle, StyledLink, Title } from './ContactUsModal.styles';

export const ContactUsModal = ({ open, onClose }: { open: boolean; onClose?: () => void }) => {
  const { t } = useTranslation();
  const { setShowContactModal } = useAppContainerContext();

  return (
    <Modal open={open} onClose={onClose ?? (() => setShowContactModal(false))}>
      <Title>{t('contactUs.title')}</Title>
      <StyledLink href="mailto:ProtonComplianceServices@protontechs.com">
        <ButtonTitle>{t('contactUs.emailUs.title')}</ButtonTitle>
        <div>{t('contactUs.emailUs.description', { email: 'ProtonComplianceServices@protontechs.com' })}</div>
      </StyledLink>
      <StyledLink href="https://calendar.app.google/BSogC8B88JGb9Pib8" target="_blank">
        <ButtonTitle>{t('contactUs.scheduleAppointment.title')}</ButtonTitle>
        <div>{t('contactUs.scheduleAppointment.description')}</div>
      </StyledLink>
    </Modal>
  );
};
