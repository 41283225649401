import React from 'react';

const EmailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="35" viewBox="0 0 55 35" fill="none">
    <path
      d="M5.15625 0.613281C2.30957 0.613281 0 2.50293 0 4.83203C0 6.15918 0.762695 7.40723 2.0625 8.20703L25.4375 22.5508C26.6621 23.2979 28.3379 23.2979 29.5625 22.5508L52.9375 8.20703C54.2373 7.40723 55 6.15918 55 4.83203C55 2.50293 52.6904 0.613281 49.8438 0.613281H5.15625ZM0 10.457V28.7383C0 31.8408 3.08301 34.3633 6.875 34.3633H48.125C51.917 34.3633 55 31.8408 55 28.7383V10.457L31.625 24.8008C29.1758 26.3037 25.8242 26.3037 23.375 24.8008L0 10.457Z"
      fill="black"
    />
  </svg>
);

export default EmailIcon;
