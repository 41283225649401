import { Auth } from 'aws-amplify';
import React, { createContext, FC, ReactNode, useState } from 'react';

type Nullable<T> = T | null;

export interface AuthContextProps {
  authToken: Nullable<string>;
  updateAuthToken: () => Promise<boolean>;
}

interface AuthProviderProps {
  children: ReactNode;
}

/* eslint-disable */
const defaultProps: AuthContextProps = {
  authToken: null,
  updateAuthToken: async () => false,
};
/* eslint-enable */

const AuthContext = createContext<AuthContextProps>(defaultProps);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<Nullable<string>>(null);

  /**
   * Attempts to get a new JWT token for the current session.
   * @returns {boolean} indicating if the token was successfully updated
   */
  const updateAuthToken = (): Promise<boolean> =>
    Auth.currentSession()
      .then((auth) => {
        setAuthToken(auth.getIdToken().getJwtToken());
        return true;
      })
      .catch((err) => {
        if (err !== 'No current user') {
          alert(err);
          console.error({
            message: 'Error while updating auth token',
            error: err?.stack || err,
          });
        }
        setAuthToken(null);
        return false;
      });

  return (
    <AuthContext.Provider
      value={{
        authToken,
        updateAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => AuthContextProps = () => {
  return React.useContext(AuthContext);
};
