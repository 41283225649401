import React from 'react';

const DevicesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="55" height="40" viewBox="0 0 55 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M54.9856 19.9609C54.9856 21.3672 53.5532 22.4688 51.9298 22.4688H48.8739L48.9408 34.9844C48.9408 35.1953 48.9217 35.4062 48.893 35.6172V36.875C48.893 38.6016 47.1837 40 45.0733 40H43.5454C43.4403 40 43.3353 40 43.2302 39.9922C43.0965 40 42.9629 40 42.8292 40H39.7256H37.4337C35.3233 40 33.614 38.6016 33.614 36.875V35V30C33.614 28.6172 32.2484 27.5 30.5582 27.5H24.4465C22.7563 27.5 21.3907 28.6172 21.3907 30V35V36.875C21.3907 38.6016 19.6814 40 17.5709 40H15.2791H12.2328C12.0896 40 11.9463 39.9922 11.8031 39.9844C11.6885 39.9922 11.5739 40 11.4593 40H9.9314C7.82098 40 6.11163 38.6016 6.11163 36.875V28.125C6.11163 28.0547 6.11163 27.9766 6.12118 27.9062V22.4688H3.05582C1.33692 22.4688 0 21.375 0 19.9609C0 19.2578 0.286483 18.6328 0.954942 18.0859L25.4397 0.625C26.1081 0.078125 26.8721 0 27.5405 0C28.209 0 28.9729 0.15625 29.5459 0.546875L53.9351 18.0859C54.6991 18.6328 55.0811 19.2578 54.9856 19.9609Z"
      fill="black"
    />
  </svg>
);

export default DevicesIcon;
