import { useEffect } from 'react';

export const useConditionalEventListener = (
  condition: boolean | (() => boolean),
  event: string,
  listener: (e) => void,
  dependencies: any[] = [],
  listenToCapture?: boolean,
) => {
  useEffect(() => {
    if (condition) {
      document.addEventListener(event, listener, listenToCapture);
      return () => {
        document.removeEventListener(event, listener, listenToCapture);
      };
    }
    return undefined;
  }, [condition, event, listener, ...dependencies]);
};
