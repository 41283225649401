import React from 'react';

import { ButtonRole } from '../Button';
import FileUploader from '../FileUploader';
import Spinner from '../Spinner';
import { AvatarContainer, EditAvatarButton, EditIcon, LoadingWrapper, StyledAvatar } from './AvatarUploader.styles';

interface AvatarUploaderProps {
  avatarSize?: string;
  imageUrl?: string;
  onUploadFinish: (fileUrl: string) => void;
  avatarInitials?: string;
}

const AvatarUploader = ({ imageUrl, onUploadFinish, avatarSize = '10rem', avatarInitials }: AvatarUploaderProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadingAvatar, setUploadingAvatar] = React.useState(false);

  const onAvatarClick = () => {
    fileInputRef?.current.click();
  };

  return (
    <>
      <FileUploader
        ref={fileInputRef}
        setLoading={setUploadingAvatar}
        onUploadFinish={onUploadFinish}
        accept="image/*"
      />
      <AvatarContainer avatarSize={avatarSize}>
        <StyledAvatar squared text={!uploadingAvatar && avatarInitials} src={!uploadingAvatar && imageUrl} />
        {uploadingAvatar && (
          <LoadingWrapper>
            <Spinner size="lg" />
          </LoadingWrapper>
        )}
        <EditAvatarButton buttonRole={ButtonRole.IconOnly} icon={<EditIcon />} onClick={onAvatarClick} />
      </AvatarContainer>
    </>
  );
};

export default AvatarUploader;
