import React from 'react';

const InformationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.101469"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="#2D7FF9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5H7.00833C7.56062 5 8.00833 4.55228 8.00833 4C8.00833 3.44772 7.56062 3 7.00833 3H7ZM8 7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7V10C6 10.5523 6.44772 11 7 11C7.55228 11 8 10.5523 8 10V7Z"
        fill="#white"
      />
    </svg>
  );
};

export default InformationIcon;
