import { Dispatch, useMemo } from 'react';

import { ModalProps } from '../components/Modal/Modal';
import { DeepPartial, SetUpdateStateAction, useUpdateState } from './useUpdateState';

interface ModalInternalState<T> {
  modalProps: ModalProps;
  contentProps?: T;
}

export interface ModalState<T> extends ModalInternalState<T> {
  update: Dispatch<SetUpdateStateAction<ModalInternalState<T>>>;
  updateModal: Dispatch<DeepPartial<ModalProps>>;
  updateProps: Dispatch<DeepPartial<T>>;
}

export const useModalState = <T>(initialState?: Partial<ModalInternalState<T>>): ModalState<T> => {
  const [state, updateState] = useUpdateState<ModalInternalState<T>>({
    modalProps: { open: false, children: '' },
    ...initialState,
  });

  return useMemo(
    () => ({
      modalProps: state.modalProps,
      contentProps: state.contentProps,
      update: updateState,
      updateModal: (modalProps) => updateState({ modalProps }),
      updateProps: (props) => updateState({ contentProps: props }),
    }),
    [state],
  );
};
