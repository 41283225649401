import React from 'react';

const CompleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#2D7FF9"
    {...props}
  >
    <rect opacity="0.08" width="32" height="32" rx="6" fill="#2D7FF9" />
    <g clipPath="url(#clip0_628_1596)">
      <path
        d="M23.5 15.31V16C23.4991 17.6173 22.9754 19.191 22.007 20.4864C21.0386 21.7818 19.6775 22.7294 18.1265 23.1879C16.5756 23.6465 14.9179 23.5914 13.4009 23.031C11.8838 22.4705 10.5885 21.4346 9.70822 20.0778C8.82795 18.721 8.40984 17.1161 8.51626 15.5023C8.62267 13.8884 9.24791 12.3523 10.2987 11.1228C11.3495 9.89339 12.7696 9.03656 14.3471 8.68013C15.9247 8.3237 17.5752 8.48677 19.0525 9.14502"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23.5 10L16 17.5075L13.75 15.2575" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_628_1596">
        <rect width="18" height="18" fill="white" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default CompleteIcon;
