import React from 'react';

const ExpandIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 12L14 12" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.57143 15.5715L5 12.0001" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.57143 8.42857L5 12" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 18L18 6" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ExpandIcon;
