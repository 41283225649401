import React from 'react';

const VerticalDotsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.1004 11.9996C11.1004 12.4967 11.5033 12.8996 12.0004 12.8996C12.4974 12.8996 12.9004 12.4967 12.9004 11.9996C12.9004 11.5026 12.4974 11.0996 12.0004 11.0996C11.5033 11.0996 11.1004 11.5026 11.1004 11.9996Z"
      fill="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1004 18.2999C11.1004 18.797 11.5033 19.1999 12.0004 19.1999C12.4974 19.1999 12.9004 18.797 12.9004 18.2999C12.9004 17.8028 12.4974 17.3999 12.0004 17.3999C11.5033 17.3999 11.1004 17.8028 11.1004 18.2999Z"
      fill="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1004 5.6998C11.1004 6.19686 11.5033 6.5998 12.0004 6.5998C12.4974 6.5998 12.9004 6.19686 12.9004 5.6998C12.9004 5.20275 12.4974 4.7998 12.0004 4.7998C11.5033 4.7998 11.1004 5.20275 11.1004 5.6998Z"
      fill="#ABABAB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerticalDotsIcon;
