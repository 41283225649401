import React from 'react';

const DlrSecuredLogo = () => {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24.5477C11.8084 24.5477 11.6167 24.4997 11.425 24.4518C0.835462 20.1872 1.60213 7.68099 1.74588 6.24349C1.7938 5.81224 2.0813 5.47682 2.51255 5.38099C8.4063 4.23099 10.6584 2.45807 11.3292 1.69141C11.5209 1.54766 11.7605 1.45182 12 1.45182C12.2396 1.45182 12.4792 1.54766 12.6709 1.73932C13.3896 2.45807 15.6417 4.27891 21.4875 5.42891C21.9188 5.52474 22.2063 5.86016 22.2542 6.29141C22.398 7.68099 23.1167 20.1872 12.5271 24.4518C12.3834 24.4997 12.1917 24.5477 12 24.5477Z"
        fill="#0054A6"
      />
      <path
        d="M12.0001 1.78755C12.1439 1.78755 12.2876 1.83546 12.4314 1.97921C13.2939 2.84171 15.6418 4.61463 21.4397 5.76463C21.6793 5.81255 21.871 6.00421 21.9189 6.29171C22.1106 8.5438 22.446 20.0438 12.4314 24.1167C12.2876 24.1646 12.1439 24.2125 12.0001 24.2125C11.8564 24.2125 11.7126 24.1646 11.5689 24.1167C1.5543 20.0917 1.88972 8.59171 2.08139 6.29171C2.08139 6.00421 2.27305 5.81255 2.56055 5.76463C8.35847 4.66255 10.7543 2.88963 11.5689 1.97921C11.7126 1.88338 11.8564 1.78755 12.0001 1.78755ZM12.0001 1.0688C11.6647 1.0688 11.3293 1.21255 11.0897 1.45213C10.4189 2.17088 8.21472 3.89588 2.46472 4.99796C1.88972 5.0938 1.45847 5.62088 1.41055 6.19588C1.2668 7.63338 0.500137 20.3792 11.3293 24.7396C11.5689 24.8355 11.7606 24.8834 12.0001 24.8834C12.2397 24.8834 12.4793 24.8355 12.671 24.7396C23.5001 20.3792 22.7335 7.63338 22.5897 6.19588C22.5418 5.62088 22.1106 5.14171 21.5356 5.04588C15.7856 3.9438 13.5814 2.17088 12.9106 1.45213C12.7189 1.21255 12.3356 1.0688 12.0001 1.0688Z"
        fill="white"
      />
      <path
        d="M15.6896 10.9874H15.45V9.45405C15.45 7.63321 13.9646 6.1478 12.1438 6.1478H12.0958C10.275 6.1478 8.7896 7.63321 8.7896 9.45405V10.9874H8.35835C7.87918 10.9874 7.49585 11.3707 7.49585 11.8499V17.1686C7.49585 17.6478 7.87918 18.0311 8.35835 18.0311H15.7854C16.2646 18.0311 16.6479 17.6478 16.6479 17.1686V11.8978C16.6 11.3707 16.2167 10.9874 15.6896 10.9874ZM12.0479 7.10613H12.0958C13.3896 7.10613 14.4438 8.1603 14.4438 9.45405V10.9874H9.6521V9.45405C9.70002 8.1603 10.7542 7.10613 12.0479 7.10613ZM12.9583 16.7374H11.0417L11.5688 14.4853C11.0417 14.2457 10.7542 13.6228 10.9938 13.0478C11.2333 12.5207 11.8563 12.2332 12.4313 12.4728C12.9583 12.7124 13.2458 13.3353 13.0063 13.9103C12.9104 14.1499 12.7188 14.3415 12.4313 14.4853L12.9583 16.7374Z"
        fill="white"
      />
    </svg>
  );
};

export default DlrSecuredLogo;
