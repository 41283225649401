export const safelyJoinPaths = (...args: Array<string>): string => {
  const trailingSlash = '/';

  return args.reduce((pathParts, arg, index) => {
    const lastArg = index === args.length - 1;
    const argHasNoTrailingSlash = arg.charAt(arg.length - 1) !== trailingSlash;

    if (!lastArg && argHasNoTrailingSlash) {
      return pathParts.concat(arg, trailingSlash);
    }

    return pathParts.concat(arg);
  }, '');
};
