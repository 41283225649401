import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import React, { useState } from 'react';

import { StyledDocument, StyledPage, StyledSpinner } from './PdfRenderer.styles';

interface Props {
  fileUrl: string | { url: string };
  onDocumentLoadSuccess?: ({ numPages }: { numPages: any }) => void;
  noMargin?: boolean;
  pageWidth?: number;
}

const PdfRenderer = ({ fileUrl, onDocumentLoadSuccess, noMargin = false, pageWidth = 800 }: Props) => {
  // State
  const [numPages, setNumPages] = useState(null);

  // Handlers
  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    onDocumentLoadSuccess?.({ numPages });
  };

  return (
    <StyledDocument
      id="pdf-container"
      onLoadSuccess={handleLoadSuccess}
      file={fileUrl}
      loading={<StyledSpinner />}
      $noMargin={noMargin}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <StyledPage loading={<StyledSpinner />} width={pageWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </StyledDocument>
  );
};

export default PdfRenderer;
