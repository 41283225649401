import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import { Button } from '../Button';
import { Label } from '../Button.styles';

interface ButtonProps {
  $completed?: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props: ThemeProp & ButtonProps) => (props.$completed ? '#16B255' : props.theme.colors.white)};
  border: 1px solid ${(props: ThemeProp & ButtonProps) => (props.$completed ? 'transparent' : props.theme.colors.c100)};
  ${Label} {
    color: ${(props: ThemeProp & ButtonProps) => (props.$completed ? props.theme.colors.white : '#16b355')};
  }
  stroke: ${(props: ThemeProp & ButtonProps) => (props.$completed ? props.theme.colors.white : '#16b355')};
  stroke-opacity: ${(props: ButtonProps) => (props.$completed ? 0.6 : 0.45)};
`;
