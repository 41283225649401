import styled from 'styled-components';

import { CompletedCheckIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

const defaultDropzoneSize = 25;

interface DropzoneProps {
  disabled?: boolean;
  uploaded?: boolean;
  isError?: boolean;
}

const getBorderColor = (props: DropzoneProps & ThemeProp) => {
  if (props.isError) {
    return props.theme.colors.red;
  }
  if (props.uploaded || props.disabled) {
    return props.theme.colors.c100;
  }
  return props.theme.colors.primary;
};

export const Dropzone = styled.div<DropzoneProps>`
  padding: 1rem;
  background-color: ${(props: DropzoneProps & ThemeProp) =>
    props.disabled ? props.theme.colors.c050 : props.theme.colors.white};
  border-width: 1px;
  border-color: ${(props: DropzoneProps & ThemeProp) => getBorderColor(props)};
  border-style: ${(props: DropzoneProps & ThemeProp) =>
    props.disabled || props.uploaded || props.isError ? 'solid' : 'dashed'};
  align-items: center;
  display: flex;
  border-radius: 0.25rem;
  gap: 1rem;
  cursor: ${(props: DropzoneProps) => (props.uploaded ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props: DropzoneProps) => props.disabled && 'none'};

  ${(props: DropzoneProps) => props.disabled && `cursor: not-allowed;`}

  svg:first-child {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${defaultDropzoneSize}rem;
  overflow: hidden;
  gap: 0.75rem;
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  color: ${(props: ThemeProp) => props.theme.colors.c500};
  cursor: pointer;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-left: 0.5rem;
  text-decoration: none;
`;

export const FileName = styled.div`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  color: ${(props: ThemeProp) => props.theme.colors.black};
  display: inline-block;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DesktopTitleContainer = styled.div`
  display: flex;
  gap: 0.3rem;
`;

export const DesktopGreyTitle = styled.span`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
`;

export const BlueText = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.primary};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const FileInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledCheckIcon = styled(CompletedCheckIcon)`
  path {
    fill: ${(props: ThemeProp) => props.theme.colors.primary}1a;
  }

  path:last-child {
    stroke: ${(props: ThemeProp) => props.theme.colors.primary};
  }

  height: 1.5rem;
  width: 1.5rem;
`;
