import React from 'react';

const TaskCheckbox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="10.25" fill="white" stroke="#DCE1E6" strokeWidth="1.5" />
    <path d="M6 10.4706L9.7037 14L16 8" stroke="#A3AEB8" strokeWidth="1.7" />
  </svg>
);

export default TaskCheckbox;
