export const sortComparer = <T>(selector: (item: T) => number, order: 'ascending' | 'descending' = 'ascending') =>
  order === 'ascending' ? (a: T, b: T) => selector(a) - selector(b) : (a: T, b: T) => selector(b) - selector(a);

export const alphabetizeComparer = <T>(
  selector: (item: T) => string,
  order: 'ascending' | 'descending' = 'ascending',
  options?: Intl.CollatorOptions,
) =>
  order === 'ascending'
    ? (a: T, b: T) => selector(a).localeCompare(selector(b), undefined, options)
    : (a: T, b: T) => selector(b).localeCompare(selector(a), undefined, options);
