import styled from 'styled-components';

import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

const ActionLink = styled.button`
  color: ${(props: ThemeProp) => props.theme.colors.primary};
  border: none;
  border-radius: 0.3rem;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus-visible {
    ${focusedOutline}
  }
`;

export default ActionLink;
