import 'react-phone-input-2/lib/style.css';

import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import ChevronUrl from '../../assets/chevron.svg';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.div<{ $required?: boolean }>`
  font-size: 0.875rem;
  line-height: 1;
  padding-left: 0.5rem;

  ${({ $required }) =>
    $required &&
    `:after {
    content: ' *';
  }`}
`;

interface StyledPhoneInputProps {
  disableDropdown: boolean;
}

export const StyledPhoneInput = styled(PhoneInput)<StyledPhoneInputProps>`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  border: 1px solid ${({ theme }: ThemeProp) => theme.colors.c200};
  border-radius: ${({ theme }: ThemeProp) => theme.borderRadius};

  & .form-control:focus-visible {
    ${focusedOutline}
  }

  & .selected-flag {
    padding-left: 0.75rem;
    ${({ disableDropdown }: StyledPhoneInputProps) => disableDropdown && `cursor: default;`}
  }

  & .selected-flag:focus {
    background-color: transparent;
  }

  & .selected-flag:hover {
    background-color: transparent;
  }

  & .selected-flag.open {
    background-color: transparent;
  }

  & .form-control {
    height: 2.75rem;
    font-family: ${({ theme }: ThemeProp) => theme.fonts.primary};
    font-size: ${({ theme }: ThemeProp) => theme.fontSize.m};
    line-height: 0.5rem;
    border: 0;
  }

  & .flag-dropdown {
    background-color: transparent;
    border: none;
  }

  & .flag-dropdown.open {
    background-color: transparent;
    border: 0;
  }

  & .flag-dropdown.open .selected-flag {
    background-color: transparent;
    border: 0;
  }

  & .selected-flag .arrow {
    background-image: url(${ChevronUrl});
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;
    margin-top: -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  & .selected-flag .arrow.up {
    background-image: url(${ChevronUrl});
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;
    margin-top: -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(180deg);
  }
`;
