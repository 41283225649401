import React from 'react';

export const ProtonLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="49"
    viewBox="0 0 96 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="black"
    strokeWidth="0"
    {...props}
  >
    <path
      d="M92.553 14.3666C92.5047 13.9716 92.2213 13.8249 92.0513 13.7366C92.023 13.7216 91.9963 13.7082 91.9713 13.6932C91.0263 13.1199 90.0013 12.6066 88.9263 12.1682C83.1047 9.7899 76.4747 8.63157 68.658 8.6249C64.5413 8.62157 61.4947 8.00823 58.7847 6.63657L58.2363 6.35823C56.5813 5.51823 54.8697 4.6499 53.088 3.96323C46.7063 1.50157 39.903 0.909901 32.2897 2.1499C27.058 3.00323 21.8813 4.67323 16.463 7.25823C14.5613 8.16657 12.398 8.70823 9.84967 8.9149C9.59134 8.93657 9.33134 8.95157 9.07134 8.96823C7.89467 9.0399 6.678 9.1149 5.53634 9.6699C3.76467 10.5332 3.063 11.9799 3.50967 13.8499L3.813 15.1199L4.12634 13.8532C4.50967 12.3032 5.58467 11.3532 7.513 10.8666C8.113 10.7149 8.778 10.6299 9.48634 10.6116C11.928 10.5499 14.2147 10.0699 16.2863 9.1849L16.633 9.03657C17.9897 8.45657 19.3913 7.85657 20.7813 7.28823C26.5713 4.91823 31.918 3.6249 37.1313 3.33657C42.013 3.06657 46.403 3.6099 50.548 4.9999C52.9113 5.79323 55.1597 6.9149 57.333 8.00157C57.633 8.15157 57.9347 8.30157 58.2347 8.45157C60.913 9.7799 63.5347 10.4266 66.248 10.4266C66.2763 10.4266 66.3063 10.4266 66.3347 10.4266C71.403 10.4032 75.853 10.6816 79.9447 11.2816C85.0397 12.0299 88.7897 13.0816 92.1047 14.6916L92.6297 14.9466L92.553 14.3666Z"
      fill="#D7282F"
    />
    <path
      d="M8.46121 14.8217H12.4629C14.3529 14.8217 15.6195 15.0967 16.6112 15.795C17.5662 16.475 18.1712 17.52 18.1712 18.7683C18.1712 19.9433 17.6395 21.3933 16.3362 22.4033C15.2162 23.2667 13.6929 23.67 11.9312 23.67H10.6829L9.89287 28.1117H6.11121L8.46121 14.8217ZM12.3712 20.8067C13.0879 20.8067 13.5095 20.6417 13.8395 20.3667C14.1145 20.1283 14.3895 19.705 14.3895 19.1183C14.3895 18.6233 14.2062 18.31 13.8762 18.1083C13.5279 17.8883 13.0129 17.8333 12.5179 17.8333H11.7095L11.1779 20.8067H12.3712Z"
      fill="#231F20"
    />
    <path
      d="M20.5405 14.8217H24.7072C26.3772 14.8217 27.5705 15.0417 28.4888 15.63C29.4438 16.235 30.0305 17.19 30.0305 18.4567C30.0305 19.1733 29.8472 20.1633 29.1672 21.0633C28.6538 21.7433 27.9188 22.2933 27.0372 22.605L29.6255 28.1117H25.4405L23.3838 23.0633H22.8522L21.9705 28.1117H18.1888L20.5405 14.8217ZM24.3955 20.475C24.9655 20.475 25.4422 20.3283 25.7722 20.0533C26.0105 19.8517 26.2488 19.5217 26.2488 18.97C26.2488 18.5483 26.1022 18.2717 25.8272 18.0883C25.5338 17.8867 25.1288 17.8317 24.6155 17.8317H23.7888L23.3305 20.475H24.3955Z"
      fill="#231F20"
    />
    <path
      d="M32.9845 16.9517C34.3062 15.4834 36.1962 14.62 38.3629 14.62C41.9062 14.62 44.8979 16.8967 44.8979 20.77C44.8979 22.495 44.3112 24.46 42.9329 25.9834C41.6112 27.4517 39.7212 28.315 37.5545 28.315C34.0112 28.315 31.0195 26.0384 31.0195 22.165C31.0212 20.4384 31.6079 18.475 32.9845 16.9517ZM37.6845 25.0284C38.6945 25.0284 39.5012 24.57 40.0345 23.9267C40.6212 23.23 41.0629 22.1467 41.0629 21.0267C41.0629 19.3384 40.0529 17.9067 38.2362 17.9067C37.2262 17.9067 36.4195 18.365 35.8862 19.0084C35.2995 19.7067 34.8579 20.7884 34.8579 21.9084C34.8579 23.5967 35.8662 25.0284 37.6845 25.0284Z"
      fill="#231F20"
    />
    <path
      d="M50.0186 18.0167H45.8519L46.4203 14.8217H58.5353L57.9669 18.0167H53.8003L52.0203 28.1133H48.2386L50.0186 18.0167Z"
      fill="#231F20"
    />
    <path
      d="M60.1335 16.9517C61.4552 15.4834 63.3452 14.62 65.5118 14.62C69.0552 14.62 72.0468 16.8967 72.0468 20.77C72.0468 22.495 71.4602 24.46 70.0835 25.9834C68.7618 27.4517 66.8718 28.315 64.7052 28.315C61.1618 28.315 58.1702 26.0384 58.1702 22.165C58.1702 20.4384 58.7568 18.475 60.1335 16.9517ZM64.8335 25.0284C65.8435 25.0284 66.6502 24.57 67.1835 23.9267C67.7702 23.23 68.2118 22.1467 68.2118 21.0267C68.2118 19.3384 67.2018 17.9067 65.3852 17.9067C64.3752 17.9067 63.5685 18.365 63.0352 19.0084C62.4485 19.7067 62.0068 20.7884 62.0068 21.9084C62.0068 23.5967 63.0168 25.0284 64.8335 25.0284Z"
      fill="#231F20"
    />
    <path
      d="M72.4685 28.1117L74.8185 14.8217H78.3435L80.6018 18.915C81.2818 20.145 81.9602 21.3933 82.6035 22.6233L82.6402 22.605C82.8235 21.4117 83.0618 19.9617 83.3018 18.6033L83.9618 14.8217H87.5968L85.2468 28.1117H81.7218L79.4635 24.0183C78.7835 22.7883 78.1052 21.54 77.4618 20.31L77.4252 20.3283C77.2418 21.5217 77.0035 22.9717 76.7635 24.33L76.1018 28.1117H72.4685Z"
      fill="#231F20"
    />
    <path
      d="M16.8674 43.552L17.384 43.9836C17.3074 44.102 17.179 44.242 16.999 44.4036C16.819 44.5653 16.5924 44.707 16.319 44.8286C16.0457 44.9503 15.729 45.0103 15.369 45.0103C14.864 45.0103 14.419 44.8936 14.029 44.6586C13.639 44.4236 13.339 44.1053 13.1224 43.702C12.9057 43.2986 12.7974 42.842 12.7974 42.332C12.7974 41.957 12.859 41.6053 12.9824 41.2786C13.1057 40.952 13.2824 40.6636 13.5107 40.4136C13.739 40.1636 14.0124 39.9686 14.329 39.827C14.6457 39.6853 14.9974 39.6153 15.3824 39.6153C15.7474 39.6153 16.0624 39.6703 16.324 39.782C16.5857 39.892 16.804 40.027 16.9757 40.1836C17.149 40.3403 17.284 40.4903 17.3807 40.6353L16.8557 41.0836C16.774 40.9653 16.669 40.8436 16.5424 40.7203C16.4157 40.597 16.259 40.492 16.0707 40.407C15.8824 40.322 15.6557 40.2803 15.3874 40.2803C15.0124 40.2803 14.689 40.3736 14.419 40.5586C14.149 40.7436 13.9407 40.9903 13.794 41.2953C13.6474 41.602 13.574 41.942 13.574 42.3186C13.574 42.6886 13.6474 43.027 13.794 43.3303C13.9407 43.6336 14.1474 43.8736 14.4157 44.052C14.684 44.2286 14.999 44.3186 15.3657 44.3186C15.674 44.3186 15.9257 44.2703 16.1224 44.172C16.319 44.0736 16.474 43.9653 16.589 43.8436C16.709 43.7203 16.8007 43.6236 16.8674 43.552Z"
      fill="#D7282F"
    />
    <path
      d="M20.1852 44.8865V42.5865L18.4102 39.7382H19.2368L20.5802 41.9382L21.9235 39.7382H22.7185L20.9352 42.5865V44.8865H20.1852Z"
      fill="#D7282F"
    />
    <path
      d="M24.1334 44.8865V39.7382H25.8784C26.2334 39.7382 26.5268 39.7982 26.7584 39.9165C26.9901 40.0348 27.1618 40.1932 27.2751 40.3915C27.3884 40.5898 27.4451 40.8098 27.4451 41.0515C27.4451 41.2682 27.3984 41.4548 27.3068 41.6148C27.2151 41.7748 27.1018 41.8998 26.9718 41.9932C26.8401 42.0865 26.7184 42.1432 26.6051 42.1632C26.7084 42.1782 26.8234 42.2115 26.9518 42.2632C27.0801 42.3148 27.2034 42.3882 27.3184 42.4832C27.4334 42.5782 27.5301 42.7048 27.6034 42.8615C27.6784 43.0182 27.7151 43.2132 27.7151 43.4448C27.7151 43.8098 27.6284 44.0982 27.4568 44.3098C27.2851 44.5215 27.0534 44.6698 26.7618 44.7582C26.4718 44.8465 26.1451 44.8898 25.7851 44.8898H24.1334V44.8865ZM24.8518 41.8832H25.7701C25.9251 41.8832 26.0751 41.8582 26.2218 41.8065C26.3684 41.7548 26.4884 41.6732 26.5851 41.5598C26.6801 41.4465 26.7284 41.2965 26.7284 41.1115C26.7284 40.9065 26.6801 40.7498 26.5851 40.6448C26.4901 40.5398 26.3684 40.4682 26.2218 40.4332C26.0751 40.3965 25.9251 40.3798 25.7701 40.3798H24.8518V41.8832ZM24.8518 44.2465H25.7468C25.8801 44.2465 26.0184 44.2382 26.1601 44.2232C26.3018 44.2082 26.4334 44.1715 26.5568 44.1148C26.6801 44.0582 26.7801 43.9732 26.8584 43.8598C26.9351 43.7465 26.9734 43.5948 26.9734 43.4048C26.9734 43.1582 26.9101 42.9715 26.7851 42.8448C26.6584 42.7182 26.5068 42.6332 26.3268 42.5865C26.1468 42.5398 25.9684 42.5165 25.7934 42.5165H24.8518V44.2465Z"
      fill="#D7282F"
    />
    <path
      d="M29.4226 44.8865V39.7382H32.6643V40.3948H30.1643V41.8915H32.0476V42.5548H30.1643V44.2215H32.8509V44.8848H29.4226V44.8865Z"
      fill="#D7282F"
    />
    <path
      d="M34.3738 44.8865V39.7382H36.0171C36.4538 39.7382 36.8038 39.8132 37.0671 39.9615C37.3288 40.1115 37.5188 40.3015 37.6338 40.5332C37.7488 40.7648 37.8071 41.0048 37.8071 41.2515C37.8071 41.5815 37.7304 41.8715 37.5754 42.1232C37.4204 42.3748 37.1821 42.5632 36.8571 42.6865L38.0604 44.8865H37.2271L36.1238 42.7798H35.0888V44.8865H34.3738ZM35.0921 42.1532H36.0804C36.3988 42.1532 36.6404 42.0732 36.8054 41.9132C36.9704 41.7532 37.0521 41.5398 37.0521 41.2732C37.0521 40.9898 36.9638 40.7698 36.7888 40.6132C36.6138 40.4565 36.3721 40.3782 36.0638 40.3782H35.0921V42.1532Z"
      fill="#D7282F"
    />
    <path
      d="M39.2372 44.1149L39.7622 43.6366C39.8605 43.7449 39.9738 43.8533 40.1022 43.9616C40.2305 44.0699 40.3805 44.1599 40.5539 44.2316C40.7255 44.3033 40.9222 44.3399 41.1438 44.3399C41.4838 44.3399 41.7489 44.2616 41.9389 44.1049C42.1289 43.9483 42.2239 43.7416 42.2239 43.4833C42.2239 43.1949 42.1172 42.9816 41.9038 42.8433C41.6905 42.7049 41.3772 42.5916 40.9655 42.5033C40.5289 42.4099 40.1722 42.2416 39.8972 41.9983C39.6222 41.7533 39.4839 41.4283 39.4839 41.0216C39.4839 40.7699 39.5472 40.5366 39.6772 40.3233C39.8055 40.1099 39.9988 39.9366 40.2555 39.8066C40.5122 39.6749 40.8289 39.6099 41.2055 39.6099C41.5405 39.6099 41.8172 39.6549 42.0355 39.7449C42.2539 39.8349 42.4255 39.9366 42.5489 40.0499C42.6722 40.1633 42.7605 40.2566 42.8122 40.3283L42.3189 40.7833C42.2772 40.7316 42.2089 40.6649 42.1105 40.5833C42.0122 40.5016 41.8872 40.4283 41.7355 40.3633C41.5839 40.2999 41.3972 40.2666 41.1755 40.2666C40.9589 40.2666 40.7822 40.3033 40.6422 40.3783C40.5039 40.4533 40.4005 40.5449 40.3338 40.6566C40.2672 40.7666 40.2339 40.8816 40.2339 40.9999C40.2339 41.2516 40.3389 41.4416 40.5505 41.5666C40.7622 41.6933 41.0622 41.7999 41.4538 41.8866C41.7105 41.9433 41.9555 42.0316 42.1872 42.1533C42.4189 42.2749 42.6072 42.4416 42.7539 42.6549C42.9005 42.8683 42.9739 43.1499 42.9739 43.4999C42.9739 43.7983 42.8939 44.0616 42.7339 44.2866C42.5739 44.5133 42.3589 44.6916 42.0855 44.8233C41.8122 44.9549 41.5039 45.0199 41.1589 45.0199C40.8405 45.0199 40.5555 44.9733 40.3055 44.8816C40.0555 44.7899 39.8422 44.6716 39.6655 44.5299C39.4889 44.3866 39.3455 44.2483 39.2372 44.1149Z"
      fill="#D7282F"
    />
    <path
      d="M44.6885 44.8865V39.7382H47.9301V40.3948H45.4301V41.8915H47.3135V42.5548H45.4301V44.2215H48.1168V44.8848H44.6885V44.8865Z"
      fill="#D7282F"
    />
    <path
      d="M53.2986 43.5517L53.8153 43.9834C53.7386 44.1017 53.6086 44.2417 53.4286 44.4034C53.2486 44.565 53.0219 44.7067 52.7486 44.8284C52.4753 44.95 52.1586 45.01 51.7986 45.01C51.2936 45.01 50.8486 44.8934 50.4586 44.6584C50.0703 44.425 49.7686 44.105 49.5519 43.7017C49.3353 43.2984 49.2269 42.8417 49.2269 42.3317C49.2269 41.9567 49.2886 41.605 49.4119 41.2784C49.5353 40.9517 49.7119 40.6634 49.9403 40.4134C50.1686 40.1634 50.4419 39.9684 50.7586 39.8267C51.0753 39.685 51.4269 39.615 51.8119 39.615C52.1769 39.615 52.4919 39.67 52.7536 39.7817C53.0169 39.8917 53.2336 40.0267 53.4053 40.1834C53.5769 40.34 53.7119 40.49 53.8103 40.635L53.2853 41.0834C53.2036 40.965 53.0986 40.8434 52.9719 40.72C52.8453 40.5967 52.6886 40.4917 52.5019 40.4067C52.3136 40.3217 52.0869 40.28 51.8186 40.28C51.4436 40.28 51.1203 40.3734 50.8503 40.5584C50.5803 40.7434 50.3719 40.99 50.2253 41.295C50.0786 41.6017 50.0053 41.9417 50.0053 42.3184C50.0053 42.6884 50.0786 43.0267 50.2253 43.33C50.3719 43.6334 50.5786 43.8734 50.8469 44.0517C51.1136 44.2284 51.4303 44.3184 51.7969 44.3184C52.1053 44.3184 52.3586 44.27 52.5536 44.1717C52.7486 44.0734 52.9053 43.965 53.0203 43.8434C53.1403 43.72 53.2319 43.6234 53.2986 43.5517Z"
      fill="#D7282F"
    />
    <path
      d="M55.2838 42.9265V39.7382H56.0255V42.7482C56.0255 43.0565 56.0638 43.3298 56.1405 43.5665C56.2171 43.8032 56.3521 43.9865 56.5422 44.1182C56.7322 44.2498 56.9971 44.3148 57.3371 44.3148C57.6771 44.3148 57.9405 44.2498 58.1288 44.1182C58.3171 43.9865 58.4488 43.8032 58.5255 43.5665C58.6021 43.3298 58.6421 43.0565 58.6421 42.7482V39.7382H59.3838V42.9265C59.3838 43.3182 59.3105 43.6715 59.1638 43.9882C59.0171 44.3048 58.7938 44.5548 58.4921 44.7415C58.1905 44.9282 57.8071 45.0198 57.3388 45.0198C56.8705 45.0198 56.4838 44.9265 56.1805 44.7415C55.8771 44.5565 55.6521 44.3048 55.5055 43.9882C55.3571 43.6715 55.2838 43.3165 55.2838 42.9265Z"
      fill="#D7282F"
    />
    <path
      d="M61.2877 44.8865V39.7382H62.9311C63.3677 39.7382 63.7177 39.8132 63.9811 39.9615C64.2427 40.1115 64.4327 40.3015 64.5477 40.5332C64.6627 40.7648 64.721 41.0048 64.721 41.2515C64.721 41.5815 64.6444 41.8715 64.4894 42.1232C64.3344 42.3748 64.0961 42.5632 63.7711 42.6865L64.9744 44.8865H64.1411L63.0377 42.7798H62.0027V44.8865H61.2877ZM62.0061 42.1532H62.9944C63.3127 42.1532 63.5544 42.0732 63.7194 41.9132C63.8844 41.7532 63.966 41.5398 63.966 41.2732C63.966 40.9898 63.8777 40.7698 63.7027 40.6132C63.5277 40.4565 63.2861 40.3782 62.9777 40.3782H62.0061V42.1532Z"
      fill="#D7282F"
    />
    <path d="M66.5261 44.8865V39.7382H67.2678V44.8865H66.5261Z" fill="#D7282F" />
    <path d="M70.5589 44.8865V40.3865H68.7139V39.7382H73.1372V40.3865H71.3005V44.8865H70.5589Z" fill="#D7282F" />
    <path
      d="M76.1408 44.8865V42.5865L74.3658 39.7382H75.1925L76.5358 41.9382L77.8792 39.7382H78.6742L76.8908 42.5865V44.8865H76.1408Z"
      fill="#D7282F"
    />
    <path
      d="M6.28442 35.5001V29.8301H8.17942C8.77942 29.8301 9.31109 29.9301 9.77276 30.1318C10.2344 30.3334 10.5978 30.6434 10.8611 31.0618C11.1244 31.4818 11.2561 32.0201 11.2561 32.6768C11.2561 33.3118 11.1244 33.8384 10.8611 34.2584C10.5978 34.6784 10.2328 34.9901 9.76942 35.1934C9.30442 35.3968 8.77276 35.5001 8.17109 35.5001H6.28442ZM7.10109 34.7684H8.15442C8.86276 34.7684 9.41442 34.5934 9.80776 34.2451C10.2011 33.8968 10.3978 33.3801 10.3978 32.6934C10.3978 32.0018 10.2061 31.4734 9.82442 31.1084C9.44109 30.7434 8.87609 30.5601 8.12942 30.5601H7.10109V34.7684Z"
      fill="#898A8D"
    />
    <path
      d="M14.2877 35.5001V29.8301H17.8577V30.5518H15.1027V32.2001H17.1761V32.9318H15.1027V34.7684H18.0611V35.5001H14.2877Z"
      fill="#898A8D"
    />
    <path
      d="M20.6405 35.5001L22.8588 29.8301H23.6922L25.9105 35.5001H25.0005L24.4138 33.9184H22.0855L21.4988 35.5001H20.6405ZM22.3238 33.2301H24.1688L23.2505 30.7401L22.3238 33.2301Z"
      fill="#898A8D"
    />
    <path d="M28.6085 35.5001V29.8301H29.4252V34.7768H32.3752V35.5001H28.6085Z" fill="#898A8D" />
    <path
      d="M35.1456 35.5001V29.8301H38.7156V30.5518H35.9623V32.2001H38.0356V32.9318H35.9623V34.7684H38.9206V35.5001H35.1456Z"
      fill="#898A8D"
    />
    <path
      d="M41.7511 35.5001V29.8301H43.5611C44.0428 29.8301 44.4278 29.9118 44.7178 30.0768C45.0061 30.2418 45.2144 30.4501 45.3428 30.7051C45.4711 30.9601 45.5344 31.2234 45.5344 31.4951C45.5344 31.8584 45.4494 32.1784 45.2794 32.4551C45.1094 32.7318 44.8461 32.9401 44.4894 33.0751L45.8161 35.4984H44.8978L43.6828 33.1784H42.5444V35.5001H41.7511ZM42.5411 32.4918H43.6294C43.9811 32.4918 44.2478 32.4034 44.4278 32.2284C44.6094 32.0534 44.6994 31.8184 44.6994 31.5234C44.6994 31.2118 44.6028 30.9701 44.4111 30.7968C44.2178 30.6234 43.9528 30.5368 43.6128 30.5368H42.5411V32.4918Z"
      fill="#898A8D"
    />
    <path
      d="M48.259 34.6501L48.8374 34.1234C48.9457 34.2417 49.069 34.3617 49.2107 34.4801C49.3524 34.5984 49.5174 34.6984 49.7074 34.7784C49.8974 34.8584 50.114 34.8967 50.3574 34.8967C50.7307 34.8967 51.0224 34.8101 51.2324 34.6367C51.4424 34.4634 51.5474 34.2367 51.5474 33.9517C51.5474 33.6351 51.429 33.4001 51.194 33.2467C50.959 33.0934 50.614 32.9684 50.1607 32.8734C49.679 32.7717 49.2857 32.5851 48.984 32.3167C48.6807 32.0484 48.529 31.6901 48.529 31.2417C48.529 30.9634 48.599 30.7084 48.7407 30.4717C48.8824 30.2367 49.094 30.0467 49.379 29.9017C49.6624 29.7567 50.0107 29.6851 50.424 29.6851C50.7924 29.6851 51.0974 29.7351 51.3374 29.8334C51.5774 29.9317 51.7674 30.0451 51.9024 30.1684C52.0374 30.2917 52.134 30.3951 52.1907 30.4751L51.6474 30.9767C51.6024 30.9201 51.5257 30.8467 51.4174 30.7551C51.309 30.6651 51.1724 30.5834 51.0057 30.5134C50.839 30.4434 50.6324 30.4067 50.389 30.4067C50.1507 30.4067 49.9557 30.4484 49.8024 30.5301C49.649 30.6117 49.5357 30.7151 49.4624 30.8367C49.389 30.9584 49.3524 31.0851 49.3524 31.2151C49.3524 31.4934 49.469 31.7017 49.7007 31.8401C49.9324 31.9784 50.264 32.0967 50.6957 32.1934C50.979 32.2551 51.2474 32.3534 51.504 32.4867C51.759 32.6201 51.9674 32.8034 52.129 33.0384C52.2907 33.2734 52.3707 33.5834 52.3707 33.9684C52.3707 34.2967 52.2824 34.5867 52.1074 34.8351C51.9324 35.0851 51.694 35.2817 51.394 35.4251C51.094 35.5701 50.754 35.6417 50.374 35.6417C50.0224 35.6417 49.709 35.5901 49.434 35.4884C49.159 35.3867 48.924 35.2584 48.729 35.1017C48.534 34.9484 48.3774 34.7967 48.259 34.6501Z"
      fill="#898A8D"
    />
    <path
      d="M55.4148 35.5001V29.8301H56.2315V32.2018H59.3348V29.8301H60.1515V35.5001H59.3348V32.9334H56.2315V35.5001H55.4148Z"
      fill="#898A8D"
    />
    <path d="M63.4304 35.5001V29.8301H64.2471V35.5001H63.4304Z" fill="#898A8D" />
    <path
      d="M67.5262 35.5001V29.8301H69.3196C69.7046 29.8301 70.0229 29.8818 70.2762 29.9868C70.5279 30.0918 70.7279 30.2284 70.8762 30.3984C71.0229 30.5684 71.1279 30.7534 71.1912 30.9551C71.2529 31.1568 71.2846 31.3534 71.2846 31.5451C71.2846 31.9184 71.2029 32.2351 71.0379 32.4934C70.8729 32.7518 70.6462 32.9451 70.3579 33.0751C70.0696 33.2051 69.7379 33.2701 69.3629 33.2701H68.3346V35.5001H67.5262ZM68.3329 32.5768H69.3529C69.7046 32.5768 69.9746 32.4868 70.1646 32.3051C70.3546 32.1234 70.4496 31.8768 70.4496 31.5651C70.4496 31.2484 70.3512 30.9951 70.1562 30.8084C69.9612 30.6218 69.6929 30.5284 69.3529 30.5284H68.3329V32.5768Z"
      fill="#898A8D"
    />
    <path d="M76.2665 35.5001V29.8301H77.7715V35.5001H76.2665Z" fill="#898A8D" />
    <path d="M82.0627 35.5001V31.1484H80.3193V29.8318H85.3093V31.1484H83.5843V35.5001H82.0627Z" fill="#898A8D" />
    <path
      d="M6.10986 37.6065H85.3149"
      stroke="#D7282F"
      strokeWidth="0.333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProtonLogo;
