import styled from 'styled-components';

import NoiseBackground from '../../assets/NoiseBackground.png';
import { ThemeProp } from '../../theme/Themes';
import { headerHeight } from '../WizardHeader/WizardHeader.styles';

const Y_AXIS_PADDING = '4rem';

interface DocumentContainerProps {
  $noMargin?: boolean;
  $marginTop?: string;
}

export const LogoContainer = styled.div<DocumentContainerProps>`
  margin-bottom: 1em;
  align-self: flex-end;
  width: 4em;
  height: 70px;
`;

export const BottomLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13.6px;
`;

export const FlexCover = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DocumentContainer = styled.div<DocumentContainerProps>`
  display: flex;
  flex-grow: 1;
  filter: drop-shadow(0px 33.2px 58.1px rgba(0, 0, 0, 0.04));
  margin: ${(props: DocumentContainerProps) => (props.$noMargin ? '0 auto' : `${Y_AXIS_PADDING} auto`)};
  ${(props: DocumentContainerProps & ThemeProp) => props.$marginTop && `margin-top: ${props.$marginTop}`};
  border-radius: 0.25rem;
  height: calc(
    100vh - ${headerHeight} - ${(props: DocumentContainerProps) => (props.$noMargin ? 0 : Y_AXIS_PADDING)} * 2
  );
  width: auto;
  overflow: auto;
`;

export const DocumentPositioner = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

// Page size reflective of a 279mm sized A4 page
// Conversion obtained from https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units
const PX_PER_MM = 3.78;
export const DOCUMENT_PAGE_HEIGHT = 279 * PX_PER_MM;
export const DOCUMENT_PAGE_WIDTH = 216 * PX_PER_MM;

// NOTE: These styles should reflect the styles defined for the PDF renderer in api/src/pdf/resources.
// Because of this, em is used instead of rem to be relative of the 12pt font size.
export const Document = styled.div`
  height: ${DOCUMENT_PAGE_HEIGHT}px;
  width: ${DOCUMENT_PAGE_WIDTH}px;
  flex-shrink: 0;
  align-self: center;
  font-size: 12pt;
  background-color: ${(props: ThemeProp) => props.theme.colors.white};
`;

export const DocumentCover = styled.div`
  display: grid;
  padding: 79px 55px 40px;
  grid-column: 2;
  grid-row: 1 / span 2;
`;

export const DealershipImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const DocumentTitle = styled.h1`
  font-size: 64px;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  line-height: 65px;
  word-break: break-word;
`;

export const DealershipName = styled.h1`
  font-size: 30px;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  line-height: 40px;
  word-break: break-word;
  margin-top: 1.5rem;
`;

export const DocumentSubtitle = styled.h2`
  font-size: 30px;
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1em;
  margin-top: 1em;
  word-break: break-word;
  flex-grow: 1;
`;

interface DocumentFooterProps {
  $color?: string;
}

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 25%;
`;

const FOOTER_FONT_SIZE = 0.375;
export const DocumentColor = styled.div<DocumentFooterProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 25%;
  padding: ${32.985 / FOOTER_FONT_SIZE}em ${6.2 / FOOTER_FONT_SIZE}em;
  font-size: ${FOOTER_FONT_SIZE}em;
  background: ${(props: DocumentFooterProps & ThemeProp) => props.$color || props.theme.colors.primary};
  border-radius: 0 0 0.25rem 0.25rem;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(${NoiseBackground});
    opacity: 0.6;
  }
`;

export const FooterText = styled.div<DocumentFooterProps>`
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.bold};
  line-height: 1em;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
  align-self: center;
  height: 20px;
`;
