import { createGlobalStyle, css } from 'styled-components';

import { ThemeProp } from './Themes';

const nextUIProviderStyles = css`
  body {
    font-family: IBM Plex Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    text-rendering: geometricprecision;
    -webkit-tap-highlight-color: transparent;
  }
  p {
    font-size: 1rem;
    line-height: 1.75;
  }
  p,
  small {
    color: inherit;
    letter-spacing: -0.05rem;
    font-weight: 400;
    font-family: IBM Plex Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    margin: 0 0 0.625rem 0;
  }
  span {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
  }
  dl,
  dd,
  hr,
  figure,
  p {
    margin: 0px;
  }
  li {
    margin-bottom: 0.625rem;
    font-size: 1rem;
    line-height: 1.75;
  }
  h1 {
    letter-spacing: -0.05em;
    font-size: 3rem;
    line-height: 1.5;
    font-weight: 700;
  }
  h3 {
    letter-spacing: -0.05rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  a {
    cursor: pointer;
    font-size: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-box-align: center;
    align-items: center;
    color: ${({ theme }: ThemeProp) => theme.colors.primary};
    text-decoration: none;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0px;
  }
`;

export const GlobalStyles = createGlobalStyle<ThemeProp>`
  html {
    font-size: 10px;

    @media only screen and (min-width: 601px) and (max-width: 800px) {
      font-size: 10px;
    }

    @media only screen and (min-width: 801px) and (max-width: 1280px) {
      font-size: 12px;
    }

    @media only screen and (min-width: 1281px) and (max-width: 1439px) {
      font-size: 13px;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1920px) {
      font-size: 16px;
    }

    @media only screen and (min-width: 1921px) and (max-width: 3840px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 3840px) {
      font-size: 32px;
    }
  }

  ${nextUIProviderStyles}

  body {
    background: ${({ theme }: ThemeProp): string => theme.colors.white};
    color: ${({ theme }: ThemeProp): string => theme.colors.black};
    margin: 0;
    padding: 0
  }

  h4 {
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    font-size: 16px;
  }

  .rjsf-form {
    width: 100%;
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
      background-color: transparent !important;
  }

  .tooltip-wrapper, .tooltip-pixel-disabled {
    padding: 0.5rem;
    background: ${(props: ThemeProp) => props.theme.colors.black};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    max-width: 15rem;
    color: ${(props: ThemeProp) => props.theme.colors.c100};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};

    // NOTE: These styles could be moved to a "mixin" constant and referenced.
    &--unconstrained {
      padding: 0.5rem;
      background: ${(props: ThemeProp) => props.theme.colors.black};
      border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
      color: ${(props: ThemeProp) => props.theme.colors.c100};
      font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
      font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
    }
  }
`;

export default GlobalStyles;
