import React from 'react';

const LargeX = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
    <path
      d="M6 3.98828C2.69063 3.98828 0 6.67891 0 9.98828V39.9883C0 43.2977 2.69063 45.9883 6 45.9883H42C45.3094 45.9883 48 43.2977 48 39.9883V9.98828C48 6.67891 45.3094 3.98828 42 3.98828H6ZM16.4062 17.3945C17.2875 16.5133 18.7125 16.5133 19.5844 17.3945L23.9906 21.8008L28.3969 17.3945C29.2781 16.5133 30.7031 16.5133 31.575 17.3945C32.4469 18.2758 32.4562 19.7008 31.575 20.5727L27.1687 24.9789L31.575 29.3852C32.4562 30.2664 32.4562 31.6914 31.575 32.5633C30.6937 33.4352 29.2687 33.4445 28.3969 32.5633L23.9906 28.157L19.5844 32.5633C18.7031 33.4445 17.2781 33.4445 16.4062 32.5633C15.5344 31.682 15.525 30.257 16.4062 29.3852L20.8125 24.9789L16.4062 20.5727C15.525 19.6914 15.525 18.2664 16.4062 17.3945Z"
      fill="black"
    />
  </svg>
);

export default LargeX;
