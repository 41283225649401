import React, { useEffect, useRef } from 'react';

import { ButtonRole } from '../Button';
import FocusTrap from '../FocusTrap';
import { CloseButton, ModalBackdrop, ModalContainer, StyledCloseIcon } from './Modal.styles';

export interface ModalProps {
  open: boolean;
  onClose?: () => void;
  preventClose?: boolean;
  hideCloseButton?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Modal = ({ open, children, onClose, preventClose, hideCloseButton, className }: ModalProps) => {
  const backgroundRef = useRef(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (backgroundRef?.current === e.target) {
      onClose?.();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose?.();
    }
  };

  useEffect(() => {
    if (!preventClose && open) {
      document.addEventListener('mouseup', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return undefined;
  }, [open, preventClose]);

  return (
    <ModalBackdrop $open={open} ref={backgroundRef}>
      <FocusTrap isTrapped={open}>
        <ModalContainer className={className}>
          {!hideCloseButton && (
            <CloseButton buttonRole={ButtonRole.IconOnly} icon={<StyledCloseIcon />} onClick={onClose} />
          )}
          {children}
        </ModalContainer>
      </FocusTrap>
    </ModalBackdrop>
  );
};

export default Modal;
