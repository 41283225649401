import React from 'react';

const PenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7482 5.46327L14.5362 4.25129C14.201 3.91615 13.6585 3.91615 13.3242 4.25129L4.25065 13.3248C4.08951 13.4851 3.99951 13.7037 3.99951 13.9308V15.9999H6.06862C6.29576 15.9999 6.51432 15.9099 6.67461 15.7488L15.7482 6.67525C16.0833 6.34097 16.0833 5.79841 15.7482 5.46327V5.46327Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.2076 8.20805L11.7905 5.79095" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PenIcon;
