import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const StyledHr = styled.hr`
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background: ${(props: ThemeProp) => props.theme.colors.c200};
  border: none;
  margin: 0;
`;
