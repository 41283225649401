import React from 'react';

export const CircleArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.25 6V10.5H15.75" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.3675 14.2498C17.8812 15.6301 16.9595 16.815 15.7413 17.6259C14.523 18.4368 13.0742 18.8298 11.6132 18.7457C10.1522 18.6616 8.75807 18.105 7.6409 17.1597C6.52374 16.2143 5.74406 14.9316 5.41933 13.5046C5.09461 12.0777 5.24243 10.5838 5.84053 9.24816C6.43863 7.91252 7.45461 6.80744 8.73537 6.09941C10.0161 5.39139 11.4923 5.11879 12.9415 5.32268C14.3907 5.52657 15.7343 6.19591 16.77 7.22984L20.25 10.4998"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleArrowIcon;
