import React from 'react';

const RoadMapIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="4" fill="#F4F4F4" />
      <path
        opacity="0.2"
        d="M8.00002 40L8 24C8 21.7909 9.79086 20 12 20H27C29.2091 20 31 18.2091 31 16V0"
        stroke="url(#paint0_linear_2500_14553)"
        strokeDasharray="3 3"
      />
      <circle cx="20" cy="20" r="4" fill="#2D7FF9" />
      <circle cx="20" cy="20" r="4" fill="#2D7FF9" />
      <circle cx="8" cy="31" r="2" fill="#18BFFF" />
      <circle cx="31" cy="8" r="2" fill="#00C06C" />
      <defs>
        <linearGradient
          id="paint0_linear_2500_14553"
          x1="4.5"
          y1="40"
          x2="4.5"
          y2="9.47271e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00477109" stopOpacity="0" />
          <stop offset="0.133129" />
          <stop offset="0.860211" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RoadMapIcon;
