import styled from 'styled-components';

import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';
import Popover from '../Popover';

export const TooltipContent = styled(Popover)`
  color: ${(props: ThemeProp) => props.theme.colors.c100};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  background-color: ${(props: ThemeProp) => props.theme.colors.black};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  border-radius: 0.325rem;
  max-width: 20rem;
  min-width: 0rem;
  padding: 0.3rem;
`;

export const TooltipTrigger = styled.span`
  border-radius: 0.3rem;

  &:focus-visible {
    ${focusedOutline}
  }
`;

export const TooltipContainer = styled.div``;
