import React from 'react';

import { StyledHr } from './Divider.styles';

interface DividerProps {
  className?: string;
}

const Divider = ({ className }: DividerProps) => {
  return <StyledHr className={className} />;
};

export default Divider;
