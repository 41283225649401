import styled from 'styled-components';

import { CloseIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Button from '../Button';

const transitionTime = '0.2s';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  top: 50%;
  left: 50%;
  border-radius: 0.375rem;
  margin: auto;
  background: ${({ theme }: ThemeProp) => theme.colors.white};
  max-width: 32.5rem;
  padding: 1.5rem;
`;

export const ModalBackdrop = styled.div<{ $open: boolean }>`
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  transition: opacity ${transitionTime} ease, visibility ${transitionTime} ease, transform ${transitionTime} ease;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  ${ModalContainer} {
    transition: transform ${transitionTime} ease;
    transform: translate(-50%, -50%) ${({ $open }) => ($open ? 'scale(1)' : 'scale(0.9)')};
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.c300};
  width: 1.5rem;
  height: 1.5rem;
`;
