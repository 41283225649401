import React from 'react';

const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2522_12607)">
      <path
        d="M19.5 11.3099V11.9999C19.4991 13.6172 18.9754 15.1909 18.007 16.4863C17.0386 17.7816 15.6775 18.7293 14.1265 19.1878C12.5756 19.6464 10.9179 19.5913 9.40085 19.0308C7.88376 18.4704 6.58849 17.4345 5.70822 16.0777C4.82795 14.7209 4.40984 13.1159 4.51626 11.5021C4.62267 9.88832 5.24791 8.35214 6.29871 7.1227C7.34951 5.89326 8.76959 5.03644 10.3471 4.68001C11.9247 4.32358 13.5752 4.48665 15.0525 5.1449"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.5 6L12 13.5075L9.75 11.2575" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2522_12607">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default CheckCircleIcon;
