import React from 'react';

const DevicesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="55" height="40" viewBox="0 0 55 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.88519 2.91338V16.0236H25.9667V2.91338H2.88519ZM0 2.91338C0 1.30647 1.29383 0 2.88519 0H25.9667C27.5581 0 28.8519 1.30647 28.8519 2.91338V16.0236C28.8519 17.6305 27.5581 18.937 25.9667 18.937H2.88519C1.29383 18.937 0 17.6305 0 16.0236V2.91338ZM5.77039 20.3937H23.0815C23.8795 20.3937 24.5241 21.0446 24.5241 21.8504C24.5241 22.6561 23.8795 23.3071 23.0815 23.3071H5.77039C4.97245 23.3071 4.32779 22.6561 4.32779 21.8504C4.32779 21.0446 4.97245 20.3937 5.77039 20.3937Z"
      fill="black"
    />
    <path
      d="M31.9188 19.1201C30.3275 19.1201 29.0336 20.4266 29.0336 22.0335V33.687H31.9188V22.0335H49.23V33.687H52.1152V22.0335C52.1152 20.4266 50.8213 19.1201 49.23 19.1201H31.9188ZM27.014 35.1437C26.5361 35.1437 26.1484 35.5352 26.1484 36.0177C26.1484 37.9478 27.6992 39.5138 29.6107 39.5138H51.5381C53.4496 39.5138 55.0004 37.9478 55.0004 36.0177C55.0004 35.5352 54.6127 35.1437 54.1348 35.1437H27.014Z"
      fill="black"
    />
  </svg>
);

export default DevicesIcon;
