import styled from 'styled-components';

import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

export enum PopoverPlacement {
  bottom,
  bottomLeft,
  bottomRight,
  top,
  topLeft,
  topRight,
  left,
  leftTop,
  leftBottom,
  right,
  rightTop,
  rightBottom,
}

export const PopoverContainer = styled.div``;

export const PopoverTrigger = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  &:focus-visible {
    ${focusedOutline}
  }
`;

export const PopoverRow = styled.button`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.25rem;
  justify-content: flex-start;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.25rem;
  color: ${(props: ThemeProp) => props.theme.colors.black};

  &:hover {
    background: ${(props: ThemeProp) => props.theme.colors.primary}0f;
  }
  &:focus-visible {
    outline: 1px auto ${(props: ThemeProp) => props.theme.colors.primary};
  }
`;
