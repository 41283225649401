import styled from 'styled-components';

import { ChevronIcon } from '../../assets';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';
import Popover from '../Popover';

const MAX_HEIGHT_OFFSET = '2rem';

export const DropdownTrigger = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }: ThemeProp) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProp) => theme.colors.c100};
  border-radius: ${({ theme }: ThemeProp) => theme.borderRadius};
  padding: 0.3rem 0.5rem;
  width: 12rem;
  height: 2.75rem;
  overflow: hidden;
  cursor: pointer;

  transition: border 0.25s ease 0s;
  &:focus-visible {
    ${focusedOutline}
  }
  &:hover {
    border: 1px solid ${({ theme }: ThemeProp) => theme.colors.black};
  }
`;

export const DropdownPortal = styled(Popover).attrs({
  maxHeight: ($top: number) => `min(calc(100vh - ${MAX_HEIGHT_OFFSET} - ${$top}px), 20rem)`,
})`
  border: 1px solid #00000026;
  min-width: 18rem;
  overflow: auto;
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  stroke: ${({ theme }: ThemeProp) => theme.colors.black};
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

export const SelectedTextContainer = styled.div`
  max-width: calc(100% - 1.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.s};
`;

export const PlaceholderText = styled.span`
  color: ${({ theme }: ThemeProp) => theme.colors.c400};
`;

export const DropdownContainer = styled.div``;

export const DropdownTitle = styled.div`
  padding-left: 0.5rem;
  margin-bottom: 0.375rem;
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.s};
  font-weight: ${({ theme }: ThemeProp) => theme.fontWeight.regular};
  color: ${({ theme }: ThemeProp) => theme.colors.black};
`;

export const DropdownText = styled.div`
  display: flex;
  gap: 1rem;
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.s};
  font-weight: ${({ theme }: ThemeProp) => theme.fontWeight.regular};
`;

export const SelectAllText = styled(DropdownText)`
  display: flex;
  gap: 1rem;
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.s};
  font-weight: ${({ theme }: ThemeProp) => theme.fontWeight.bold};
`;

export const DropdownDescription = styled.div`
  font-style: italic;
  color: ${({ theme }: ThemeProp) => theme.colors.c300};
`;

export const DropdownRow = styled.button<{ selected?: boolean }>`
  background: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.25rem;
  justify-content: flex-start;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.25rem;
  color: ${(props: ThemeProp) => props.theme.colors.black};
  ${({ theme, selected }: ThemeProp & { selected?: boolean }) =>
    selected && `background-color: ${theme.colors.primary}0f;`}
  &:hover {
    background-color: ${(props: ThemeProp) => props.theme.colors.primary}0f;
  }
  &:focus-visible {
    outline: 1px auto ${(props: ThemeProp) => props.theme.colors.primary};
  }
`;
