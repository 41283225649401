import React from 'react';

const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.6673 14.2222V17.1851C18.6673 17.578 18.5112 17.9549 18.2334 18.2327C17.9556 18.5105 17.5787 18.6666 17.1858 18.6666H6.81547C6.42255 18.6666 6.04573 18.5105 5.7679 18.2327C5.49007 17.9549 5.33398 17.578 5.33398 17.1851V14.2222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.29688 10.5178L12.0006 14.2215L15.7043 10.5178"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 14.2221V5.33325" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default DownloadIcon;
