import styled from 'styled-components';

import CheckIcon from '../../assets/CheckIcon';
import DashIcon from '../../assets/DashIcon';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

interface IntermediateProps {
  $isIntermediate: boolean;
}

export const StyledLabel = styled.div``;

export const StyledInput = styled.input`
  position: absolute;
  scale: 0;
`;

export const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  &:has(input:focus-visible) {
    border-radius: 0.3rem;
    ${focusedOutline}
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  width: 1.15rem;
  height 1.15rem;
  border: 1px solid ${({ theme }: ThemeProp) => theme.colors.c200};
  border-radius: 0.325rem;

  &:hover {
    background-color ${({ theme }: ThemeProp) => theme.colors.c200};
  }
`;

export const StyledCheckIcon = styled(CheckIcon)<IntermediateProps>`
  align-self: center;
  stroke: ${({ theme }: ThemeProp) => theme.colors.white};
  border-radius: 0.325rem;
  stroke-width: inherit;
  background-color: ${({ theme }: ThemeProp) => theme.colors.primary};
  ${({ $isIntermediate }: IntermediateProps & ThemeProp) =>
    !$isIntermediate &&
    `transition:
    height 0.1s,
    width 0.1s;
  `}
  ${StyledInput}:checked + & {
    width: 100%;
    height: 100%;
  }
  ${StyledInput}:not(:checked) + & {
    width: 0;
    height: 0;
  }
`;

export const StyledIntermediateIcon = styled(DashIcon)<IntermediateProps>`
  align-self: center;
  width: ${({ $isIntermediate }) => ($isIntermediate ? '100%' : '0')};
  height ${({ $isIntermediate }) => ($isIntermediate ? '100%' : '0')};
  stroke: ${({ theme }: ThemeProp) => theme.colors.white};
  border-radius: 0.325rem;
  stroke-width: inherit;
  background-color: ${({ theme }: ThemeProp) => theme.colors.primary};
  transition: height 0.1s, width 0.1s;
`;
