import { AnimatePresence } from 'framer-motion';
import React, { ReactNode, useEffect } from 'react';

import FocusTrap from '../FocusTrap';
import { Container, StyledMotionDiv } from './SideDrawer.styles';

interface SideDrawerProps {
  hideDrawer: () => void;
  open: boolean;
  children?: ReactNode;
  marginTop?: string;
  className?: string;
  width?: string;
  containerRef?: React.MutableRefObject<any>;
}

const SideDrawer = ({
  hideDrawer,
  open,
  children,
  marginTop,
  className,
  width = '33vw',
  containerRef,
}: SideDrawerProps) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      hideDrawer();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }
    return undefined;
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <StyledMotionDiv
          initial={{ width: 0 }}
          animate={{
            width,
          }}
          exit={{
            width: 0,
          }}
          $marginTop={marginTop}
        >
          <FocusTrap>
            <Container ref={containerRef} className={className}>
              {children}
            </Container>
          </FocusTrap>
        </StyledMotionDiv>
      )}
    </AnimatePresence>
  );
};

export default SideDrawer;
