import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '../Input';
import { FiltersContainer, InputContainer, StyledSearchIcon, Title, TitleContainer } from './TitleWithFacets.styles';

interface TitleWithFacetsProps {
  search: string;
  searchPlaceholder?: string;
  hideSearch?: boolean;
  onSearchChange: (value: string) => void;
  filters?: JSX.Element[];
  title: string;
}

/**
 * Contains a `Title` on the left with an optional search and dropdowns on the right.
 * Has the option for the search to be on the left or right of the filter dropdowns
 */
const TitleWithFacets = ({
  search,
  searchPlaceholder = '',
  hideSearch = false,
  onSearchChange,
  filters,
  title,
}: TitleWithFacetsProps) => {
  const { t } = useTranslation();

  const searchInput = !hideSearch && (
    <InputContainer>
      <Input
        placeholder={searchPlaceholder}
        aria-label={t('common.search')}
        iconLeft={<StyledSearchIcon />}
        clearable
        onChange={(e) => onSearchChange(e.target.value)}
        value={search}
      />
    </InputContainer>
  );

  const filterList = filters?.map((filter, i) => <Fragment key={i}>{filter}</Fragment>);

  return (
    <TitleContainer>
      <Title>{title}</Title>
      <FiltersContainer>
        {searchInput}
        {filterList}
      </FiltersContainer>
    </TitleContainer>
  );
};

export default TitleWithFacets;
