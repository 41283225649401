import React from 'react';

const ImageUploadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 14.5V6.16667C19.5 5.24619 18.7538 4.5 17.8333 4.5H6.16667C5.24619 4.5 4.5 5.24619 4.5 6.16667V17.8333C4.5 18.7538 5.24619 19.5 6.16667 19.5H13"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.8848 16.9999L17.8848 21.5" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 18.7947L17.8844 17L19.7689 18.7947" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.0835 10.3333C9.77385 10.3333 10.3335 9.77361 10.3335 9.08325C10.3335 8.3929 9.77385 7.83325 9.0835 7.83325C8.39314 7.83325 7.8335 8.3929 7.8335 9.08325C7.8335 9.77361 8.39314 10.3333 9.0835 10.3333Z"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4998 14.4999L15.3332 10.3333L6.1665 19.4999"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageUploadIcon;
