import React from 'react';

import { PopoverPlacement } from '../PopoverButton/PopoverButton.styles';
import { HelpContainer, StyledQuestionMarkIcon, StyledTooltip } from './HelpTooltip.styles';

interface Props {
  helpText: string;
  direction?: PopoverPlacement;
}

const HelpTooltip = ({ helpText, direction }: Props) => {
  return (
    <StyledTooltip text={helpText} placement={direction}>
      <HelpContainer>
        <StyledQuestionMarkIcon />
      </HelpContainer>
    </StyledTooltip>
  );
};

export default HelpTooltip;
