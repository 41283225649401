import React from 'react';

type AppContainerContextType = {
  setShowContactModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContainerContext = React.createContext<AppContainerContextType>(undefined);

export function useAppContainerContext() {
  return React.useContext(AppContainerContext);
}
