import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
`;

export const ModalBody = styled.div`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  padding-bottom: 2rem;
  ul {
    color: ${(props: ThemeProp) => props.theme.colors.c400};
  }
`;

export const ModalContainer = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  top: 50%;
  left: 50%;
  border-radius: 0.375rem;
  margin: auto;
  background: ${({ theme }: ThemeProp) => theme.colors.white};
  max-width: ${({ $width }) => $width};
`;

export const ModalBackdrop = styled.div<{ $open: boolean }>`
  background-color: ${({ theme }: ThemeProp) => theme.colors.black}80;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity 0.25s ease, visibility 0.25s ease, transform 0.25s ease;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  ${ModalContainer} {
    transition: transform 0.25s ease;
    transform: translate(-50%, -50%) ${({ $open }) => ($open ? 'scale(1)' : 'scale(0.9)')};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.l};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.black};
`;
