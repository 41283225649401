import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  ServerError,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';

import { DocumentsQuery, VendorOrganizationsQuery } from './types/api.graphql';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const refreshToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const currentSession = user.signInUserSession;
  return new Promise((resolve, reject) => {
    user.refreshSession(currentSession.refreshToken, (error, session) => {
      if (error) {
        reject(error);
      }

      resolve(session.getIdToken().getJwtToken());
    });
  });
};

type ErrorHandler = (message: string, code?: string) => void;

const createErrorLink = (handleError: ErrorHandler, setAuthToken: (authToken) => void) => {
  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    graphQLErrors?.forEach(({ message, extensions }) => {
      handleError(message, extensions.code as string);
    });

    if ((networkError as ServerError)?.statusCode === 401) {
      const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
        // CONSIDER: Add error handling/logging here.
        void (async () => {
          setAuthToken(await refreshToken());

          // Retry the failed request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })();
      });

      return observable;
    } else {
      return null;
    }
  });
};

export const createClient = (token: string | null, guid: string | null, onError: ErrorHandler) => {
  let authToken = token;

  // don't send queries unless authenticated or a guid query param is present
  const allowQueries = split(() => !!(authToken || guid), httpLink);

  const createAuthLink = split(
    () => !!authToken,
    setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: authToken,
      },
    })),
  );

  const links = [
    cleanTypeName,
    createErrorLink(onError, (newAuthToken) => (authToken = newAuthToken)),
    createAuthLink,
    allowQueries,
  ];

  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            organization: {
              merge(existing = {}, incoming: any) {
                return { ...existing, ...incoming };
              },
            },
            documents: {
              merge(existing: DocumentsQuery['documents'], incoming: DocumentsQuery['documents']) {
                return {
                  pageInfo: { ...incoming.pageInfo },
                  totalCount: incoming.totalCount,
                  nodes: mergeIncomingAndExistingNodes(incoming.nodes, existing?.nodes),
                } as DocumentsQuery['documents'];
              },
              keyArgs: ['documents'],
            },
            vendorOrganizations: {
              merge(
                existing: VendorOrganizationsQuery['vendorOrganizations'],
                incoming: VendorOrganizationsQuery['vendorOrganizations'],
              ) {
                return {
                  pageInfo: { ...incoming.pageInfo },
                  totalCount: incoming.totalCount,
                  nodes: mergeIncomingAndExistingNodes(incoming.nodes, existing?.nodes),
                } as VendorOrganizationsQuery['vendorOrganizations'];
              },
              keyArgs: ['vendorOrganizations'],
            },
          },
        },
      },
    }),
    connectToDevTools: process.env.REACT_APP_APOLLO_DEVTOOLS === 'true',
  });
};

const mergeIncomingAndExistingNodes = <T,>(incomingNodes?: Array<T>, existingNodes?: Array<T>): Array<T> => {
  return [...(existingNodes || []), ...(incomingNodes || [])];
};
