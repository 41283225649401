import styled, { css } from 'styled-components';

import { VerticalDotsIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

const baseTypography = css`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.ml};
  color: ${(props: ThemeProp) => props.theme.colors.black};
  height: 4rem;
  white-space: nowrap;
  overflow: hidden;
`;

interface RowBasedListItemContainerProps {
  $disabled?: boolean;
}

export const RowBasedListItemContainer = styled.div<RowBasedListItemContainerProps>`
  position: relative;
  display: flex;
  cursor: ${({ $disabled }: RowBasedListItemContainerProps) => (!$disabled ? 'pointer' : 'auto')};
  transition: box-shadow 150ms ease-in-out;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  align-items: center;
  background-color: ${({ $disabled, theme }: ThemeProp & RowBasedListItemContainerProps) =>
    $disabled ? theme.colors.c050 : theme.colors.white};
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem #00000005;
  padding: ${20 / 16}rem;
  ${baseTypography}

  &:hover {
    box-shadow: ${({ $disabled }: RowBasedListItemContainerProps) => (!$disabled ? '0 5px 10px #0000000d' : 'none')};
  }
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.ml};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.ml};
  color: ${(props: ThemeProp) => props.theme.colors.c500};
  height: 4rem;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonSection = styled(Section)`
  justify-content: right;
  padding-right: 0.25rem;
`;

export const StyledVertDotsIcon = styled(VerticalDotsIcon)<{ $active?: boolean }>`
  stroke: ${(props) => (props.$active ? props.theme.colors.black : props.theme.colors.c400)};
  fill: ${(props) => (props?.$active ? props.theme.colors.black : props.theme.colors.c400)};
  height: 1.5rem;
  width: 1.5rem;

  :hover {
    stroke: ${(props: ThemeProp) => props.theme.colors.black};
    fill: ${(props: ThemeProp) => props.theme.colors.black};
  }
`;
