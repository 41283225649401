import styled from 'styled-components';

import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

export const TextAreaLabel = styled.div`
  padding-left: 0.2rem;
`;

export const TextAreaContent = styled.div`
  display: grid;
  overflow: auto;
`;

interface TextAreaInputProps {
  $minRows?: number;
  $maxRows?: number;
}

export const TextAreaInputContainer = styled.div<TextAreaInputProps>`
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c200};
  overflow: hidden;
  border-radius: 0.375rem;

  &:has(textarea:focus-visible) {
    border-radius: 0.3rem;
    ${focusedOutline}
  }

  ${TextAreaContent} {
    box-sizing: content-box;
    padding: 0.5lh;
    background-color: ${(props: ThemeProp) => props.theme.colors.white};
    min-height: ${({ $minRows }: TextAreaInputProps) => $minRows ?? 2}lh;
    max-height: ${({ $maxRows }: TextAreaInputProps) => $maxRows ?? 6}lh;
  }
`;

export const StyledTextArea = styled.textarea`
  display: block;
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden;
  word-break: break-word;
  border: none;
  padding: 0;
  resize: none;
  &:focus-visible {
    outline: none;
  }
`;

export const ReplicatedDiv = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  white-space: pre-wrap;
  word-break: break-word;
  visibility: hidden;
`;

export const HelperTextContainer = styled.div`
  padding-left: 0.5rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
`;
