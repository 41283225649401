import { DependencyList, useEffect } from 'react';

export function useEventListener<K extends keyof WindowEventMap>(
  type: K | K[],
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  deps?: DependencyList,
): void;

export function useEventListener(
  type: string | string[],
  listener: EventListenerOrEventListenerObject,
  deps?: DependencyList,
): void;

export function useEventListener(
  event: string | string[],
  listener: EventListenerOrEventListenerObject,
  deps: DependencyList = [],
) {
  const events = Array.isArray(event) ? event : [event];

  useEffect(() => {
    for (const e of events) {
      window.addEventListener(e, listener);
    }

    return () => {
      for (const e of events) {
        window.removeEventListener(e, listener);
      }
    };
  }, deps);
}
