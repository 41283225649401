import React from 'react';

const DealershipIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3186_13385)">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.64844 4.2998C4.64844 3.88559 4.98422 3.5498 5.39844 3.5498H18.5984C19.0127 3.5498 19.3484 3.88559 19.3484 4.2998V19.6998C19.3484 20.114 19.0127 20.4498 18.5984 20.4498H5.39844C4.98422 20.4498 4.64844 20.114 4.64844 19.6998V4.2998ZM6.14844 5.0498V18.9498H17.8484V5.0498H6.14844Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.04883 14.1997C9.04883 13.7855 9.38461 13.4497 9.79883 13.4497H14.1988C14.613 13.4497 14.9488 13.7855 14.9488 14.1997V19.6997C14.9488 20.1139 14.613 20.4497 14.1988 20.4497C13.7846 20.4497 13.4488 20.1139 13.4488 19.6997V14.9497H10.5488V19.6997C10.5488 20.1139 10.213 20.4497 9.79883 20.4497C9.38461 20.4497 9.04883 20.1139 9.04883 19.6997V14.1997Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49805 8.1499C8.49805 7.73569 8.83383 7.3999 9.24805 7.3999H14.198C14.6123 7.3999 14.948 7.73569 14.948 8.1499C14.948 8.56412 14.6123 8.8999 14.198 8.8999H9.24805C8.83383 8.8999 8.49805 8.56412 8.49805 8.1499ZM8.49805 10.8999C8.49805 10.4857 8.83383 10.1499 9.24805 10.1499H14.198C14.6123 10.1499 14.948 10.4857 14.948 10.8999C14.948 11.3141 14.6123 11.6499 14.198 11.6499H9.24805C8.83383 11.6499 8.49805 11.3141 8.49805 10.8999Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3186_13385">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DealershipIcon;
