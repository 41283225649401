import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  display: grid;
  grid-template: 1/1;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PrimarySpinner = styled.div<{ $size: number; $color: string }>`
  grid-area: 1/1;
  z-index: 2;
  border: ${({ $size }) => $size * 0.15}px solid transparent;
  border-bottom: ${({ $size }) => $size * 0.15}px solid ${({ theme, $color }) => theme.colors[$color]};
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  animation: spin 0.8s ease infinite;
`;
export const SecondarySpinner = styled.div<{ $size: number; $color: string }>`
  grid-area: 1/1;
  border: ${({ $size }) => $size * 0.15}px solid transparent;
  border-bottom: ${({ $size }) => $size * 0.15}px dotted ${({ theme, $color }) => theme.colors[$color]}80;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
`;
