import React from 'react';

const ClosedBookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="#2D7FF9" />
    <path
      d="M13.3333 26.25C13.3333 25.6975 13.5527 25.1676 13.9434 24.7769C14.3341 24.3861 14.8641 24.1667 15.4166 24.1667H26.6666"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4166 11.6667H26.6666V28.3333H15.4166C14.8641 28.3333 14.3341 28.1138 13.9434 27.7231C13.5527 27.3324 13.3333 26.8025 13.3333 26.25V13.75C13.3333 13.1975 13.5527 12.6676 13.9434 12.2769C14.3341 11.8861 14.8641 11.6667 15.4166 11.6667V11.6667Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClosedBookIcon;
