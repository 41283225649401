import { RoadmapStepName } from '../types/api.graphql';
import { TranslationKeys } from '../types/react-i18next';

export const ROADMAP_STEPS = [
  RoadmapStepName.WrittenInformationSecurityProgram,
  RoadmapStepName.WrittenRiskAssessment,
  RoadmapStepName.SecurityAwarenessProgram,
  RoadmapStepName.OverseeServiceProviders,
  RoadmapStepName.AccessControl,
  RoadmapStepName.TechnicalRequirements,
  RoadmapStepName.SecurityTesting,
  RoadmapStepName.DevelopResponsePlan,
  RoadmapStepName.ExecutiveReporting,
] as const;

export const RoadmapStepNames: Record<RoadmapStepName, TranslationKeys> = {
  [RoadmapStepName.WrittenInformationSecurityProgram]: 'roadMap.steps.1.title',
  [RoadmapStepName.WrittenRiskAssessment]: 'roadMap.steps.2.title',
  [RoadmapStepName.SecurityAwarenessProgram]: 'roadMap.steps.3.title',
  [RoadmapStepName.OverseeServiceProviders]: 'roadMap.steps.4.title',
  [RoadmapStepName.AccessControl]: 'roadMap.steps.5.title',
  [RoadmapStepName.TechnicalRequirements]: 'roadMap.steps.6.title',
  [RoadmapStepName.SecurityTesting]: 'roadMap.steps.7.title',
  [RoadmapStepName.DevelopResponsePlan]: 'roadMap.steps.8.title',
  [RoadmapStepName.ExecutiveReporting]: 'roadMap.steps.9.title',
};

export const RoadmapStepColors: Record<RoadmapStepName, string> = {
  [RoadmapStepName.WrittenInformationSecurityProgram]: '#0085FF',
  [RoadmapStepName.WrittenRiskAssessment]: '#B396F1',
  [RoadmapStepName.SecurityAwarenessProgram]: '#BC7100',
  [RoadmapStepName.OverseeServiceProviders]: '#4C5988',
  [RoadmapStepName.AccessControl]: '#18A3C1',
  [RoadmapStepName.TechnicalRequirements]: '#F6C06E',
  [RoadmapStepName.SecurityTesting]: '#64E7B0',
  [RoadmapStepName.DevelopResponsePlan]: '#8738EC',
  [RoadmapStepName.ExecutiveReporting]: '#FF8F5A',
};
