import React from 'react';

const CircleCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity="0.1" cx="9" cy="9" r="9" fill="#7528BE" />
    <path
      d="M12.625 6.75L7.8125 11.5625L5.625 9.375"
      stroke="#7528BE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheckIcon;
