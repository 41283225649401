import React from 'react';

const LargeBook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="46" viewBox="0 0 50 46" fill="none">
    <g clipPath="url(#clip0_6266_3326)">
      <path
        d="M12.375 0.988281C7.19922 0.988281 3 4.76758 3 9.42578V37.5508C3 42.209 7.19922 45.9883 12.375 45.9883H40.5H43.625C45.3535 45.9883 46.75 44.7314 46.75 43.1758C46.75 41.6201 45.3535 40.3633 43.625 40.3633V34.7383C45.3535 34.7383 46.75 33.4814 46.75 31.9258V3.80078C46.75 2.24512 45.3535 0.988281 43.625 0.988281H40.5H12.375ZM12.375 34.7383H37.375V40.3633H12.375C10.6465 40.3633 9.25 39.1064 9.25 37.5508C9.25 35.9951 10.6465 34.7383 12.375 34.7383ZM15.5 13.6445C15.5 12.8711 16.2031 12.2383 17.0625 12.2383H35.8125C36.6719 12.2383 37.375 12.8711 37.375 13.6445C37.375 14.418 36.6719 15.0508 35.8125 15.0508H17.0625C16.2031 15.0508 15.5 14.418 15.5 13.6445ZM17.0625 17.8633H35.8125C36.6719 17.8633 37.375 18.4961 37.375 19.2695C37.375 20.043 36.6719 20.6758 35.8125 20.6758H17.0625C16.2031 20.6758 15.5 20.043 15.5 19.2695C15.5 18.4961 16.2031 17.8633 17.0625 17.8633Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_6266_3326">
        <rect width="50" height="45" fill="white" transform="translate(0 0.988281)" />
      </clipPath>
    </defs>
  </svg>
);

export default LargeBook;
