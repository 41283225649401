import styled from 'styled-components';

import { PenIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Avatar from '../Avatar';
import { AvatarText } from '../Avatar/Avatar.styles';
import Button from '../Button';

interface StyledAvatarProps {
  avatarSize: string;
}

export const AvatarContainer = styled.label<StyledAvatarProps>`
  display: flex;
  position: relative;
  width: ${(props: StyledAvatarProps) => props.avatarSize};
  height: ${(props: StyledAvatarProps) => props.avatarSize};
  background: ${(props: ThemeProp) => props.theme.colors.c050};
  border-radius: 0.75rem;
`;

export const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 2px solid ${(props: ThemeProp) => props.theme.colors.white};
  border-radius: 0.75rem;
  ${AvatarText} {
    font-size: 4.5rem;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const EditIcon = styled(PenIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.black};
  width: 1.25rem;
  height: 1.25rem;
`;

export const EditAvatarButton = styled(Button)`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.white};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
`;
