import React from 'react';

const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="#7528BE"
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.3327 4L5.99935 11.3333L2.66602 8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CheckIcon;
