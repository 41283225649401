import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import Button from '../Button';

export const IconBadgeContainer = styled.div``;

export const StyledIconButton = styled(Button)`
  border-radius: 50%;
  transition: background-color 0.25s ease-in-out;
  margin-left: 0.5rem;

  &:hover {
    background-color: ${({ theme }: ThemeProp) => theme.colors.c100};
  }
`;
