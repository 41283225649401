import React from 'react';

const RoleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7973 17.5013H4.20252C3.2813 17.5013 2.53516 16.7552 2.53516 15.8339V6.66345C2.53516 5.74224 3.2813 4.99609 4.20252 4.99609H15.7973C16.7186 4.99609 17.4647 5.74224 17.4647 6.66345V15.8339C17.4647 16.7552 16.7177 17.5013 15.7973 17.5013Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 7.5C11.1515 7.5 12.0844 8.43289 12.0844 9.5842C12.0844 10.7355 11.1515 11.6684 10.0002 11.6684C8.8489 11.6684 7.91602 10.7355 7.91602 9.5842C7.91602 8.43289 8.8489 7.5 10.0002 7.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7512 15.0005C13.642 14.7262 13.4727 14.4795 13.256 14.2786V14.2786C12.89 13.9384 12.4106 13.75 11.9104 13.75C11.0767 13.75 8.92249 13.75 8.08881 13.75C7.58861 13.75 7.11007 13.9392 6.74325 14.2786V14.2786C6.5265 14.4795 6.35726 14.7262 6.24805 15.0005"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98242 4.99714V4.16345C3.98242 3.24224 4.72857 2.49609 5.64978 2.49609H14.3492C15.2705 2.49609 16.0166 3.24224 16.0166 4.16345V4.99714"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RoleIcon;
