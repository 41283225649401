import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { UNAUTHENTICATED_PATHS } from '../../constants';
import { useAppContext } from '../../hooks/AppContext';

export default function AuthenticatedRoute() {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`${UNAUTHENTICATED_PATHS.Login}?redirect=${pathname}${search}`, { replace: true });
    }
  }, [isAuthenticated]);
  return <Outlet />;
}
