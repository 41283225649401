import React from 'react';
import styled, { css, StyledComponent } from 'styled-components';

import { CircleCloseIcon } from '../../assets';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  width: 100%;
  color: ${(props: ThemeProp) => props.theme.colors.black};
`;

const requiredCss = css`
  :after {
    content: ' *';
  }
`;

export const Label = styled.div<{ $required?: boolean }>`
  font-size: 0.875rem;
  padding-left: 0.5rem;
  margin-bottom: 0.375rem;
  line-height: 1;
  user-select: none;

  ${({ $required }) => $required && requiredCss}
`;

export const Error = styled.div`
  margin: 0.125rem 0 0 0.625rem;
  color: #f31260;
  font-size: calc(0.875rem - 1px);
  line-height: 1.125rem;
`;

export const Icon = styled.span`
  display: flex;
  width: 1rem;
  height: 100%;
  align-items: center;
  padding: 0 0.75rem;
  box-sizing: content-box;
`;

export const ClearIcon = styled(CircleCloseIcon)`
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;

  [stroke] {
    stroke: white;
  }
  [fill] {
    opacity: 1;
    fill: rgb(136, 144, 150);
  }
`;

export const ClearButton = styled.button.attrs({ children: <ClearIcon /> })`
  display: inline-flex;
  background: none;
  border: none;
  padding: 1px 4px;

  &:hover {
    opacity: 0.85;
  }
  &:focus-visible {
    border-radius: 0.3rem;
    ${focusedOutline}
  }
` as StyledComponent<'button'>;

interface InputFieldProps {
  $error?: boolean;
  $disabled?: boolean;
}

export const InputField = styled.div<InputFieldProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.75rem;
  border: 1px solid ${({ $error, theme }: InputFieldProps & ThemeProp) => ($error ? '#F31260' : theme.colors.c200)};
  border-radius: 6px;
  background: ${({ $disabled, theme }: InputFieldProps & ThemeProp) =>
    $disabled ? theme.colors.c050 : theme.colors.white};
  font-size: 0.9375rem;
  ${({ $disabled, theme }: InputFieldProps & ThemeProp) => $disabled && `color: ${theme.colors.c400};`}

  &:has(input:focus-visible) {
    ${focusedOutline}
  }

  :has(input:invalid) {
    border: 1px solid #f31260;
  }

  input {
    border: none;
    margin: 0.25rem 0.625rem;
    padding: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    ${({ $disabled }) => $disabled && 'cursor: not-allowed;'}

    :focus::placeholder {
      opacity: 1;
    }
  }

  input:focus-visible {
    outline: none;
  }
`;
