import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppContext } from '../../hooks/AppContext';
import { querystring } from '../../utils/RouteHelper';

export default function UnauthenticatedRoute() {
  const { isAuthenticated } = useAppContext();

  const redirect = querystring('redirect');
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirect || '/', { replace: true });
    }
  }, [isAuthenticated]);
  return <Outlet />;
}
