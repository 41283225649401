import React from 'react';

const EmptyViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="314" height="82" viewBox="0 0 314 82" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="1" width="312" height="80" rx="6" fill="white" stroke="#F4F4F4" />
    <circle cx="41" cy="41" r="24" fill="url(#paint0_linear_1935_15410)" />
    <rect x="81" y="25" width="138" height="11" rx="5.5" fill="url(#paint1_linear_1935_15410)" />
    <rect x="81" y="46" width="192" height="11" rx="5.5" fill="url(#paint2_linear_1935_15410)" />
    <defs>
      <linearGradient id="paint0_linear_1935_15410" x1="41" y1="17" x2="41" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EAEAEA" />
        <stop offset="1" stopColor="#EAEAEA" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_1935_15410" x1="81" y1="31" x2="219" y2="31" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EAEAEA" />
        <stop offset="1" stopColor="#EAEAEA" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient id="paint2_linear_1935_15410" x1="81" y1="52" x2="273" y2="52" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EAEAEA" />
        <stop offset="1" stopColor="#EAEAEA" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyViewIcon;
