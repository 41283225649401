import React from 'react';

const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.101469"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 8.5827e-07C5.37258 2.7888e-07 -2.78897e-07 5.37258 -8.58281e-07 12C-1.43767e-06 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 1.43766e-06 12 8.5827e-07Z"
        fill="#DE2A30"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 18.1426C12.5523 18.1426 13 17.6949 13 17.1426C13 16.5903 12.5523 16.1426 12 16.1426L11.9861 16.1426C11.4338 16.1426 10.9861 16.5903 10.9861 17.1426C10.9861 17.6949 11.4338 18.1426 11.9861 18.1426L12 18.1426ZM11 13.4998C11 14.052 11.4477 14.4998 12 14.4998C12.5523 14.4998 13 14.052 13 13.4998L13 6.99976C13 6.44747 12.5523 5.99976 12 5.99976C11.4477 5.99976 11 6.44747 11 6.99976L11 13.4998Z"
        fill="#DE2A30"
      />
    </svg>
  );
};

export default ErrorIcon;
