export const TERMINALS = {
  ROOT: 'app',
  Overview: 'overview',
  Settings: 'settings/profile',
  RoadMap: 'road-map',
  ComingSoon: 'coming-soon',
  Help: 'help',
  StatusTracker: {
    ROOT: 'status-tracker',
    ComplianceOverview: 'compliance-overview',
    WrittenInfoSecurity: 'written-info',
    WrittenRiskAssessment: 'written-risk-assessment',
    SecurityAwareness: 'security-awareness',
    OverseeServiceProviders: 'oversee-service-providers',
    AccessControl: 'access-control',
    TechnicalRequirements: 'tech-requirements',
    SecurityTesting: 'security-testing',
    DevelopResponsePlan: 'develop-response',
    ExecReporting: 'exec-reporting',
  },
  SecurityDashboard: {
    ROOT: 'security-dashboard',
    ComplianceOverview: 'compliance-overview',
    WrittenInfoSecurity: 'written-info',
    WrittenRiskAssessment: 'written-risk-assessment',
    SecurityAwareness: 'security-awareness',
    OverseeServiceProviders: 'oversee-service-providers',
    AccessControl: 'access-control',
    TechnicalRequirements: 'tech-requirements',
    SecurityTesting: 'security-testing',
    DevelopResponsePlan: 'develop-response',
    ExecReporting: 'exec-reporting',
  },
  Dealerships: {
    ROOT: 'dealerships',
    General: 'general',
    Members: 'members',
    Settings: 'settings',
    Notifications: 'notification-settings',
    Create: 'create',
  },
  Policies: {
    ROOT: 'policies',
    Create: {
      ROOT: 'new',
      SelectTemplate: 'select-template',
      DealerSelect: 'dealer-select',
      CompleteDocument: 'complete-document',
      CustomizeDocument: 'customize-document',
    },
    Edit: {
      ROOT: 'edit',
      EditDocument: 'edit-document',
      CustomizeDocument: 'customize-document',
    },
    Details: `:policyId`,
  },
  DocumentRepository: {
    ROOT: 'document-repository',
    Create: 'new',
  },
  ExecutiveReports: {
    ROOT: 'executive-reports',
    Create: 'new',
  },
  RiskAssessments: {
    ROOT: 'risk-assessments',
    Create: 'new',
    Details: ':riskAssessmentId',
    Answers: 'answers',
  },
  VendorAssessments: {
    ROOT: 'vendor-assessments',
    Create: 'new',
  },
  SecurityOps: {
    ROOT: 'security-dashboard',
    DeviceManagement: 'device-management',
    SecurityResponse: 'security-response',
    SecurityTraining: 'security-training',
    PhishingSimulation: 'phishing-simulation',
    SecurityTesting: 'security-testing',
  },
} as const;

export const ROUTES = {
  Root: `/${TERMINALS.ROOT}/*`,
  Overview: `/${TERMINALS.Overview}/*`,
  Settings: `/${TERMINALS.Settings}`,
  Roadmap: `/${TERMINALS.RoadMap}/`,
  ComingSoon: `/${TERMINALS.ComingSoon}/`,
  Help: `/${TERMINALS.Help}/`,
  StatusTracker: {
    Root: `/${TERMINALS.StatusTracker.ROOT}/*`,
    ComplianceOverview: `/${TERMINALS.StatusTracker.ComplianceOverview}`,
    WrittenInfoSecurity: `/${TERMINALS.StatusTracker.WrittenInfoSecurity}`,
    WrittenRiskAssessment: `/${TERMINALS.StatusTracker.WrittenRiskAssessment}`,
    SecurityAwareness: `/${TERMINALS.StatusTracker.SecurityAwareness}`,
    OverseeServiceProviders: `/${TERMINALS.StatusTracker.OverseeServiceProviders}`,
    AccessControl: `/${TERMINALS.StatusTracker.AccessControl}`,
    TechnicalRequirements: `/${TERMINALS.StatusTracker.TechnicalRequirements}`,
    SecurityTesting: `/${TERMINALS.StatusTracker.SecurityTesting}`,
    DevelopResponsePlan: `/${TERMINALS.StatusTracker.DevelopResponsePlan}`,
    ExecReporting: `/${TERMINALS.StatusTracker.ExecReporting}`,
  },
  SecurityDashboard: {
    Root: `/${TERMINALS.SecurityDashboard.ROOT}/*`,
  },
  Dealerships: {
    Root: `/${TERMINALS.Dealerships.ROOT}/*`,
    Create: `/${TERMINALS.Dealerships.Create}`,
    Dealership: {
      Root: `:dealershipId/*`,
      General: `${TERMINALS.Dealerships.General}`,
      Members: `${TERMINALS.Dealerships.Members}`,
      Settings: `${TERMINALS.Dealerships.Settings}`,
      Notifications: `${TERMINALS.Dealerships.Notifications}`,
    },
  },
  Policies: {
    Root: `/${TERMINALS.Policies.ROOT}/*`,
    Create: {
      New: `/${TERMINALS.Policies.Create.ROOT}/*`,
      Draft: `/${TERMINALS.Policies.Details}/${TERMINALS.Policies.Create.ROOT}/*`,
      SelectTemplate: `/${TERMINALS.Policies.Create.SelectTemplate}`,
      DealerSelect: `/${TERMINALS.Policies.Create.DealerSelect}`,
      CompleteDocument: `/${TERMINALS.Policies.Create.CompleteDocument}`,
      CustomizeDocument: `/${TERMINALS.Policies.Create.CustomizeDocument}`,
    },
    Policy: `/${TERMINALS.Policies.Details}/*`,
    Edit: {
      Root: `/${TERMINALS.Policies.Details}/${TERMINALS.Policies.Edit.ROOT}/*`,
      EditDocument: `/${TERMINALS.Policies.Edit.EditDocument}`,
      CustomizeDocument: `/${TERMINALS.Policies.Edit.CustomizeDocument}`,
    },
  },
  DocumentRepository: {
    Root: `/${TERMINALS.DocumentRepository.ROOT}/*`,
    Create: `/${TERMINALS.DocumentRepository.Create}`,
    Details: `/:documentId/*`,
  },
  RiskAssessments: {
    Root: `/${TERMINALS.RiskAssessments.ROOT}/*`,
    Create: `/${TERMINALS.RiskAssessments.Create}`,
    Details: `${TERMINALS.RiskAssessments.Details}/*`,
    Answers: `${TERMINALS.RiskAssessments.Details}/${TERMINALS.RiskAssessments.Answers}/*`,
  },
  VendorAssessments: {
    Root: `/${TERMINALS.VendorAssessments.ROOT}/*`,
    Create: `/${TERMINALS.VendorAssessments.Create}`,
    Details: `/:vendorAssessmentId/*`,
  },
  ExecutiveReports: {
    Root: `/${TERMINALS.ExecutiveReports.ROOT}/*`,
    Create: `/${TERMINALS.ExecutiveReports.Create}`,
    Details: `/:executiveReportId`,
  },
  SecurityOps: {
    Root: `/${TERMINALS.SecurityOps.ROOT}/*`,
    DeviceManagement: `/${TERMINALS.SecurityOps.DeviceManagement}`,
    SecurityResponse: `/${TERMINALS.SecurityOps.SecurityResponse}`,
    SecurityTraining: `/${TERMINALS.SecurityOps.SecurityTraining}`,
    PhishingSimulation: `/${TERMINALS.SecurityOps.PhishingSimulation}`,
    SecurityTesting: `/${TERMINALS.SecurityOps.SecurityTesting}`,
  },
} as const;

export const PATHS = {
  ROOT: `/${TERMINALS.ROOT}`,
  get Overview() {
    return `${this.ROOT}/${TERMINALS.Overview}` as const;
  },
  get Roadmap() {
    return `${this.ROOT}/${TERMINALS.RoadMap}` as const;
  },
  get ComingSoon() {
    return `${this.ROOT}/${TERMINALS.ComingSoon}` as const;
  },
  get Help() {
    return `${this.ROOT}/${TERMINALS.Help}` as const;
  },
  get Settings() {
    return `${this.ROOT}/${TERMINALS.Settings}` as const;
  },
  get StatusTracker() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.StatusTracker.ROOT}`,
      get ComplianceOverview() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.ComplianceOverview}` as const;
      },
      get WrittenInfoSecurity() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.WrittenInfoSecurity}` as const;
      },
      get WrittenRiskAssessment() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.WrittenRiskAssessment}` as const;
      },
      get SecurityAwareness() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.SecurityAwareness}` as const;
      },
      get OverseeServiceProviders() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.OverseeServiceProviders}` as const;
      },
      get AccessControl() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.AccessControl}` as const;
      },
      get TechnicalRequirements() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.TechnicalRequirements}` as const;
      },
      get SecurityTesting() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.SecurityTesting}` as const;
      },
      get DevelopResponsePlan() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.DevelopResponsePlan}` as const;
      },
      get ExecReporting() {
        return `${this.ROOT}/${TERMINALS.StatusTracker.ExecReporting}` as const;
      },
    } as const;
  },
  get SecurityDashboard() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.SecurityDashboard.ROOT}`,
    } as const;
  },
  get Dealerships() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.Dealerships.ROOT}`,
      get Create() {
        return `${this.ROOT}/${TERMINALS.Dealerships.Create}` as const;
      },
      Dealership: {
        ROOT: (dealershipId: string) => `${this.ROOT}/${TERMINALS.Dealerships.ROOT}/${dealershipId}` as const,
        General(dealershipId: string) {
          return `${this.ROOT(dealershipId)}/${TERMINALS.Dealerships.General}` as const;
        },
        Members(dealershipId: string) {
          return `${this.ROOT(dealershipId)}/${TERMINALS.Dealerships.Members}` as const;
        },
        Settings(dealershipId: string) {
          return `${this.ROOT(dealershipId)}/${TERMINALS.Dealerships.Settings}` as const;
        },
        Notifications(dealershipId: string) {
          return `${this.ROOT(dealershipId)}/${TERMINALS.Dealerships.Notifications}` as const;
        },
      } as const,
    } as const;
  },
  get Policies() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.Policies.ROOT}`,
      get Create() {
        return {
          ROOT: (policyId?: string) =>
            policyId
              ? (`${this.ROOT}/${policyId}/${TERMINALS.Policies.Create.ROOT}` as const)
              : (`${this.ROOT}/${TERMINALS.Policies.Create.ROOT}` as const),
          get Steps() {
            return {
              SelectTemplate: (policyId?: string) =>
                `${this.ROOT(policyId)}/${TERMINALS.Policies.Create.SelectTemplate}` as const,
              DealerSelect: (policyId?: string) =>
                `${this.ROOT(policyId)}/${TERMINALS.Policies.Create.DealerSelect}` as const,
              CompleteDocument: (policyId?: string) =>
                `${this.ROOT(policyId)}/${TERMINALS.Policies.Create.CompleteDocument}` as const,
              CustomizeDocument: (policyId?: string) =>
                `${this.ROOT(policyId)}/${TERMINALS.Policies.Create.CustomizeDocument}` as const,
            };
          },
        };
      },
      Policy(policyId: string) {
        return `${this.ROOT}/${policyId}` as const;
      },
      get Edit() {
        return {
          ROOT: (policyId: string) => `${this.Policy(policyId)}/${TERMINALS.Policies.Edit.ROOT}` as const,
          EditDocument(policyId: string) {
            return `${this.ROOT(policyId)}/${TERMINALS.Policies.Edit.EditDocument}` as const;
          },
          CustomizeDocument(policyId: string) {
            return `${this.ROOT(policyId)}/${TERMINALS.Policies.Edit.CustomizeDocument}` as const;
          },
        };
      },
    } as const;
  },
  get DocumentRepository() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.DocumentRepository.ROOT}`,
      get Create() {
        return `${this.ROOT}/${TERMINALS.DocumentRepository.Create}` as const;
      },
      Details(documentId: string) {
        return `${this.ROOT}/${documentId}` as const;
      },
    } as const;
  },
  get RiskAssessments() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.RiskAssessments.ROOT}`,
      get Create() {
        return `${this.ROOT}/${TERMINALS.RiskAssessments.Create}` as const;
      },
      RiskAssessment(riskAssessmentId: string) {
        return `${this.ROOT}/${riskAssessmentId}` as const;
      },
      Answers(riskAssessmentId: string) {
        return `${this.RiskAssessment(riskAssessmentId)}/${TERMINALS.RiskAssessments.Answers}` as const;
      },
    } as const;
  },
  get VendorAssessments() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.VendorAssessments.ROOT}`,
      get Create() {
        return `${this.ROOT}/${TERMINALS.VendorAssessments.Create}` as const;
      },
      VendorAssessment(vendorOrganizationId: string) {
        return `${this.ROOT}/${vendorOrganizationId}` as const;
      },
    } as const;
  },
  get ExecutiveReports() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.ExecutiveReports.ROOT}`,
      get Create() {
        return `${this.ROOT}/${TERMINALS.ExecutiveReports.Create}` as const;
      },
      ExecutiveReport(executiveReportId: string) {
        return `${this.ROOT}/${executiveReportId}` as const;
      },
    } as const;
  },
  get SecurityOps() {
    return {
      ROOT: `${this.ROOT}/${TERMINALS.SecurityOps.ROOT}`,
      get DeviceManagement() {
        return `${this.ROOT}/${TERMINALS.SecurityOps.DeviceManagement}` as const;
      },
      get SecurityResponse() {
        return `${this.ROOT}/${TERMINALS.SecurityOps.SecurityResponse}` as const;
      },
      get SecurityTraining() {
        return `${this.ROOT}/${TERMINALS.SecurityOps.SecurityTraining}` as const;
      },
      get PhishingSimulation() {
        return `${this.ROOT}/${TERMINALS.SecurityOps.PhishingSimulation}` as const;
      },
      get SecurityTesting() {
        return `${this.ROOT}/${TERMINALS.SecurityOps.SecurityTesting}` as const;
      },
    } as const;
  },
} as const;

export const HOME_PATH = PATHS.Overview;
export const POLICIES_HOME_PATH = PATHS.Policies.ROOT;
export const DOCUMENT_REPOSITORY_HOME_PATH = PATHS.DocumentRepository.ROOT;
export const RISK_ASSESSMENTS_HOME_PATH = PATHS.RiskAssessments.ROOT;
export const VENDOR_ASSESSMENTS_HOME_PATH = PATHS.VendorAssessments.ROOT;
export const EXECUTIVE_REPORTS_HOME_PATH = PATHS.ExecutiveReports.ROOT;
