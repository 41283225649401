import styled from 'styled-components';

import { ChevronIcon, InformationIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  background: ${(props: ThemeProp) => props.theme.colors.primary}14;
  mix-blend-mode: normal;
  border-radius: 0.25rem;
  padding: 0.75rem;
  width: 100%;
  margin-top: 1rem;
  gap: 0.5rem;
  cursor: pointer;
  svg {
    margin: ${7 / 16}rem 0;
  }
`;

interface HelpTextProps {
  $maxHeight?: string;
}

export const HelpText = styled.div<HelpTextProps>`
  * {
    color: ${(props: ThemeProp) => props.theme.colors.primary};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    line-height: ${(props: ThemeProp) => props.theme.lineHeight.m};
    font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  transition: max-height 0.5s ease-in-out;
  max-height: ${({ $maxHeight }: HelpTextProps) => $maxHeight};
  overflow: hidden;
  margin-right: auto;
`;

export const DummyHelpTextBox = styled(HelpText)`
  display: none;
`;

export const StyledInformationIcon = styled(InformationIcon)`
  align-self: start;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  fill: ${(props: ThemeProp) => props.theme.colors.white};
  path {
    opacity: 1;
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)<{ $isExpanded?: boolean }>`
  align-self: start;
  flex-shrink: 0;
  transition: transform 0.15s ease-in;
  transform: ${(props: { $isExpanded?: boolean }) => (props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: 1rem;
  height: 1rem;
  stroke: ${(props: ThemeProp) => props.theme.colors.primary};
`;
