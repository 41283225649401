export enum SectionStatus {
  Pending,
  Completed,
  Unnamed,
  Saved,
}

export enum RiskRating {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum TaskCompletion {
  All,
  Completed,
  Pending,
}

export enum CoreFunction {
  Identify = 'Identify (ID)',
  Protect = 'Protect (PR)',
  Detect = 'Detect (DE)',
  Respond = 'Respond (RS)',
  Recover = 'Recover (RC)',
}

export const DEFAULT_COLORS = [
  '#DE2A30',
  '#FE6F2C',
  '#FCB400',
  '#00C06C',
  '#20D9D2',
  '#18BFFF',
  '#2D7FF9',
  '#FF08C2',
  '#8B46FF',
];
