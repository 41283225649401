import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const TopProgressContainer = styled.div`
  position: relative;
  background-color: ${({ theme }: ThemeProp) => theme.colors.background};
  height: 0.625rem;
  overflow: hidden;
`;

export const ProgressBar = styled.div`
  position: absolute;
  background-color: ${({ theme }: ThemeProp) => theme.colors.primary};
  width: 50%;
  height: 100%;
  animation: loading 1.5s ease 0s infinite;
  @keyframes loading {
    0% {
      left: -50%;
    }
    100% {
      left: 100%;
    }
  }
`;
