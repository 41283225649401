import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import TextArea from '../TextArea';
import { TextAreaContent, TextAreaInputContainer } from '../TextArea/TextArea.styles';

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  ${TextAreaInputContainer} {
    border: none;
  }
  ${TextAreaContent} {
    padding-left: 0;
  }
`;

export const StyledTextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface ButtonsContainerProps {
  $showCharacterCounter: boolean;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: ${({ $showCharacterCounter }: ButtonsContainerProps) => ($showCharacterCounter ? '1rem' : '.5rem')};
`;

export const DescriptionText = styled.div`
  font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.black};
  line-break: auto;
  overflow-wrap: break-word;
  & > div {
    width: 100%;
  }
`;
