import { Auth } from 'aws-amplify';

import { ExtendedAxiosRequestConfig } from '../../axios';

export const authInterceptor = async (config: ExtendedAxiosRequestConfig) => {
  if (config.isAuthorizationNeeded ?? true) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    config.headers = {
      ...config.headers,
      Authorization: token,
    };
  }

  return config;
};
