import React from 'react';

const LargeMinus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
    <g clipPath="url(#clip0_6266_3329)">
      <path
        d="M22.5 45.9883C28.4674 45.9883 34.1903 43.6178 38.4099 39.3982C42.6295 35.1786 45 29.4557 45 23.4883C45 17.5209 42.6295 11.7979 38.4099 7.57838C34.1903 3.35881 28.4674 0.988281 22.5 0.988281C16.5326 0.988281 10.8097 3.35881 6.5901 7.57838C2.37053 11.7979 0 17.5209 0 23.4883C0 29.4557 2.37053 35.1786 6.5901 39.3982C10.8097 43.6178 16.5326 45.9883 22.5 45.9883ZM16.1719 21.3789H28.8281C29.9971 21.3789 30.9375 22.3193 30.9375 23.4883C30.9375 24.6572 29.9971 25.5977 28.8281 25.5977H16.1719C15.0029 25.5977 14.0625 24.6572 14.0625 23.4883C14.0625 22.3193 15.0029 21.3789 16.1719 21.3789Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_6266_3329">
        <rect width="45" height="45" fill="white" transform="translate(0 0.988281)" />
      </clipPath>
    </defs>
  </svg>
);

export default LargeMinus;
