import React from 'react';

export const ProtonLogoSimple = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="76" height="23" viewBox="0 0 76 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M75.3304 10.8605C75.2896 10.5268 75.0502 10.4029 74.9066 10.3283C74.8827 10.3156 74.8602 10.3043 74.8391 10.2917C74.0408 9.80733 73.1749 9.37369 72.2668 9.00341C67.3489 6.9943 61.7482 6.01579 55.145 6.01016C51.6674 6.00734 49.0937 5.48923 46.8044 4.3305L46.3412 4.09538C44.9432 3.38579 43.4972 2.65226 41.9921 2.07219C36.6012 -0.00731664 30.854 -0.50713 24.4226 0.540366C20.0032 1.26122 15.6301 2.67197 11.053 4.85566C9.44652 5.62298 7.61904 6.08056 5.46632 6.25514C5.24809 6.27344 5.02845 6.28611 4.80882 6.30019C3.81482 6.36073 2.78703 6.42409 1.82261 6.89293C0.32598 7.62223 -0.266757 8.84431 0.110567 10.424L0.36681 11.4968L0.6315 10.4268C0.955323 9.11745 1.86344 8.31493 3.49241 7.90382C3.99926 7.7757 4.56102 7.70389 5.15939 7.68841C7.222 7.63631 9.15368 7.23083 10.9037 6.48322L11.1966 6.35792C12.3426 5.86796 13.5267 5.3611 14.7009 4.881C19.592 2.87893 24.1087 1.78638 28.5127 1.54281C32.6365 1.31473 36.3449 1.77371 39.8465 2.94792C41.8429 3.61809 43.7422 4.56563 45.5781 5.48359C45.8316 5.61031 46.0864 5.73702 46.3398 5.86373C48.6024 6.98585 50.817 7.53213 53.1091 7.53213C53.1331 7.53213 53.1584 7.53213 53.1823 7.53213C57.4638 7.51242 61.223 7.74754 64.6795 8.25439C68.9835 8.88655 72.1513 9.77495 74.9517 11.135L75.3952 11.3504L75.3304 10.8605Z"
      fill="#D7282F"
    />
    <path
      d="M4.29255 11.2451H7.67298C9.26957 11.2451 10.3396 11.4774 11.1773 12.0673C11.984 12.6417 12.4951 13.5245 12.4951 14.579C12.4951 15.5716 12.046 16.7965 10.945 17.6497C9.99887 18.379 8.71203 18.7197 7.22385 18.7197H6.16931L5.50196 22.4719H2.30737L4.29255 11.2451ZM7.59554 16.3009C8.20095 16.3009 8.55716 16.1615 8.83593 15.9292C9.06823 15.7279 9.30054 15.3703 9.30054 14.8747C9.30054 14.4565 9.14567 14.1918 8.8669 14.0215C8.57264 13.8356 8.13759 13.7892 7.71944 13.7892H7.0366L6.58747 16.3009H7.59554Z"
      fill="#231F20"
    />
    <path
      d="M14.4963 11.2451H18.0161C19.4268 11.2451 20.4349 11.4309 21.2107 11.9279C22.0174 12.439 22.513 13.2457 22.513 14.3157C22.513 14.9212 22.3581 15.7575 21.7837 16.5177C21.3501 17.0922 20.7292 17.5568 19.9844 17.8201L22.1709 22.4719H18.6356L16.8982 18.2073H16.4491L15.7043 22.4719H12.5097L14.4963 11.2451ZM17.7528 16.0207C18.2343 16.0207 18.637 15.8968 18.9158 15.6645C19.1171 15.4942 19.3184 15.2154 19.3184 14.7494C19.3184 14.3932 19.1945 14.1595 18.9622 14.0046C18.7144 13.8342 18.3723 13.7878 17.9387 13.7878H17.2403L16.8532 16.0207H17.7528Z"
      fill="#231F20"
    />
    <path
      d="M25.0088 13.0443C26.1253 11.8039 27.7219 11.0746 29.5522 11.0746C32.5454 11.0746 35.0727 12.9979 35.0727 16.2699C35.0727 17.7271 34.5771 19.387 33.4127 20.6739C32.2962 21.9142 30.6997 22.6436 28.8694 22.6436C25.8761 22.6436 23.3489 20.7203 23.3489 17.4483C23.3503 15.9897 23.8459 14.3312 25.0088 13.0443ZM28.9792 19.8671C29.8324 19.8671 30.5138 19.4799 30.9643 18.9365C31.4599 18.348 31.833 17.4328 31.833 16.4867C31.833 15.0605 30.9798 13.8511 29.4452 13.8511C28.592 13.8511 27.9106 14.2382 27.46 14.7817C26.9644 15.3716 26.5913 16.2854 26.5913 17.2315C26.5913 18.6577 27.4431 19.8671 28.9792 19.8671Z"
      fill="#231F20"
    />
    <path
      d="M39.3987 13.944H35.8789L36.359 11.2451H46.5932L46.1131 13.944H42.5933L41.0896 22.4733H37.8951L39.3987 13.944Z"
      fill="#231F20"
    />
    <path
      d="M47.9431 13.0443C49.0596 11.8039 50.6562 11.0746 52.4865 11.0746C55.4798 11.0746 58.007 12.9979 58.007 16.2699C58.007 17.7271 57.5114 19.387 56.3485 20.6739C55.232 21.9142 53.6354 22.6436 51.8051 22.6436C48.8118 22.6436 46.2846 20.7203 46.2846 17.4483C46.2846 15.9897 46.7802 14.3312 47.9431 13.0443ZM51.9135 19.8671C52.7667 19.8671 53.4481 19.4799 53.8987 18.9365C54.3943 18.348 54.7674 17.4328 54.7674 16.4867C54.7674 15.0605 53.9142 13.8511 52.3795 13.8511C51.5263 13.8511 50.8449 14.2382 50.3943 14.7817C49.8988 15.3716 49.5257 16.2854 49.5257 17.2315C49.5257 18.6577 50.3789 19.8671 51.9135 19.8671Z"
      fill="#231F20"
    />
    <path
      d="M58.363 22.4719L60.3482 11.2451H63.326L65.2337 14.7029C65.8082 15.742 66.3812 16.7965 66.9246 17.8356L66.9556 17.8201C67.1105 16.812 67.3118 15.5871 67.5146 14.4396L68.0721 11.2451H71.1428L69.1576 22.4719H66.1798L64.2721 19.014C63.6977 17.9749 63.1246 16.9204 62.5812 15.8814L62.5502 15.8968C62.3953 16.9049 62.194 18.1298 61.9913 19.2773L61.4323 22.4719H58.363Z"
      fill="#231F20"
    />
  </svg>
);

export default ProtonLogoSimple;
