import Lottie from 'react-lottie-player';
import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
`;

export const StyledLottie = styled(Lottie)`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  margin: auto;
  width: 8rem;
  height: 8rem;
`;
