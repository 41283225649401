import React, { ReactNode } from 'react';

import { getFocusableElements } from '../../utils/domUtils';
import { TrapContainer } from './FocusTrap.styles';

const focusTrapClassName = 'focus-trap';

const findActiveFocusTrap = (): Element | undefined => {
  const focusTraps = document.getElementsByClassName(focusTrapClassName);
  for (let i = focusTraps.length - 1; i >= 0; i--) {
    const focusTrap = focusTraps[i] as HTMLElement;
    if (focusTrap.dataset.isTrapped) {
      return focusTrap;
    }
  }
  return undefined;
};

const handleTabKeyDown = (focusTrap: Element, e: KeyboardEvent) => {
  const focusableElements = getFocusableElements(focusTrap);
  const firstElement = focusableElements[0] as HTMLElement;
  const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

  if (focusTrap.contains(document.activeElement)) {
    if (e.shiftKey && document.activeElement === firstElement) {
      e.preventDefault();
      lastElement?.focus();
    } else if (!e.shiftKey && document.activeElement === lastElement) {
      e.preventDefault();
      firstElement?.focus();
    }
  } else {
    e.preventDefault();
    firstElement?.focus();
  }
};

const handleKeyDown = (e: KeyboardEvent) => {
  const activeFocusTrap = findActiveFocusTrap();
  if (activeFocusTrap && e.key === 'Tab') {
    handleTabKeyDown(activeFocusTrap, e);
  }
};

document.addEventListener('keydown', handleKeyDown);

interface Props {
  isTrapped?: boolean;
  children: ReactNode;
}

const FocusTrap = ({ isTrapped = true, children }: Props) => {
  return (
    <TrapContainer className={focusTrapClassName} data-is-trapped={isTrapped || undefined}>
      {children}
    </TrapContainer>
  );
};

export default FocusTrap;
