import { SetStateAction, useCallback, useEffect, useState } from 'react';

const parseSessionStorage = (key: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {
    return undefined;
  }
};

/**
 * Custom hook to handle storing items in `sessionStorage`. Once the browser is closed, the items will be cleared.
 * @param storageKey string value to identify the item in session storage
 * @param fallbackState if the item does not exist in storage or is null, then use this state
 * @returns state values for the stored `value` and setter
 */
const useSessionStorage = <T>(storageKey: string, fallbackState: T) => {
  const [value, setValue] = useState<T>(parseSessionStorage(storageKey) as T);

  useEffect(() => {
    setValue(parseSessionStorage(storageKey) as T);
  }, [storageKey]);

  const assignValue = useCallback(
    (setStateAction: SetStateAction<T>) => {
      setValue((prev) => {
        const newValue = setStateAction instanceof Function ? setStateAction(prev) : setStateAction;
        sessionStorage.setItem(storageKey, JSON.stringify(newValue));
        return newValue;
      });
    },
    [storageKey],
  );

  return [value === undefined ? fallbackState : value, assignValue] as const;
};

export default useSessionStorage;
