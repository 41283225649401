import styled from 'styled-components';

import { ProtonLogoSimple } from '../../assets';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';
import Button from '../Button';
import Wizard from '../Wizard';

export const headerHeight = '3.5rem';

export const Container = styled.div`
  flex-shrink: 0;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c100};
  padding: 0 1.5rem;
  height: ${headerHeight};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  background: ${(props: ThemeProp) => props.theme.colors.white};
  box-shadow: 0px 1px 4px rgba(6, 21, 33, 0.04);
  z-index: 1;
`;

export const WizardContainer = styled.div`
  flex: 1;
  justify-content: center;
`;

export const StyledWizard = styled(Wizard)`
  justify-content: center;
`;

export const Logo = styled(ProtonLogoSimple)`
  width: ${75.395 / 16}rem;
  height: ${22.624 / 16}rem;
  flex-shrink: 0;
  cursor: pointer;
`;
export const LogoContainer = styled.button`
  background: none;
  border: none;
  border-radius: 0.3rem;
  width: fit-content;
  &:focus-visible {
    ${focusedOutline}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem;
`;

export const StyledSecondaryButton = styled(Button)`
  height: 2rem;
  &:not(:hover) {
    border-color: ${(props: ThemeProp) => props.theme.colors.c100};
  }
`;
