import React from 'react';

const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.6667 13.28V15.28C16.6674 15.4657 16.6294 15.6494 16.555 15.8195C16.4806 15.9897 16.3715 16.1424 16.2347 16.2679C16.0979 16.3934 15.9364 16.489 15.7605 16.5485C15.5846 16.6079 15.3983 16.63 15.2133 16.6133C13.1619 16.3904 11.1913 15.6894 9.46001 14.5667C7.84923 13.5431 6.48356 12.1774 5.46001 10.5667C4.33333 8.82745 3.63217 6.84731 3.41334 4.78665C3.39668 4.60229 3.41859 4.41649 3.47767 4.24107C3.53676 4.06564 3.63172 3.90444 3.75652 3.76773C3.88131 3.63102 4.03321 3.52179 4.20253 3.447C4.37186 3.37221 4.5549 3.33349 4.74001 3.33332H6.74001C7.06354 3.33013 7.3772 3.4447 7.62251 3.65567C7.86783 3.86664 8.02806 4.15961 8.07334 4.47998C8.15775 5.12003 8.31431 5.74847 8.54001 6.35332C8.6297 6.59193 8.64911 6.85126 8.59594 7.10057C8.54277 7.34988 8.41925 7.57872 8.24001 7.75998L7.39334 8.60665C8.34238 10.2757 9.72431 11.6576 11.3933 12.6067L12.24 11.76C12.4213 11.5807 12.6501 11.4572 12.8994 11.404C13.1487 11.3509 13.4081 11.3703 13.6467 11.46C14.2515 11.6857 14.88 11.8422 15.52 11.9267C15.8439 11.9723 16.1396 12.1355 16.351 12.385C16.5624 12.6345 16.6748 12.953 16.6667 13.28Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
