import { useState } from 'react';

export const useIsScrolled = () => {
  const [scrollTop, setScrollTop] = useState<number>(0);

  const onScrollHandler = (event) => {
    setScrollTop(+event.target.scrollTop);
  };

  function getIsScrolled() {
    const isAtTop = scrollTop === 0;

    return !isAtTop;
  }

  return { isScrolled: getIsScrolled(), onScrollHandler };
};
