import { useState } from 'react';

import { lightTheme } from '../theme/Themes';
import { useEventListener } from './useEventListener';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEventListener(
    'resize',
    () => {
      const { width, height } = getWindowDimensions();
      if (width !== windowDimensions.width || height !== windowDimensions.height) {
        setWindowDimensions({ width, height });
      }
    },
    [windowDimensions],
  );

  return windowDimensions;
}

export const parsePx = (px: string) => Number(px.replace('px', ''));

export const inTablet = (width?: number) =>
  (width || window.innerWidth) <= Number(parsePx(lightTheme.breakpoints.tablet));
