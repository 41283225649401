import React from 'react';

const ClosedBoxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    {...props}
  >
    <circle cx="20" cy="20" r="20" fill="#2D7FF9" />
    <g clipPath="url(#clip0_115_361)">
      <path d="M27.5 16.6667V27.5H12.5V16.6667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.1666 12.5H10.8333V16.6667H29.1666V12.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.3333 20H21.6666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_115_361">
        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
);

export default ClosedBoxIcon;
