import { css } from 'styled-components';

import { ThemeProp } from '../theme/Themes';

export const focusedOutline = css`
  outline: 2px solid ${({ theme }: ThemeProp) => theme.colors.primary};
  outline-offset: 2px;
`;

export const focusedOutlineSecondary = css`
  outline: 2px solid ${({ theme }: ThemeProp) => theme.colors.white};
  outline-offset: 2px;
`;
