import React from 'react';

export const AppContext = React.createContext<{
  isAuthenticated: boolean;
  reloadAuthInfo: () => Promise<void>;
  showError: (error: string) => void;
  showAlert: (alert: string) => void;
  themeName: 'light' | 'dark';
  setThemeName: React.Dispatch<React.SetStateAction<'light' | 'dark'>>;
  logout: () => Promise<void>;
}>(undefined);

export function useAppContext() {
  return React.useContext(AppContext);
}
