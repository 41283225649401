import React from 'react';

const UndoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.66699 6.16663L6.16699 8.66663L8.66699 11.1666"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16699 8.66663H13.667C16.4287 8.66663 18.667 10.7183 18.667 13.25V13.25C18.667 15.7816 16.4287 17.8333 13.667 17.8333H7.00033"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UndoIcon;
