import { ApolloProvider } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import lazyWithPreload from 'react-lazy-with-preload';
import { BrowserRouter as Router, createSearchParams, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Root } from './App.styles';
import { axiosClient } from './axios';
import { TopProgress } from './components';
import AuthenticatedRoute from './components/routing/AuthenticatedRoute';
import UnauthenticatedRoute from './components/routing/UnauthenticatedRoute';
import { HOME_PATH } from './constants';
import { ROUTES, UNAUTHENTICATED_ROUTES } from './constants/routes';
import { useAuthContext } from './contexts/AuthContext';
import { SessionProvider } from './contexts/SessionContext';
import { createClient } from './graphql';
import { AppContext, useAppContext, useSessionStorage } from './hooks';
import { usePromise } from './hooks/usePromise';
import { GlobalStyles } from './theme/GlobalStyles';
import { darkTheme, lightTheme } from './theme/Themes';
import { MeDocument } from './types/api.graphql';
import { configureAmplify } from './utils/Amplify';

configureAmplify();

const LoginModule = lazyWithPreload(() => import('./views/auth/LoginPage'));
const VendorQuestionnaireModule = lazyWithPreload(
  () => import('./views/vendor-questionnaire/views/VendorQuestionnairePage'),
);
const AppContainerModule = lazy(() => import('./views/AppContainer'));

const App = () => {
  const { authToken, updateAuthToken } = useAuthContext();
  const { loading: updatingAuthToken } = usePromise(updateAuthToken);
  const [isAuthenticated, setIsAuthenticated] = useSessionStorage('user.authenticated', false);
  const [loading, setLoading] = useState(true);
  const [themeName, setThemeName] = useState<'light' | 'dark'>('light');

  const params = createSearchParams(window.location.search);
  const guid = params.get('guid');

  const theme = themeName === 'dark' ? darkTheme : lightTheme;

  const showError = (error: string) => {
    toast.error(error);
  };

  const showAlert = (alert: string) => {
    toast(alert);
  };

  const client = useMemo(() => createClient(authToken, guid, showError), [authToken]);

  const preloadUnauthModules = () => {
    void LoginModule.preload();
    void VendorQuestionnaireModule.preload();
  };

  const logout = async () => {
    setLoading(true);

    try {
      setIsAuthenticated(false);
      await Promise.allSettled([
        axiosClient.get('/dlrSecured/logout', {
          headers: {
            Authorization: authToken,
          },
        }),
        client.clearStore(),
        Auth.signOut(),
      ]);
    } catch (err: any) {
      console.error({
        message: 'Error while logging out',
        error: err?.stack || err,
      });
    } finally {
      localStorage.clear();
      setLoading(false);
    }
  };

  const validateUser = async (): Promise<void> => {
    setLoading(true);

    const hasProductAccess = await client
      .query({ query: MeDocument })
      .then((result) => result?.data?.me?.organizations?.length > 0)
      .catch((error) => {
        console.error(error);
        return false;
      });

    const authorizedUser = authToken && hasProductAccess;
    const unauthorizedUser = authToken && !hasProductAccess;

    if (authorizedUser) {
      setIsAuthenticated(true);
    } else if (unauthorizedUser) {
      preloadUnauthModules();
      // FIXME: Remove after changes on PC-933
      showError('Invalid User');
      await logout();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (guid) {
      setLoading(false);
    } else if (!updatingAuthToken) {
      // Don't validate the user until the auth token has been fetched.
      void validateUser();
    }
  }, [updatingAuthToken, client]);

  const contextValues = {
    isAuthenticated,
    reloadAuthInfo: validateUser,
    showError,
    showAlert,
    themeName,
    setThemeName,
    logout,
  };

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        {loading || updatingAuthToken ? (
          <TopProgress />
        ) : (
          <SessionProvider>
            <Router>
              <Suspense fallback={<TopProgress />}>
                <Root>
                  <AppContext.Provider value={contextValues}>
                    <AnimatedRoutes />
                  </AppContext.Provider>
                </Root>
              </Suspense>
            </Router>
          </SessionProvider>
        )}
        <Toaster containerStyle={{ zIndex: 10000 }} />
      </ThemeProvider>
    </ApolloProvider>
  );
};

const Main = () => {
  const { isAuthenticated } = useAppContext();

  return <Navigate replace to={isAuthenticated ? HOME_PATH : '/login'} />;
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence initial={false} exitBeforeEnter={true}>
      <Routes location={location}>
        <Route path={UNAUTHENTICATED_ROUTES.Root} element={<Main />} />
        <Route path={UNAUTHENTICATED_ROUTES.VendorQuestionnaire} element={<VendorQuestionnaireModule />} />
        <Route element={<UnauthenticatedRoute />}>
          <Route path={UNAUTHENTICATED_ROUTES.Login} element={<LoginModule />} />
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route path={ROUTES.Root} element={<AppContainerModule />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default App;
