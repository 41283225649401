import React, { ChangeEventHandler, forwardRef, useCallback, useRef } from 'react';

import { uploadFile } from '../../utils/Amplify';

type FileUploaderProps = {
  setLoading?: (loading: boolean) => void;
  onUploadFinish: (url: string) => void;
  accept?: string;
};

const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(({ accept, onUploadFinish, setLoading }, ref) => {
  const inputRef = useRef(null);

  const refCallback = useCallback(
    (element: HTMLInputElement) => {
      inputRef.current = element;
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    },
    [inputRef, ref],
  );

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    void (async () => {
      const file: File = event.target.files[0];
      if (!file) return;
      setLoading?.(true);
      const url = await uploadFile(file);
      if (url) {
        onUploadFinish(url);
      }
      setLoading?.(false);
      inputRef.current.value = '';
    })();
  };

  return <input type="file" onChange={onFileChange} hidden ref={refCallback} accept={accept} />;
});

export default FileUploader;
