import React from 'react';

const FolderUploadArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7 10.9999V7.1665" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.16536 8.33317L6.9987 7.1665L5.83203 8.33317"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33333 10.9999H11.0833C11.3928 11 11.6896 10.8771 11.9084 10.6583C12.1272 10.4395 12.2501 10.1427 12.25 9.83321V5.49983C12.25 4.85551 11.7277 4.33319 11.0833 4.33316H7.30858C7.11538 4.33316 6.93473 4.23748 6.82617 4.07766L6.00658 2.87308C5.89802 2.71364 5.71764 2.61821 5.52475 2.61816H2.91667C2.60722 2.61808 2.31043 2.74097 2.09161 2.95978C1.8728 3.17859 1.74991 3.47539 1.75 3.78483L1.75 9.83321C1.74991 10.1427 1.8728 10.4395 2.09161 10.6583C2.31043 10.8771 2.60722 11 2.91667 10.9999H4.66667"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderUploadArrowUpIcon;
