import styled from 'styled-components';

import { focusedOutline } from '../../../constants/styles';
import { ThemeProp } from '../../../theme/Themes';

export const Title = styled.h2`
  text-align: center;
  color: ${(props: ThemeProp) => props.theme.colors.c500};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xl};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
`;

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(235, 235, 240);
  padding: 1.5rem;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xs};
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  box-shadow: rgba(45, 45, 45, 0.05) 0px 5px 10px;
  &:focus-visible {
    ${focusedOutline}
  }
`;

export const ButtonTitle = styled.div`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.medium};
  color: ${(props: ThemeProp) => props.theme.colors.c500};
`;
