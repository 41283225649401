import React, { useEffect, useRef } from 'react';

import { getFocusableElements } from '../../utils/domUtils';
import Popover from '../Popover/Popover';
import { PopoverContainer, PopoverPlacement, PopoverTrigger } from './PopoverButton.styles';

export interface PopoverProps {
  children: React.ReactNode;
  triggerDisplay: React.ReactNode;
  placement?: PopoverPlacement;
  disabled?: boolean;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  className?: string;
}

const PopoverButton = ({
  children,
  isOpen,
  disabled,
  triggerDisplay,
  placement = PopoverPlacement.bottom,
  onOpenChange,
  className,
}: PopoverProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleMouseDown = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!triggerRef?.current?.contains(target) && !contentRef?.current?.contains(target)) {
      onOpenChange(false);
    }
  };

  const handleScroll = () => {
    onOpenChange(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onOpenChange(!isOpen);
  };

  const handleTab = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const focusableElements = getFocusableElements(contentRef?.current);
    e.stopPropagation();
    if (
      (e.shiftKey && document.activeElement === focusableElements?.[0]) ||
      (!e.shiftKey && document.activeElement === focusableElements?.[focusableElements?.length - 1])
    ) {
      e.preventDefault();
      triggerRef?.current?.focus();
      onOpenChange(false);
    }
  };

  const handleEscapePress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onOpenChange(false);
    triggerRef?.current?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Tab':
        handleTab(e);
        break;
      case 'Escape':
        handleEscapePress(e);
        break;
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('scroll', handleScroll, true);
      document.addEventListener('mousedown', handleMouseDown);
      return () => {
        document.removeEventListener('scroll', handleScroll, true);
        document.removeEventListener('mousedown', handleMouseDown);
      };
    }
    return undefined;
  }, [isOpen]);

  return (
    <PopoverContainer className={className}>
      <PopoverTrigger disabled={disabled} ref={triggerRef} onClick={handleClick}>
        {triggerDisplay}
      </PopoverTrigger>
      <Popover onKeyDown={handleKeyDown} ref={contentRef} triggerRef={triggerRef} placement={placement} isOpen={isOpen}>
        {children}
      </Popover>
    </PopoverContainer>
  );
};

export default PopoverButton;
