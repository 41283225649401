import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { getFocusableElements } from '../../utils/domUtils';
import { PopoverPlacement } from '../PopoverButton/PopoverButton.styles';
import { PopoverPortalContainer } from './Popover.styles';

interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  triggerRef: React.MutableRefObject<HTMLButtonElement>;
  placement: PopoverPlacement;
  children?: React.ReactNode;
  className?: string;
  maxHeight?: string | (($top: number) => string);
}

const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ isOpen, triggerRef, placement, children, className, maxHeight, ...divProps }: PopoverProps, ref) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [isRendered, setIsRendered] = useState(false);

    const handlePropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    };

    const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
      if (!isOpen) {
        setIsRendered(false);
      }
      divProps.onAnimationEnd?.(e);
    };

    const handlePlacement = () => {
      // setting to none because it could change the calcs for the bounding box if not
      contentRef.current.style.display = `none`;
      const triggerRect = triggerRef.current.getBoundingClientRect();

      // setting back to default in order to get the actual width and height of element
      contentRef.current.style.display = ``;
      const contentRect = contentRef.current.getBoundingClientRect();

      let topPos = triggerRect.y;
      let leftPos = triggerRect.x;
      switch (placement) {
        case PopoverPlacement.bottom:
          topPos += triggerRect.height;
          leftPos += triggerRect.width / 2 - contentRect.width / 2;
          break;
        case PopoverPlacement.bottomLeft:
          topPos += triggerRect.height;
          break;
        case PopoverPlacement.bottomRight:
          topPos += triggerRect.height;
          leftPos += triggerRect.width - contentRect.width;
          break;
        case PopoverPlacement.left:
          topPos += triggerRect.height / 2 - contentRect.height / 2;
          leftPos -= contentRect.width;
          break;
        case PopoverPlacement.leftBottom:
          topPos += triggerRect.height - contentRect.height;
          leftPos -= contentRect.width;
          break;
        case PopoverPlacement.leftTop:
          leftPos -= contentRect.width;
          break;
        case PopoverPlacement.right:
          topPos += triggerRect.height / 2 - contentRect.height / 2;
          leftPos += triggerRect.width;
          break;
        case PopoverPlacement.rightBottom:
          topPos += triggerRect.height - contentRect.height;
          leftPos += triggerRect.width;
          break;
        case PopoverPlacement.rightTop:
          leftPos += triggerRect.width;
          break;
        case PopoverPlacement.top:
          topPos -= contentRect.height;
          leftPos += triggerRect.width / 2 - contentRect.width / 2;
          break;
        case PopoverPlacement.topLeft:
          topPos -= contentRect.height;
          break;
        case PopoverPlacement.topRight:
          topPos -= contentRect.height;
          leftPos += triggerRect.width - contentRect.width;
          break;
      }
      setTop(topPos);
      setLeft(leftPos);
    };

    const setupRefs = useCallback(
      (element: HTMLInputElement) => {
        contentRef.current = element;
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      },
      [contentRef, ref],
    );

    useEffect(() => {
      if (isOpen) {
        setIsRendered(true);
        (getFocusableElements(contentRef.current)?.[0] as HTMLElement)?.focus();
      }
    }, [isOpen]);

    useEffect(() => {
      if (isRendered) {
        handlePlacement();
        (getFocusableElements(contentRef.current)?.[0] as HTMLElement)?.focus();
      }
    }, [isRendered]);

    return (
      isRendered &&
      createPortal(
        <PopoverPortalContainer
          {...divProps}
          onAnimationEnd={handleAnimationEnd}
          onClick={handlePropagation}
          $top={top}
          $left={left}
          ref={setupRefs}
          $isOpen={isOpen}
          className={className}
          $maxHeight={maxHeight}
        >
          {children}
        </PopoverPortalContainer>,
        document.body,
      )
    );
  },
);

export default Popover;
