import { useEventListener } from './useEventListener';

interface Params {
  /**
   * A list of CSS Selectors that should be ignored. You must ensure that a
   *  valid CSS Selector is provided (e.g. `my-class` is not valid, but
   *  `.my-class` is. `my-id` is not valid, but `#my-id` is.).
   */
  cssSelectorsToIgnore: Array<string>;
  onBlur: () => void;
}
export const useControlledBlur = ({ cssSelectorsToIgnore, onBlur }: Params) => {
  useEventListener(
    'focusin',
    (e) => {
      const target = e.target as HTMLElement;

      if (target && !cssSelectorsToIgnore.some((cssSelectorToIgnore) => target.closest(cssSelectorToIgnore))) {
        onBlur();
      }
    },
    [cssSelectorsToIgnore, onBlur],
  );

  useEventListener(
    'click',
    (e) => {
      const target = e.target as HTMLElement;
      if (target && !cssSelectorsToIgnore.some((cssSelectorToIgnore) => target.closest(cssSelectorToIgnore))) {
        onBlur();
      }
    },
    [cssSelectorsToIgnore, onBlur],
  );
};
