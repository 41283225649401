import React from 'react';

const RedoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.333 6.16663L17.833 8.66663L15.333 11.1666"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.833 8.66663H10.333C7.57134 8.66663 5.33301 10.7183 5.33301 13.25V13.25C5.33301 15.7816 7.57134 17.8333 10.333 17.8333H16.9997"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RedoIcon;
