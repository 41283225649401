import React from 'react';

import { TickIcon } from '../../../assets';
import Spinner from '../../Spinner';
import { StyledButton } from './ButtonWithLoadingState.styles';

interface ButtonWithLoadingStateProps {
  className?: string;
  completedText: string;
  notCompletedText: string;
  completed: boolean;
  loading: boolean;
  onClick: () => void;
}

const ButtonWithLoadingState: React.FC<ButtonWithLoadingStateProps> = ({
  className,
  completedText,
  notCompletedText,
  completed,
  loading,
  onClick,
}) => {
  const buttonText = completed ? completedText : notCompletedText;
  const iconToRender = loading ? <Spinner size="xs" color={completed ? 'white' : 'primary'} /> : <TickIcon />;

  return (
    <StyledButton onClick={onClick} $completed={completed} className={className} icon={iconToRender}>
      {buttonText}
    </StyledButton>
  );
};

export default ButtonWithLoadingState;
